import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.promoCodeState || initialState;
export const selectIsAddPromoCodePopupOpen = createSelector(
	[selectDomain],
	state => state.isAddPromoCodePopupOpen,
);
export const selectPromoCodeList = createSelector(
	[selectDomain],
	state => state.promoCodeList,
);
export const selectSelectedPromoCode = createSelector(
	[selectDomain],
	state => state.selectedPromoCode,
);
export const selectPromoCodeForm = createSelector(
	[selectDomain],
	state => state.promoCodeForm,
);
export const selectLoading = createSelector(
	[selectDomain],
	state => state.loading,
);
export const selectIsEditAction = createSelector(
	[selectDomain],
	state => state.isEditAction,
);
