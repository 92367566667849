import React, { useEffect } from 'react';
import {
	Box,
	Container,
	Button,
	ButtonGroup,
	TableRow,
	TableHead,
	TableBody,
	Table,
	Grid,
} from '@mui/material';
import {
	IconTrashRed,
	IconBlueArchive,
	IconTrash,
	IconUnArchive,
	IconBlueReassign,
	IconEdit,
	IconBluePlus,
	IconBag,
	IconSearch,
	IconLine,
	IconArchive,
	IconReassign,
} from '../../../assets/images';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { PatientRepoSaga } from '../Patients/redux/saga';
import { sliceKey, reducer } from '../Patients/redux/slice';
import { useInjectReducer } from 'redux-injectors';
import { useInjectSaga } from 'redux-injectors';
import {
	actions as actionsClinic,
	sliceKey as sliceKeyClinic,
	reducer as reducerClinic,
} from './redux/slice';
import { ClinicRepoSaga } from './redux/saga';

import { useDispatch, useSelector } from 'react-redux';
import { selectContactPersonList, selectActiveClinic } from './redux/selector';
interface Props {
	setPatientPopup: any;
	deleteContactPerson: any;
}
const ClinicList = (props: Props) => {
	const dispatch = useDispatch();
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: PatientRepoSaga });
	useInjectReducer({ key: sliceKeyClinic, reducer: reducerClinic });
	useInjectSaga({ key: sliceKeyClinic, saga: ClinicRepoSaga });
	const rows = useSelector(selectContactPersonList);
	const activeClinic = useSelector(selectActiveClinic);

	useEffect(() => {
		if (activeClinic.length > 0) {
			dispatch(actionsClinic.searchClinicsContactPerson(''));
		}
		return () => {};
	}, [activeClinic]);

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.common,
			color: '#645B70',
			fontWeight: '400',
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
			fontWeight: '500',
		},
	}));

	const StyledTableRow = styled(TableRow)(() => ({
		'&:hover': {
			backgroundColor: '#ece6f4',
			cursor: 'pointer',
			'& .MuiTableCell-root': {
				color: '#40018C',
			},
		},
	}));

	return (
		<Box display="flex" alignItems={'center'} sx={{ mt: 5 }}>
			<Container maxWidth={'xl'}>
				<Grid container>
					<Grid item lg={11} xs={12}>
						<Table
							sx={{ minWidth: 700 }}
							aria-label="simple table"
							style={{ width: '100%', overflowX: 'auto' }}
						>
							<TableHead>
								<TableRow>
									<StyledTableCell style={{ paddingLeft: '15px' }}>
										Contact People
									</StyledTableCell>
									<StyledTableCell align="left" style={{ paddingLeft: '15px' }}>
										Email
									</StyledTableCell>
									<StyledTableCell align="center">Actions</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((item, index) => (
									<StyledTableRow
										key={index}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<StyledTableCell
											style={{ paddingLeft: '15px' }}
											component="th"
											scope="row"
										>
											{item.name}
										</StyledTableCell>
										<StyledTableCell
											align="left"
											style={{ paddingLeft: '15px' }}
										>
											{item.email}
										</StyledTableCell>
										<StyledTableCell align="left" style={{ width: '5%' }}>
											<ButtonGroup
												className="iconBtnGroup"
												variant="text"
												aria-label="text button group"
												sx={{ justifyContent: 'flex-end' }}
											>
												<Button
													onClick={() => {
														props.setPatientPopup(true, item._id);
													}}
												>
													<img src={IconEdit} alt="edit" />
												</Button>
												<Button
													onClick={() => {
														props.deleteContactPerson(item._id, item.name);
													}}
												>
													<img src={IconTrash} alt="trash" />
												</Button>
											</ButtonGroup>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default ClinicList;
