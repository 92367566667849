import { Box } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useStyles } from './style';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'app/containers/Patients/redux/slice';
import { parse } from 'assets/packages/csv-parse/lib/sync';
import { selectSelectedPatient } from 'app/containers/Patients/redux/selector';
import { PatientDetail } from 'app/containers/Patients/types';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
export function MyDropzone() {
	const styles = useStyles();
	const dispatch = useDispatch();
	const patientDetail: PatientDetail = useSelector(selectSelectedPatient);
	const userId = patientDetail?._id;
	const [dragNDropText, setDragNDropText] = useState(
		'Drag & drop the CSV file to upload',
	);
	const onDrop = useCallback(
		acceptedFiles => {
			acceptedFiles.forEach(file => {
				const reader = new FileReader();

				reader.onabort = () => console.log('file reading was aborted');
				reader.onerror = () => console.log('file reading has failed');
				reader.onload = evt => {
					const input: string | ArrayBuffer | undefined | null =
						evt?.target?.result;
					if (input && userId) {
						try {
							const options = { columns: true, from_line: 15 };
							const data = parse(input.toString(), options);
							dispatch(actions.uploadCsvFile({ data, userId }));
						} catch (err: any) {
							console.error('Invalid CSV: ' + err.message);
						}
					}
					if (reader.result) {
						setDragNDropText('');
					}
				};
				reader.readAsText(file);
			});
		},
		[dispatch, userId],
	);
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			'text/csv': ['.csv'],
		},
	});
	const files = acceptedFiles.map((file: any) => (
		<p key={file?.path}>
			{file?.path} - {file?.size} bytes
		</p>
	));
	return (
		<Box className={clsx(styles.container)}>
			<div {...getRootProps()}>
				<input {...getInputProps()} />
				{dragNDropText && (
					<>
						<CloudUploadOutlinedIcon fontSize={'medium'} />
						<p className={clsx(styles.mainText)}>{dragNDropText}</p>
						<p className={clsx(styles.secondaryText)}>Or browse</p>
					</>
				)}
			</div>
			<p className={clsx(styles.secondaryText)}>{files}</p>
		</Box>
	);
}
