import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	_root: {
		background: '#F9F9F9',
	},
	hideOnSmallScreen: {
		'@media (max-width: 600px)': {
			display: 'none',
		},
	},
	dropzoneImgBox: { display: 'flex', justifyContent: 'center', marginTop: 10 },
	buttonBox: { textAlign: 'center' },
	btn: {
		borderRadius: '40px',
		padding: '8px 0px',
		width: '205px',
		height: '56px',
		fontWeight: '700',
		boxShadow: 'none',
		backgroundColor: '#40018C',
		'@media (max-width:600px)': {
			padding: '0px 8px',
			width: '100%',
			height: '40px',
			fontWeight: '400',
		},
	},
	EditPopup: {},
	TrashMClass: {
		'& .MuiTypography-h6': {
			color: '#DD2A19',
		},
		'& .MuiTypography-body1': {
			color: '#66130B',
		},
		'& .MuiInputLabel-root': {
			color: '#938E9C',
		},

		'& .MuiOutlinedInput-root': {
			background: 'transparent',
			border: '1px solid #F1F0F2',
		},

		'& .MuiButton-containedPrimary': {
			background: '#DD2A19 !important',
		},
		'& .MuiButton-textPrimary': {
			color: '#66130B !important',
		},
	},
	input: {
		'& .MuiOutlinedInput-root': {
			background: theme.palette.grey[50],
			borderRadius: '40px',
			fontWeight: '500',
			paddingLeft: '20px',
		},
		'& .MuiFormHelperText-root': {
			textAlign: 'right',
			color: theme.palette.primary.main,
			fontWeight: '700',
			fontSize: '12px',
			marginTop: 7,
		},
	},
	label: {
		paddingLeft: '24px',
		fontSize: '12px',
		color: theme.palette.primary.main,
		fontWeight: '700',
		marginBottom: '8px',
	},
	formGroup: {
		margin: '20px 0',
		'& .MuiTypography-root': {
			marginLeft: 24,
			marginBottom: 24,
		},
		'& .MuiTypography-h5': {
			paddingLeft: '0 !important',
		},
	},
	patient: {},
	TableBox: { '& .MuiBox-root': { padding: 0 } },
	backButton: {
		float: 'right',
		backgroundColor: 'transparent',
		color: '#938E9C',
		textDecorationLine: 'underline',
		'@media (max-width:600px)': {
			display: 'none',
		},
	},
	helpButton: {
		border: '2px solid #3A1086',
		padding: '0px 10px',
		borderRadius: 50,
	},
	root: {
		height: '85vh',
		background: '#FFF',
		width: '25vw',
		transform: 'translateX(-100%)',
		transition: 'transform 0.5s ease, opacity 0.5s ease',
		paddingLeft: 8,
		paddingRight: 20,
		overflowY: 'scroll',
		paddingBottom: 50,
	},
	visible: {
		transform: 'translateX(0)',
	},
	titleBox: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 10,
	},
	_titleBox: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
	},
	title: {
		textAlign: 'center',
		fontWeight: 700,
	},
	headBtn: {
		height: 40,
	},
	helpQuestion: {
		width: '100%',
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'row',
		padding: 0,
		paddingLeft: 10,
	},
	topicText: {
		fontWeight: 'bold',
		textAlign: 'left',
		lineHeight: 1.4,
		marginTop: 10,
	},
	questionText: {
		textAlign: 'left',
		lineHeight: 1.4,
		marginTop: 10,
	},
	answerText: {
		fontSize: '14px',
		lineHeight: 1.4,
		paddingLeft: 10,
	},
	line: {
		width: '100%',
		height: '2px',
		backgroundColor: theme.palette.grey[200],
	},
	wrapper: { marginTop: '20px' },
	selectInput: {
		borderRadius: 50,
		background: theme.palette.grey[50],
		paddingLeft: 20,
	},
}));
