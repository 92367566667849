import { Logo } from 'app/components/Logo';
import React from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {};

const useStyles = makeStyles(() => ({
	root: {
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		padding: 16,
	},
	text: {
		marginTop: 16,
		fontSize: 24,
		letterSpacing: '.5px',
		textAlign: 'center',
		color: '#3C4858',
	},
}));

export const NotFound = (props: Props) => {
	const styles = useStyles();
	return (
		<Box className={styles.root}>
			<Logo />
			<div className={styles.text}>{`Page Not Found`}</div>
		</Box>
	);
};
