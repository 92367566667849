import React, { useState } from 'react';
import { ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useStyles } from './style';

interface HeadingProps {
	infoText?: string;
	children?: any;
}
const InfoIcon = ({ children, infoText }: HeadingProps) => {
	const styles = useStyles();
	const [openTooltip, setOpenTooltip] = useState(false);
	const handleTooltipClose = () => {
		setOpenTooltip(false);
	};
	const handleTooltipOpen = () => {
		setOpenTooltip(true);
	};
	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<div className={clsx(styles.tooltipContainer)}>
				<Tooltip
					title={<div className={clsx(styles.tooltipText)}>{infoText}</div>}
					placement="top-end"
					leaveDelay={200}
					PopperProps={{
						disablePortal: true,
					}}
					onClose={handleTooltipClose}
					open={openTooltip}
					disableFocusListener
					disableHoverListener
					disableTouchListener
				>
					<IconButton
						aria-label="info"
						onClick={() => {
							handleTooltipOpen();
						}}
						className={clsx(styles.infoButton)}
					>
						{children}
					</IconButton>
				</Tooltip>
			</div>
		</ClickAwayListener>
	);
};

export default InfoIcon;
