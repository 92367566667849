/**
 * Homepage selectors
 */

import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

/*  Here we are passing a particular state from root state to get selectDomain  */
const selectDomain = (state: RootState) => state.authState || initialState;

export const selectToken = createSelector(
	[selectDomain],
	authState => authState.token,
);

export const selectEmail = createSelector(
	[selectDomain],
	authState => authState.email,
);

export const selectIsAgreed = createSelector(
	[selectDomain],
	authState => authState.isTermsAgreed,
);

export const selectLoginForm = createSelector(
	[selectDomain],
	authState => authState,
);

export const selectLoading = createSelector(
	[selectDomain],
	authState => authState.loading,
);

export const selectError = createSelector(
	[selectDomain],
	authState => authState.error,
);
export const selectForgotForm = createSelector(
	[selectDomain],
	state => state.forgotPasswordForm,
);
export const selectResetPasswordForm = createSelector(
	[selectDomain],
	state => state.resetForm,
);
export const selectSetAccountForm = createSelector(
	[selectDomain],
	state => state.setAccountForm,
);
export const selectPayOffer = createSelector(
	[selectDomain],
	state => state.payOffer,
);
export const selectForgotEmail = createSelector(
	[selectDomain],
	state => state.forgotPasswordForm.email,
);
export const selectForgotError = createSelector(
	[selectDomain],
	state => state.forgotPasswordForm.error,
);
export const selectUserType = createSelector(
	[selectDomain],
	state => state.userType,
);
export const selectSetAccountGuestsError = createSelector(
	[selectDomain],
	state => state.setAccountGuestsError,
);
export const selectPractitionerPayment = createSelector(
	[selectDomain],
	state => state.payment,
);
export const selectPractitionerTemplates = createSelector(
	[selectDomain],
	state => state.practitionerTemplates,
);
export const selectClinicId = createSelector(
	[selectDomain],
	state => state.clinicId,
);
export const selectPractitionerRole = createSelector(
	[selectDomain],
	state => state.practitionerRole,
);
