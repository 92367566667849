import CustomModal from 'app/components/CustomModal';
import { IconBluePlus } from 'assets/images';
import React from 'react';
import { useStyles } from '../Exclude/styles';
import { TxtBtn } from '../Autocomplete';

export interface Props {
	message: string;
	handleIgnore: any;
	openModal: boolean;
	setOpenModal: any;
	txtBtn: TxtBtn;
	_txtBtn: any;
}

export function ConflictsModal({
	message,
	handleIgnore,
	openModal,
	setOpenModal,
	txtBtn,
	_txtBtn,
}: Props) {
	const styles = useStyles();

	return (
		<CustomModal
			btnTxt={txtBtn}
			openModal={openModal}
			onSubmit={() => handleIgnore()}
			setOpen={() => {
				setOpenModal(false);
			}}
			Icon={IconBluePlus}
			heading="Conflicts"
			secondHeading={message}
			modalClass={[styles.saveNameMClass]}
			onlyOneButton={txtBtn === 'Ok'}
			_btnTxt={_txtBtn}
		></CustomModal>
	);
}
