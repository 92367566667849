import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import {
	selectClinincForm,
	selectContactPersonForm,
	selectActiveClinic,
} from './selector';
import { actions } from './slice';
import { API_URL } from 'utils/constants';
import { emailValidator } from 'utils/helpers';
import { t } from 'i18next';
import { pageSize } from 'app/containers/Practitioner';

/**
 * Github repos request/response handler
 */

export function* searchClinicRequest(action) {
	const searchTerm = action.payload;
	yield delay(1000);
	const options = {
		method: 'GET',
		// body: JSON.stringify({ searchTerm: searchTerm, pageSize: 100, pageNo: 1 }),
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/clinic?pageSize=${pageSize}&pageNo=1`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'BASIC_FOOD.FOOD_CATEGORIES_FETCH.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(actions.updateclinicList(response.data[0].results));
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}
export function* searchClinicContactPersonRequest(action) {
	const searchTerm = action.payload;
	yield delay(1000);
	let selected_clinic = yield select(selectActiveClinic);
	const options = {
		method: 'GET',
		// body: JSON.stringify({ searchTerm: searchTerm, pageSize: 100, pageNo: 1 }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/contactperson?pageSize=${pageSize}&pageNo=1&clinic=${selected_clinic}`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'BASIC_FOOD.FOOD_CATEGORIES_FETCH.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(actions.updateContactPersonList(response.data[0].results));
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}
export function* getClinicContactPersonRequest(action) {
	const id = action.payload;
	yield delay(1000);

	const options = {
		method: 'GET',
		// body: JSON.stringify({ searchTerm: searchTerm, pageSize: 100, pageNo: 1 }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/contactperson/${id}`,
			options,
		);

		if (
			response &&
			response.messageCode !== 'MESSAGE.CONTACT_PERSON_GET.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield put(
			actions.updateContactFormValue({
				key: 'name',
				value: response.data.name,
			}),
		);
		yield put(
			actions.updateContactFormValue({
				key: 'email',
				value: response.data.email,
			}),
		);
		yield put(
			actions.updateContactFormValue({ key: '_id', value: response.data._id }),
		);
		yield put(
			actions.updateContactFormValue({
				key: 'clinic_id',
				value: response.data.clinic_id,
			}),
		);
		yield put(actions.toggleAddContactFromPopup());
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}
export function* deleteContactPersonRequest(action) {
	const { id } = action.payload;
	yield delay(1000);

	const options = {
		method: 'DELETE',
		// body: JSON.stringify({ searchTerm: searchTerm, pageSize: 100, pageNo: 1 }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/contactperson/${id}`,
			options,
		);

		if (
			response &&
			response.messageCode !== 'MESSAGE.CONTACT_PERSON_DELETE.SUCCESS'
		) {
			yield put(actions.setError(response.message));
			return;
		}
		yield call(action?.payload?.callback);
		yield put(actions.searchClinicsContactPerson(''));
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}
export function* addContactPersonRequest(action) {
	yield delay(500);
	const { name, email, clinic_id } = yield select(selectContactPersonForm);

	if (name.length === 0) {
		yield put(actions.setcontactError('Please enter full name'));
		return;
	}
	if (email.length === 0) {
		yield put(actions.setcontactError('Please enter contact person email'));
		return;
	}
	if (!emailValidator(email)) {
		yield put(
			actions.setcontactError('Please enter correct contact person email'),
		);
		return;
	}

	const options = {
		method: 'POST',
		body: JSON.stringify({ name, email, clinic_id }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/contactperson/add`,
			options,
		);
		if (response && response.messageCode !== 'MESSAGE.CLINIC_ADD.SUCCESS') {
			yield put(actions.setcontactError(t(`${response.messageCode}`)));
			return;
		}
		yield call(action?.payload?.callback);
		yield put(actions.addSuccess());
		yield put(actions.searchClinicsContactPerson(''));
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setcontactError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setcontactError('something went wrong'));
		}
		console.log(e);
	}
}
export function* editContactPersonRequest(action) {
	yield delay(500);
	const form: { name: string; email: string; clinic_id: string; _id: string } =
		yield select(selectContactPersonForm);

	if (form.name.length === 0) {
		yield put(actions.setcontactError('Please enter full name'));
		return;
	}
	if (form.email.length === 0) {
		yield put(actions.setcontactError('Please enter contact person email'));
		return;
	}
	if (!emailValidator(form.email)) {
		yield put(
			actions.setcontactError('Please enter correct contact person email'),
		);
		return;
	}
	const options = {
		method: 'PUT',
		body: JSON.stringify(form),
	};
	try {
		const response = yield call(request, `${API_URL}/contactperson`, options);
		if (response && response.messageCode !== 'MESSAGE.CLINIC_ADD.SUCCESS') {
			yield put(actions.setcontactError(t(`${response.messageCode}`)));
			return;
		}
		yield call(action?.payload?.callback);
		yield put(actions.addSuccess());
		yield put(actions.searchClinicsContactPerson(''));
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setcontactError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setcontactError('something went wrong'));
		}
		console.log(e);
	}
}
export function* addClinicRequest(action) {
	yield delay(500);
	const form: { full_name: string } = yield select(selectClinincForm);
	if (form.full_name.length === 0) {
		yield put(actions.setError('Please enter full name'));
		return;
	}

	const options = {
		method: 'POST',
		body: JSON.stringify(form),
	};
	try {
		const response = yield call(request, `${API_URL}/clinic/add`, options);
		if (response && response.messageCode !== 'MESSAGE.CLINIC_ADD.SUCCESS') {
			yield put(actions.setError(t(`${response.messageCode}`)));
			return;
		}
		yield call(action?.payload?.callback);
		yield put(actions.addSuccess());
		yield put(actions.searchClinics(''));
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}

		console.log(JSON.parse(e.message));
	}
}

export function* DeleteClinic(action) {
	const { id } = action.payload;
	yield delay(1000);
	const options = {
		method: 'DELETE',
	};
	try {
		const response = yield call(request, `${API_URL}/clinic/${id}`, options);
		if (response && response.messageCode !== 'MESSAGE.CLINIC_DELETE.SUCCESS') {
			yield put(actions.setError(t(`${response.messageCode}`)));
			return;
		}
		yield call(action?.payload?.callback);
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setError('something went wrong'));
		}
		console.log(e);
	}
}
export function* ClinicRepoSaga() {
	yield takeLatest(actions.searchClinics.type, searchClinicRequest);
	yield takeLatest(
		actions.searchClinicsContactPerson.type,
		searchClinicContactPersonRequest,
	);
	yield takeLatest(
		actions.fetchEditContactPerson.type,
		getClinicContactPersonRequest,
	);
	yield takeLatest(actions.addClinic.type, addClinicRequest);
	yield takeLatest(actions.editContactPerson.type, editContactPersonRequest);
	yield takeLatest(
		actions.deleteContactPerson.type,
		deleteContactPersonRequest,
	);
	yield takeLatest(actions.addContactPerson.type, addContactPersonRequest);
	yield takeLatest(actions.deleteSelectedClinic.type, DeleteClinic);
}
