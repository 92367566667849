import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
	state.practitionerState || initialState;
export const selectPractitionersList = createSelector(
	[selectDomain],
	state => state.practitionersList,
);
export const selectPractitionersListtotal = createSelector(
	[selectDomain],
	state => state.pageCount,
);
export const selectPractitionersListtotalCount = createSelector(
	[selectDomain],
	state => state.totalCount,
);
export const selectArchivePractitionersList = createSelector(
	[selectDomain],
	state => state.practitionersListArchived,
);
export const selectArchivedPractitionersListtotal = createSelector(
	[selectDomain],
	state => state.pageCountArchived,
);
export const selectArchivedPractitionersListtotalCount = createSelector(
	[selectDomain],
	state => state.totalCountArchived,
);
export const selectSearchTerm = createSelector(
	[selectDomain],
	state => state.searchTerm,
);
export const selectArchivedSearchTerm = createSelector(
	[selectDomain],
	state => state.searchTermArchieved,
);
export const selectFrom = createSelector([selectDomain], state => state);
export const selectSnacbar = createSelector(
	[selectDomain],
	state => state.snackbar,
);
export const selectSelectedPractitioner = createSelector(
	[selectDomain],
	state => state.selected_practitioner,
);
export const selectSelectedPractitionerName = createSelector(
	[selectDomain],
	state => state.selected_practitioner_name,
);
export const selectAddPractitionerOpen = createSelector(
	[selectDomain],
	state => state.isAddPractitionerOpen,
);
export const selectDeletePractitionerOpen = createSelector(
	[selectDomain],
	state => state.isDeletePractitionerOpen,
);
export const selectSideMenuPractitionersList = createSelector(
	[selectDomain],
	state => state.sidemenuPractitionersList,
);
export const selectArchivePractitionerOpen = createSelector(
	[selectDomain],
	state => state.isArchivePractitionerOpen,
);
export const selectUnArchivePractitionerOpen = createSelector(
	[selectDomain],
	state => state.isUnArchivePractitionerOpen,
);
export const selectIsEditAction = createSelector(
	[selectDomain],
	state => state.isEditAction,
);
export const selectSidebarOpen = createSelector(
	[selectDomain],
	state => state.isSidebarOpen,
);
export const selectAddClinicPopupOpen = createSelector(
	[selectDomain],
	state => state.isAddClinicPopupOpen,
);
export const selectselectedId = createSelector(
	[selectDomain],
	state => state.selectedId,
);
export const selectselectedName = createSelector(
	[selectDomain],
	state => state.selectedName,
);
export const selectSelectedPaymentStatus = createSelector(
	[selectDomain],
	state => state.selectedPaymentStatus,
);
export const selectSelectedNumOfClients = createSelector(
	[selectDomain],
	state => state.selectedNumOfClients,
);
export const selectReAssignedPractitionerId = createSelector(
	[selectDomain],
	state => state.reAssignedPractitionerId,
);
export const selectPractitionerLoading = createSelector(
	[selectDomain],
	state => state.loading,
);
export const selectSelectedRole = createSelector(
	[selectDomain],
	state => state.selectedRole,
);
export const selectError = createSelector([selectDomain], state => state.error);
