import { actions as patientActions } from '../../../redux/slice';
import { Button } from '@mui/material';
import { selectOpenAllVitamins } from 'app/containers/Patients/redux/selector';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
export const NUM_OF_RENDER = 4;

const MoreLessButton = ({ numberOfRender, setNumberOfRender, data }) => {
	const useOpenAllVitamins = useSelector(selectOpenAllVitamins);
	const dispatch = useDispatch();
	useEffect(() => {
		if (useOpenAllVitamins) {
			setNumberOfRender(data?.length);
			dispatch(patientActions.openAllVitamins(false));
		}
	}, [data?.length, dispatch, setNumberOfRender, useOpenAllVitamins]);
	return (
		<Button
			style={{
				backgroundColor: '#FFFFFF',
				color: '#3A1086',
				marginLeft: 'auto',
				marginTop: '10px',
			}}
			size="small"
			sx={{ borderRadius: '50px' }}
			onClick={event => {
				if (numberOfRender === NUM_OF_RENDER) {
					setNumberOfRender(data?.length);
				} else {
					setNumberOfRender(NUM_OF_RENDER);
				}
			}}
		>
			{numberOfRender === NUM_OF_RENDER ? 'More' : 'Less'}
		</Button>
	);
};

export default MoreLessButton;
