import * as React from 'react';

import { Backdrop, Box, Modal, Fade, Button, Typography } from '@mui/material';
import clsx from 'clsx';
import { useStyles } from './styles';
export interface Props {
	openModal: any;
	setOpen: any;
	children?: any;
	heading: any;
	secondHeading: any;
	Icon: any;
	modalClass: any;
	btnTxt: any;
	onSubmit?: any;
	disabled?: boolean;
	onClose?: any;
	showChildrenFirst?: any;
	onlyOneButton?: any;
	_btnTxt?: any;
}

function CustomModal({
	openModal,
	setOpen,
	children,
	heading,
	secondHeading,
	Icon,
	modalClass,
	btnTxt,
	onSubmit,
	disabled,
	onClose,
	showChildrenFirst,
	onlyOneButton,
	_btnTxt,
}: Props) {
	const styles = useStyles();
	const handleClose = () => {
		setOpen(false);
		if (onClose) onClose();
	};
	return (
		<Modal
			open={openModal}
			onClose={handleClose}
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			style={{ overflow: 'scroll', boxShadow: 'none' }}
			className={clsx(styles.modal)}
		>
			<Fade in={openModal}>
				<Box component="div" className={clsx([styles.popup, ...modalClass])}>
					<Button className={clsx(styles.popupIcon)}>
						<img src={Icon} alt="icon" />
					</Button>
					{showChildrenFirst && (
						<Box
							component="form"
							sx={{ maxWidth: '100%' }}
							style={{ marginBottom: '10px' }}
						>
							{children}
						</Box>
					)}
					<Typography
						id="transition-modal-title"
						variant="h6"
						component="h2"
						className={clsx(styles.title)}
					>
						{heading}
					</Typography>
					<Typography id="transition-modal-description" sx={{ mt: 2 }}>
						{secondHeading}
					</Typography>
					{!showChildrenFirst && (
						<Box
							component="form"
							sx={{
								'@media (max-width:600px)': {
									minWidth: '80%',
								},
								minWidth: 500,
								maxWidth: '100%',
							}}
							style={{ marginTop: '30px' }}
						>
							{children}
						</Box>
					)}

					<Box component="div" display="flex" justifyContent="flex-end" pt={3}>
						<Button
							className={clsx([styles.fbtn, styles.fbtn1])}
							onClick={() => {
								if (onSubmit) {
									onSubmit();
								} else {
									handleClose();
								}
							}}
							variant="contained"
							disabled={disabled}
						>
							{btnTxt}
						</Button>
						{onlyOneButton ? null : (
							<Button
								onClick={handleClose}
								className={clsx(styles.fbtn)}
								variant="text"
							>
								{_btnTxt ? _btnTxt : 'Cancel'}
							</Button>
						)}
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
}

export default CustomModal;
