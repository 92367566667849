import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.clinicState || initialState;
export const selectClinicList = createSelector(
	[selectDomain],
	state => state.clinicList,
);
export const selectClinincForm = createSelector(
	[selectDomain],
	clinicState => clinicState,
);
export const selectContactPersonForm = createSelector(
	[selectDomain],
	clinicState => clinicState.contact_person_form,
);
export const selectAddClinicPopupOpen = createSelector(
	[selectDomain],
	clinicState => clinicState.isAddClinicPopupOpen,
);
export const selectAddContactFromPopupOpen = createSelector(
	[selectDomain],
	state => state.isAddContactFromPopupOpen,
);
export const selectContactPersonList = createSelector(
	[selectDomain],
	state => state.contactpersonList,
);
export const selectActiveClinic = createSelector(
	[selectDomain],
	state => state.selected_clinic,
);
export const selectActiveClinicName = createSelector(
	[selectDomain],
	state => state.selected_clinic_name,
);
export const selectActiveClinicIsVirtual = createSelector(
	[selectDomain],
	state => state.selected_clinic_is_virtual,
);

export const selectSearchTerm = createSelector(
	[selectDomain],
	state => state.searchTerm,
);
export const selectSnacbar = createSelector(
	[selectDomain],
	state => state.snackbar,
);
