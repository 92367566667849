import SearchIc from 'app/components/SearchIcon';
import clsx from 'clsx';
import React from 'react';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
// import './styles.css';
import Autocomplete from '@mui/material/Autocomplete';

import { useStyles } from './styles';
import MuiTextField from '@mui/material/TextField';

export const SearchAutoComplete = ({
	width = 0,
	className = '',
	endIcon = <SearchIc />,
	placeholder = '',
	items = [],
	handleOnChange,
	...props
}: {
	width?: number;
	className: string;
	endIcon: any;
	placeholder: string;
	items: Array<any>;
	handleOnChange?: any;
}) => {
	const classes = useStyles({
		width,
	});
	const window = useWindowDimensions();
	return (
		<Autocomplete
			options={items}
			onChange={handleOnChange}
			renderInput={params => (
				<MuiTextField
					{...params}
					margin="normal"
					className={clsx([
						'TextInput',
						classes.TextInput,
						classes.OutlinedInput,
						className,
					])}
					//  InputProps={{ endAdornment: endIcon }}
					placeholder={
						window.width > 1500
							? placeholder
							: placeholder.length > 20
							? placeholder.substr(0, 19) + '...'
							: placeholder
					}
					{...props}
				/>
			)}
			{...props}
		/>
	);
};
