import React from 'react';

type Props = {};

const SearchIcon = (props: Props) => {
	return (
		<div {...props}>
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M31.3742 27.5476L25.35 21.5221C25.2145 21.387 25.0536 21.28 24.8766 21.2075C24.6996 21.1349 24.51 21.0981 24.3187 21.0992H23.3373C25.305 18.5771 26.2369 15.3994 25.9428 12.2138C25.6488 9.0283 24.1511 6.07487 21.7551 3.95577C19.359 1.83667 16.2451 0.711484 13.0482 0.809632C9.8513 0.907779 6.81222 2.22187 4.55061 4.48396C2.28901 6.74606 0.9752 9.7858 0.877074 12.9834C0.778948 16.181 1.90389 19.2956 4.02254 21.6921C6.14118 24.0887 9.09396 25.5867 12.2788 25.8808C15.4637 26.1749 18.6407 25.2428 21.1622 23.2747V24.2581C21.1611 24.4494 21.1979 24.6391 21.2705 24.8161C21.3431 24.9931 21.45 25.154 21.585 25.2895L27.6093 31.3151C27.7435 31.4501 27.9032 31.5573 28.079 31.6304C28.2548 31.7036 28.4433 31.7412 28.6337 31.7412C28.8241 31.7412 29.0126 31.7036 29.1884 31.6304C29.3642 31.5573 29.5238 31.4501 29.6581 31.3151L31.3682 29.6046C31.6396 29.3318 31.7924 28.963 31.7935 28.5782C31.7946 28.1934 31.644 27.8237 31.3742 27.5493V27.5476ZM13.4278 21.1009C11.8981 21.1009 10.4027 20.6472 9.13076 19.7971C7.85884 18.947 6.86749 17.7388 6.28209 16.3252C5.69669 14.9117 5.54352 13.3562 5.84196 11.8555C6.14039 10.3549 6.87703 8.97643 7.95871 7.89451C9.04039 6.8126 10.4185 6.07581 11.9189 5.77731C13.4192 5.47881 14.9743 5.63201 16.3876 6.21754C17.8009 6.80307 19.0089 7.79462 19.8587 9.06682C20.7086 10.339 21.1622 11.8347 21.1622 13.3648C21.1625 14.3807 20.9627 15.3867 20.5741 16.3254C20.1855 17.264 19.6158 18.1169 18.8975 18.8352C18.1792 19.5535 17.3264 20.1231 16.3879 20.5116C15.4494 20.9001 14.4435 21.0997 13.4278 21.0992V21.1009Z"
					fill="#3C4858"
				/>
			</svg>
		</div>
	);
};

export default SearchIcon;
