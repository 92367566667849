import React, { useEffect } from 'react';

import { Box } from '@mui/material';

import { useStyles } from './styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { selectOpenFaq } from 'app/containers/Help/redux/selector';
import HelpPage from 'app/containers/Help/containers';
import { actions } from 'app/containers/Help/redux/slice';

const Content = ({ children }) => {
	const styles = useStyles();
	const dispatch = useDispatch();
	const useOpenFaq = useSelector(selectOpenFaq);

	useEffect(() => {
		return () => {
			dispatch(actions.closeFaq(false));
		};
	}, [dispatch]);

	return (
		<Box className={clsx(styles.root)}>
			{useOpenFaq && <HelpPage />}
			<Box
				className={clsx(styles.contentRoot, { [styles.shrink]: useOpenFaq })}
			>
				{children}
			</Box>
		</Box>
	);
};

export default Content;
