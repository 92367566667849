import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Container,
	Grid,
	Typography,
} from '@mui/material';
import Header from 'app/components/Header';
import UserRightBar from 'app/components/UserRightBar';
import Content from 'app/components/content';
import { selectUserType } from 'app/containers/Auth/selectors';
import { actions as actionsPractitioner } from 'app/containers/Practitioner/redux/slice';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { PromoCodePopup } from './PromoCodePopup';
import PromoCodesListTable from './PromoCodesList';
import { actions as actionsPromoCode, reducer, sliceKey } from './slice';
import { useStyles } from './style';
import { PromoCodeRepoSaga } from './saga';
import { ClinicRepoSaga } from '../Clinics/redux/saga';
import {
	reducer as reducerClinic,
	sliceKey as sliceKeyClinic,
} from '../Clinics/redux/slice';
function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const PromoCodes = () => {
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: PromoCodeRepoSaga });
	useInjectReducer({ key: sliceKeyClinic, reducer: reducerClinic });
	useInjectSaga({ key: sliceKeyClinic, saga: ClinicRepoSaga });
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const styles = useStyles();

	useEffect(() => {
		dispatch(actionsPromoCode.getPromoCodeList());
	}, []);
	const showAddPromoCodePopup = () => {
		dispatch(actionsPromoCode.toggleAddPromoCodePopup());
	};
	return (
		<Box display="flex" alignItems={'center'}>
			<Header />
			<Content>
				<Grid container spacing={2} className={clsx(styles.root)}>
					<Grid item lg={12} xs={12}>
						<Box display="flex" style={{ height: '100%' }}>
							<Container
								maxWidth={'xl'}
								sx={{ height: 'calc( 100vh - 80px )', overflowY: 'scroll' }}
							>
								<Box className={clsx(styles.wrapper)}>
									<Box
										component="div"
										display="flex"
										justifyContent={'space-between'}
									>
										<Button
											onClick={() => navigate('/clinics')}
											className={clsx(styles.btn)}
											variant="contained"
											color="primary"
										>
											Back
										</Button>
										<Box component="div">
											<Typography
												className={clsx(styles.subheading)}
												variant="h1"
											>
												Promo codes
											</Typography>
										</Box>
										<Button
											onClick={() => showAddPromoCodePopup()}
											className={clsx(styles.btn)}
											variant="contained"
											color="primary"
										>
											Add a new promo code
										</Button>
									</Box>
									<Box component="div" className={clsx(styles.TableBox)}>
										<PromoCodesListTable />
									</Box>
								</Box>
							</Container>
							<UserRightBar />
						</Box>
					</Grid>
				</Grid>
				<PromoCodePopup />
			</Content>
		</Box>
	);
};

export default PromoCodes;
