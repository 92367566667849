import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	buttonBox: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	addButton: {
		borderRadius: 50,
	},
}));
