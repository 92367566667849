import { Box } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useStyles } from './style';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { actions } from 'app/containers/Help/redux/slice';
export function DropzoneImg() {
	const styles = useStyles();
	const dispatch = useDispatch();
	const [dragNDropText, setDragNDropText] = useState(
		'Drag & drop the IMG file to upload',
	);
	const onDrop = useCallback(
		acceptedFiles => {
			acceptedFiles.forEach(file => {
				const reader = new FileReader();
				reader.onload = evt => {
					const input: string | ArrayBuffer | undefined | null =
						evt?.target?.result;
					if (input) {
						try {
							dispatch(actions.setSelectedQuestionImage(file));
						} catch (err: any) {
							console.error('Invalid IMG: ' + err.message);
						}
					}
					if (reader.result) {
						setDragNDropText('');
					}
				};
				reader.readAsText(file);
			});
		},
		[dispatch],
	);
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.svg'],
		},
	});
	const files = acceptedFiles.map((file: any) => (
		<p key={file?.path}>
			{file?.path} - {file?.size} bytes
		</p>
	));
	return (
		<Box className={clsx(styles.container)}>
			<div {...getRootProps()}>
				<input {...getInputProps()} />
				{dragNDropText && (
					<>
						<CloudUploadOutlinedIcon fontSize={'medium'} />
						<p className={clsx(styles.mainText)}>{dragNDropText}</p>
						<p className={clsx(styles.secondaryText)}>Or browse</p>
					</>
				)}
			</div>
			<p className={clsx(styles.secondaryText)}>{files}</p>
		</Box>
	);
}
