import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	reportBox: {
		display: 'flex',
		borderRadius: '30px',
		paddingTop: '2%',
		paddingLeft: '4%',
		border: '1px solid #000',
	},
	tableBox: {
		flexDirection: 'column',
		// borderRadius: 50,
		// border: '1px solid #000',
		padding: '5px 3%',
	},
	buttonsContainer: {
		flexDirection: 'column',
	},
	rowsContainer: {
		flexDirection: 'column',
		margin: '0px 10px',
	},
	rowContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 3,
		paddingTop: 3,
		// borderBottom: '1px solid #000',
	},
	rightContainer: {
		flexDirection: 'column',
		width: '30%',
		marginRight: '5%',
	},
	leftContainer: {
		flexDirection: 'column',
		width: '60%',
	},
	buttonsBox: {
		display: 'flex',
		flexDirection: 'row',
	},
	matchBasedsContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	matchBasedContainer: {
		width: '40%',
		marginRight: 10,
	},
	userExcludedLabelsContainer: {
		width: '40%',
	},
	title: {
		color: '#000000 ',
		fontSize: '16px',
		fontWeight: '400',
		margin: 0,
	},
	site: {
		color: '#3a1086 ',
		fontSize: '12px',
		fontWeight: '600',
		margin: 0,
		textDecorationLine: 'underline',
	},
	date: {
		color: theme.palette.grey[60],
		fontSize: '12px',
		fontWeight: '600',
		marginTop: 5,
	},
	matchBased: {
		color: '#00AB3D',
		fontSize: '12px',
		fontWeight: '600',
		marginTop: 5,
	},
	userExcludedLabels: {
		color: '#ff3407',
		fontSize: '12px',
		fontWeight: '600',
		marginTop: 5,
	},
	line: {
		width: 1,
		height: 20,
		backgroundColor: '#938E9C',
		marginLeft: 10,
	},
	lineBox: {
		marginLeft: 10,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));
