import { RootState } from 'types';
import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.helpState || initialState;
export const selectOpenFaq = createSelector(
	[selectDomain],
	state => state.openFaq,
);
export const selectFaqList = createSelector(
	[selectDomain],
	state => state.faqList,
);
export const selectFaqListUser = createSelector(
	[selectDomain],
	state => state.faqListUser,
);
export const selectError = createSelector([selectDomain], state => state.error);
export const selectAddTopicPopupOpen = createSelector(
	[selectDomain],
	state => state.addTopicPopupOpen,
);
export const selectDeleteTopicPopupOpen = createSelector(
	[selectDomain],
	state => state.deleteTopicPopupOpen,
);
export const selectAddQuestionError = createSelector(
	[selectDomain],
	state => state.addQuestionError,
);
export const selectSelectedQuestion = createSelector(
	[selectDomain],
	state => state.selectedQuestion,
);
export const selectActionType = createSelector(
	[selectDomain],
	state => state.actionType,
);
