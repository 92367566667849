import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { request } from 'utils/request';
import { API_URL } from 'utils/constants';
import { t } from 'i18next';
const errorMessage = 'Something was wrong';

export function* addNewQuestion(action) {
	const options = {
		method: 'POST',
		body: JSON.stringify(action?.payload?.question),
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/faqs/new-question`,
			options,
		);
		if (response && response.messageCode !== 'MESSAGE.ADD_FAQ.SUCCESS') {
			if (!response?.message) {
				yield put(actions.setAddQuestionError(t('something went wrong')));
			} else {
				yield put(actions.setAddQuestionError(t(`${response.message}`)));
			}
		} else {
			yield call(action?.payload?.callback);
			yield put(actions.setAddTopicPopupOpen(false));
			yield put(actions.setAddQuestionError(''));
		}
	} catch (e: any) {
		let errors = JSON.parse(e.message);
		yield put(actions.setAddQuestionError(errors.message));
	}
}

export function* setOrder(action) {
	const options = {
		method: 'POST',
		body: JSON.stringify(action?.payload),
	};

	try {
		const response = yield call(request, `${API_URL}/faqs/set-order`, options);
		if (response && response.messageCode !== 'MESSAGE.SET_ORDER.SUCCESS') {
		} else {
			yield call(action?.payload?.orderCallback);
		}
	} catch (e: any) {
		console.log('Error:', JSON.parse(e.message));
	}
}

export function* getFaqListAdmin(action) {
	const options = {
		method: 'GET',
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/faqs/get-faqs-admin`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'FAQ_WEB_LIST_FETCHED_SUCCESSFULLY'
		) {
			yield put(actions.setError(errorMessage));
			return;
		}
		yield put(actions.setFaqList(response?.data));
	} catch (e) {
		yield put(actions.setError(errorMessage));
		console.log(e);
	}
}

export function* getFaqListUser(action) {
	const options = {
		method: 'GET',
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/faqs/get-faqs-user`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'FAQ_WEB_LIST_FETCHED_SUCCESSFULLY'
		) {
			yield put(actions.setError(errorMessage));
			return;
		}
		yield put(actions.setFaqListUser(response?.data));
	} catch (e) {
		yield put(actions.setError(errorMessage));
		console.log(e);
	}
}

export function* deleteQuestion(action) {
	const options = {
		method: 'DELETE',
		body: JSON.stringify({ _id: action?.payload?.questionId }),
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/faqs/delete-question`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.DELETE_QUESTION.SUCCESS'
		) {
			if (!response?.message) {
				yield put(actions.setAddQuestionError(t('something went wrong')));
			} else {
				yield put(actions.setAddQuestionError(t(`${response.message}`)));
			}
		} else {
			yield put(actions.setAddQuestionError(t('')));
			yield call(action?.payload?.callback);
			yield put(actions.setDeleteTopicPopupOpen(false));
			yield put(actions.setAddQuestionError(''));
		}
	} catch (e: any) {
		let errors = JSON.parse(e.message);
		yield put(actions.setAddQuestionError(errors.message));
	}
}

export function* HelpRepoSaga() {
	yield takeLatest(actions.getFaqListAdmin.type, getFaqListAdmin);
	yield takeLatest(actions.getFaqListUser.type, getFaqListUser);
	yield takeLatest(actions.addNewQuestion.type, addNewQuestion);
	yield takeLatest(actions.deleteQuestion.type, deleteQuestion);
	yield takeLatest(actions.setOrder.type, setOrder);
}
