/**
 * Gets the repositories of the user from Github
 */

import { t } from 'i18next';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { API_URL, basicDomain } from 'utils/constants';
import { request } from 'utils/request';
import {
	selectEmail,
	selectForgotEmail,
	selectLoginForm,
	selectResetPasswordForm,
	selectSetAccountForm,
} from './selectors';
import { actions, logoutSuccess } from './slice';
import { LoginErrorType } from './types';
import { actions as practitionerAction } from '../Practitioner/redux/slice';
import { SET_PASSWORD } from '../Paypal/PaypalButton';

/**
 * Github repos request/response handler
 */

export function* resetPassword(action) {
	const token = action.payload?.token;
	yield delay(500);
	const { password } = yield select(selectResetPasswordForm);
	const options = {
		method: 'POST',
		body: JSON.stringify({ token, password }),
	};
	try {
		const response = yield call(request, `${API_URL}/auth/password`, options);
		if (response && response.message !== 'MESSAGES.AUTH.PASSWORD_UPDATED') {
			yield put(
				actions.updateResetForm({ name: 'error', value: response.message }),
			);
			return;
		}
		yield put(actions.resetPasswordSuccess());
		yield call(action?.payload?.callback);
	} catch (e) {
		console.log(e);
	}
}

export function* setPassword(action) {
	const token = action.payload?.token;
	const location = action.payload?.location;

	yield delay(500);
	const { password, fullName, promoCode } = yield select(selectSetAccountForm);
	const options = {
		method: 'POST',
		body: JSON.stringify({ token, password, fullName, promoCode, location }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/auth/set-account`,
			options,
		);
		const userId = response?.data?.userId;
		const link = response?.data?.promoCodeData?.payment_link;
		const paymentMethod = response?.data?.promoCodeData?.payment_method;
		if (!userId) {
			yield put(
				actions.updateSetAccountForm({
					name: 'error',
					value: `We don't have the information we need to move you to the payment stage, ${response?.message}`,
				}),
			);
			yield put(actions.setPasswordField());
			yield put(actions.setLoading(false));
			return;
		}
		if (paymentMethod !== 'Free' && !link) {
			yield put(
				actions.updateSetAccountForm({
					name: 'error',
					value: 'There was a problem with the link to the payment step',
				}),
			);
			yield put(actions.setPasswordField());
			yield put(actions.setLoading(false));
			return;
		}

		if (response && response.messageCode === 'MESSAGES.AUTH.SET_ACCOUNT.FREE') {
			yield put(actions.setPasswordSuccess(response?.data?.promoCodeData));
			yield call(action?.payload?.freeCallback('/login'));
			yield put(actions.setLoading(false));
			return;
		} else if (
			response &&
			response.messageCode === 'MESSAGES.AUTH.SET_ACCOUNT.STRIPE'
		) {
			yield put(actions.setPasswordSuccess(response?.data?.promoCodeData));
			yield call(
				action?.payload?.stripeCallback(
					`${link}?client_reference_id=${userId}`,
				),
			);
			return;
		} else if (
			response &&
			response.messageCode === 'MESSAGES.AUTH.SET_ACCOUNT.PAYPAL'
		) {
			yield put(actions.setPasswordSuccess(response.data.promoCodeData));
			yield call(action?.payload?.stripeCallback(link));
			return;
		}
		yield put(
			actions.updateSetAccountForm({
				name: 'error',
				value: response.message,
			}),
		);
		yield put(actions.setPasswordField());
		yield put(actions.setLoading(false));
		return;
	} catch (e) {
		yield put(actions.setLoading(false));
		console.log(e);
	}
}

export function* setAccount(action) {
	const data = action.payload?.data;
	yield delay(500);
	const options = {
		method: 'POST',
		body: JSON.stringify(data),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/auth/set-account-guests`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.PRACTITIONER_ADD.SUCCESS'
		) {
			yield put(actions.updateSetAccountGuestsError(response.message));
			yield put(actions.setLoading(false));
			return;
		}
		yield call(action?.payload?.callback);
		yield put(actions.setLoading(false));
		return;
	} catch (e) {
		console.log(e);
	}
}

export function* validateReCAPTCHAToken(action) {
	if (!action.payload?.data) {
		yield put(actions.updateSetAccountGuestsError('Something was wrong'));
		return;
	}

	yield delay(500);
	const options = {
		method: 'POST',
		body: JSON.stringify(action.payload),
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/auth/validate-recaptcha-token`,
			options,
		);
		if (response && response.messageCode !== 'RECAPTCHA.STATUS.SUCCESS') {
			yield put(actions.updateSetAccountGuestsError(response.message));
			return;
		} else {
			yield call(action?.payload?.callback);
			return;
		}
	} catch (e) {
		console.log(e);
		yield put(actions.updateSetAccountGuestsError(e));
	}
}

export function* forgotPassword(action) {
	yield delay(500);
	const email = yield select(selectForgotEmail);
	const options = {
		method: 'POST',
		body: JSON.stringify({ email }),
	};
	try {
		const response = yield call(request, `${API_URL}/auth/password`, options);
		if (
			response &&
			response.message !== 'MESSAGES.AUTH.FORGOT_PASSWORD_MAIL_SENT'
		) {
			yield put(actions.setForgotError(response.message));
			return;
		}
		yield put(actions.forgotPasswordSuccess());
		yield call(action?.payload?.callback);
	} catch (e) {
		console.log(e);
	}
}

export function* logoutRequest() {
	yield delay(500);
	yield localStorage.removeItem('sessionToken');
	yield put(logoutSuccess());
}
export function* meProfileRequest(action) {
	yield put(actions.setLoading(true));
	yield delay(500);
	const options = {
		method: 'GET',
	};
	try {
		// Call our request helper (see 'utils/request')
		const response = yield call(
			//TODO - revert this back
			request,
			`${API_URL}/auth/me`,
			options,
		);
		if (response) {
			yield put(
				actions.updateUserInfo({
					email: response.email,
					name: response.fullName,
					payment: response?.payment,
					practitionerTemplates: response?.templates,
					clinicId: response?.clinic_id,
					practitionerRole: response?.practitionerRole,
				}),
			);
			yield put(actions.setLoading(false));
			return false;
		}
		// yield put(actions.loginSuccess(response));
		// yield call(action.payload?.callback);
	} catch (err: any) {
		yield put(actions.setLoading(false));
		if (err.response?.status === 401) {
			yield put(actions.logout());
			//yield put(actions.loginError(err.message));
		} else {
			//yield put(actions.loginError(LoginErrorType.ERROR));
		}
	}
}

export function* checkVersion(action) {
	yield delay(2000);
	const options = {
		method: 'POST',
		body: JSON.stringify({ version: action?.payload }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/auth/check-version`,
			options,
		);

		if (response?.data?.update) {
			window.location.reload();
			return;
		}
	} catch (err: any) {}
}

export function* loginRequest(action) {
	yield delay(500);
	// Select username from store

	const form: { username: string; password: string; path: string } =
		yield select(selectLoginForm);
	if (form.username.length === 0) {
		yield put(actions.loginError(LoginErrorType.USERNAME_EMPTY));
		return;
	}
	if (form.password.length === 0) {
		yield put(actions.loginError(LoginErrorType.PASSWORD_EMPTY));
		return;
	}
	const options = {
		method: 'POST',
		body: JSON.stringify({
			email: form.username,
			password: form.password,
			path: form.path,
		}),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/auth/admin/login`,
			options,
		);
		if (response && response.messageCode !== 'MESSAGES.USER.LOGIN_SUCCESS') {
			yield put(actions.setError(t(`${response.message}`)));
			return false;
		}
		if (
			response &&
			(response.userType === 'PRACTITIONER' || response.userType === '2')
		) {
			yield localStorage.setItem('practitionerId', response._id);
			yield localStorage.setItem('practitionerName', response.fullName);
			yield put(practitionerAction.setActivePractitioner(response._id));
			yield put(
				practitionerAction.setActivePractitionerName(response.fullName),
			);
		}
		yield localStorage.setItem('sessionToken', response.token);
		yield put(actions.updateUserType(response.userType));
		yield localStorage.setItem('userType', response.userType);
		yield put(actions.loginSuccess(response));
		yield put(
			actions.updateUserInfo({
				email: response.email,
				name: response.fullName,
				payment: response?.payment,
				practitionerTemplates: response?.templates,
				clinicId: response?.clinic_id,
				practitionerRole: response?.practitionerRole,
			}),
		);
		yield put(actions.loadUser());
		if (response?.isTermsAgreed) {
			yield call(action.payload?.navigateCallback);
		} else {
			yield call(action.payload?.popupCallback);
		}
	} catch (err: any) {
		console.log('err', err);

		if (err.response?.status) {
			yield put(actions.loginError(err.message));
		}
		//  else if (err.response?.message) {
		//   yield put(actions.setError(err.message));
		// }c
		else {
			yield put(actions.loginError(LoginErrorType.ERROR));
		}
	}
}

export function* setAgreedTerms(action) {
	yield delay(500);
	const userEmail = yield select(selectEmail);
	const options = {
		method: 'POST',
		body: JSON.stringify({
			email: userEmail,
		}),
	};
	try {
		// Call our request helper (see 'utils/request')
		const response = yield call(
			//TODO - revert this back
			request,
			`${API_URL}/practitioner/terms`,
			options,
		);

		if (response) {
			yield put(actions.loginSuccess(response));
			yield call(action?.payload?.callback);
		}
	} catch (err: any) {
		yield put(actions.setError(t(`${err}`)));
	}
}

export function* onApprove(action) {
	yield put(actions.setLoading(true));
	const data = action.payload?.data;
	const sourceComponent = action.payload?.sourceComponent;
	if (sourceComponent === SET_PASSWORD) {
		data.token = action.payload?.token;
	}
	const options = {
		method: 'POST',
		body: JSON.stringify({
			data,
		}),
	};

	try {
		let response;
		if (sourceComponent === SET_PASSWORD) {
			response = yield call(
				request,
				`${API_URL}/auth/paypal/complete-payment-register`,
				options,
			);
		} else {
			response = yield call(
				request,
				`${API_URL}/auth/paypal/complete-payment`,
				options,
			);
		}
		if (
			response &&
			response.message !== 'MESSAGES.PAYPAL.COMPLETE_PAYMENT_SUCCESS'
		) {
			yield call(action?.payload?.onField(response?.message));
			return;
		}
		yield call(action?.payload?.onSuccess);
		yield put(
			actions.updateUserInfo({
				email: response.email,
				name: response.fullName,
				payment: response?.payment,
				practitionerTemplates: response?.templates,
				clinicId: response?.clinic_id,
				practitionerRole: response?.practitionerRole,
			}),
		);
		yield put(actions.loadUser());
		yield put(actions.setLoading(false));
		return;
	} catch (e) {
		console.log('error:', e);
		yield call(action?.payload?.onField(e));
	}
}
/**
 * Root saga manages watcher lifecycle
 */
export function* userRepoSaga() {
	// Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
	// By using `takeLatest` only the result of the latest API call is applied.
	// It returns task descriptor (just like fork) so we can continue execution
	// It will be cancelled automatically on component unmount
	yield takeLatest(actions.loadUser.type, meProfileRequest);
	yield takeLatest(actions.login.type, loginRequest);
	yield takeLatest(actions.logout.type, logoutRequest);
	yield takeLatest(actions.forgotPassword.type, forgotPassword);
	yield takeLatest(actions.resetPassword.type, resetPassword);
	yield takeLatest(actions.setPassword.type, setPassword);
	yield takeLatest(actions.setAgreedTerms.type, setAgreedTerms);
	yield takeLatest(actions.onApprove.type, onApprove);
	yield takeLatest(actions.setAccount.type, setAccount);
	yield takeLatest(actions.validateReCAPTCHAToken.type, validateReCAPTCHAToken);
	yield takeLatest(actions.checkVersion.type, checkVersion);
}
