import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
	},
	select: {
		borderRadius: '10px',
		backgroundColor: '#fff',
		width: 400,
		'@media (max-width:600px)': {
			width: '95%',
		},
	},
	listSubheader: {
		fontWeight: 600,
	},
	selectText: {
		color: '#555555',
		fontWeight: 500,
		fontSize: 18,
		margin: 0,
		paddingLeft: 10,
		marginRight: 10,
		'@media (max-width:600px)': {
			fontWeight: 400,
			fontSize: 12,
			paddingLeft: 0,
		},
	},
	modalClass: {},
	menuItemContent: {
		display: 'flex',
		alignItems: 'center',
	},
	label: {
		fontSize: '14px',
		color: theme.palette.primary.main,
		fontWeight: '700',
		marginBottom: 0,
	},
	labelDiv: {
		marginBottom: 15,
	},
	referencesContainer: {
		maxHeight: 300,
		overflow: 'auto',
	},
}));
