import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		borderRadius: '30px',
		background: '#FFF',
		paddingTop: '8px 0',
		border: '#3A1086 dashed 2.5px',
		height: 85,
		width: 250,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		marginRight: 20,
		'@media (max-width:600px)': {
			paddingTop: '0px',
			margin: '0px 2%',
			height: 50,
		},
	},
	flex: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
	},
	mainText: {
		color: '#000000',
		fontWeight: '700',
		fontSize: '12px',
		marginTop: 5,
		marginBottom: 0,
		'@media (max-width:600px)': {
			marginTop: 0,
			fontSize: '0px',
		},
	},
	secondaryText: {
		color: '#3A1086',
		fontWeight: '600',
		fontSize: '12px',
		marginBottom: 0,
		marginTop: 0,
		'@media (max-width:600px)': {
			fontSize: '0px',
		},
	},
}));
