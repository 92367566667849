import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	selectNutrientAndVitaminDetails,
	selectNutrientsAndVitaminsSubDetails,
	selectRestrictionsDetails,
} from '../../redux/selector';
import { Ingredient, Nutrition, UserNutrition } from '../../types';
export const DEFAULT_VALUE = 1;
export const LOW_VALUE = 0;
export const HIGH_VALUE = 2;
export const DEFAULT_KEY = 'midValue';

export const getSelectedValue = object => {
	const selectedValue = object.selectedValue.value;
	if (selectedValue === LOW_VALUE) {
		object.selectedValue.key = 'lowValue';
	} else if (selectedValue === HIGH_VALUE) {
		object.selectedValue.key = 'highValue';
	}
	return object;
};

export const updateFarPerType = (nutrientData, value?, type?) => {
	for (const nutrient of nutrientData) {
		if (Array.isArray(nutrient?.fatPerTypes) && nutrient?.fatPerTypes?.length) {
			for (const nutrientInFPT of nutrient.fatPerTypes) {
				const index = nutrientData.findIndex(
					n => n.optionId === nutrientInFPT.optionId,
				);
				if (index > -1) {
					if (value && type) {
						if (nutrientData[index].optionId === nutrientInFPT.optionId) {
							if (type === 'lowValue') {
								nutrientInFPT.lowValue = nutrientData[index].lowValue;
							} else if (type === 'highValue') {
								nutrientInFPT.highValue = nutrientData[index].highValue;
							}
						}
					} else {
						if (nutrientData[index].selectedValue.key === 'lowValue') {
							nutrientInFPT.lowValue = nutrientData[index].lowValue;
						} else if (nutrientData[index].selectedValue.key === 'highValue') {
							nutrientInFPT.highValue = nutrientData[index].highValue;
						}
					}
					nutrientInFPT.selectedValue.toFilter =
						nutrientData[index].selectedValue?.toFilter;
					nutrientInFPT.notDefault = nutrientData[index].notDefault;
				}
			}
		}
	}
	return nutrientData;
};

export const checkWhichValueIsDefault = (
	nutrientData,
	type,
	nutrientAndVitaminDetails,
) => {
	const defaultData =
		type === 'nutrients'
			? nutrientAndVitaminDetails[0]
			: nutrientAndVitaminDetails[1];
	let _nutrientData = nutrientData?.map(nutrient => {
		const defaultNutrient = defaultData.results.find(
			n => n.optionId === nutrient.optionId,
		);
		if (defaultNutrient) {
			return {
				...nutrient,
				notDefault:
					defaultNutrient.lowValue !== undefined &&
					defaultNutrient.lowValue !== nutrient.lowValue
						? 'lowValue'
						: defaultNutrient.highValue !== undefined &&
						  defaultNutrient.highValue !== nutrient.highValue
						? 'highValue'
						: null,
			};
		} else {
			return nutrient;
		}
	});
	if (defaultData.label === 'Nutrients') {
		const _nutrients = updateFarPerType(_nutrientData);
		return _nutrients;
	} else {
		return _nutrientData;
	}
};

const getSliderValue = (selectedValue, keyLabel) => {
	if (keyLabel === 'lowValue') {
		selectedValue.value = LOW_VALUE;
	} else if (keyLabel === 'midValue') {
		selectedValue.value = DEFAULT_VALUE;
	} else {
		selectedValue.value = HIGH_VALUE;
	}
	return selectedValue;
};

const convertValueToSliderType = (responseData, object, selectedValue) => {
	const selectedResponseData = responseData.filter(
		nutrientData => nutrientData.optionId === object.optionId,
	);
	let fatPerTypes: Array<Nutrition> = [];
	if (selectedResponseData?.length > 0) {
		const selectedResponseItem = selectedResponseData[0];
		if (
			Array.isArray(selectedResponseItem.fatPerTypes) &&
			selectedResponseItem.fatPerTypes?.length > 0
		) {
			fatPerTypes = selectedResponseItem.fatPerTypes;
			fatPerTypes = fatPerTypes.map((fatPerType: Nutrition) => {
				fatPerType = {
					...fatPerType,
					selectedValue: getSliderValue(
						{},
						fatPerType?.selectedValue?.key ?? DEFAULT_KEY,
					),
				};
				return fatPerType;
			});
		}
		if (selectedResponseItem.selectedValue) {
			const keyLabel = selectedResponseItem.selectedValue.key;
			selectedValue.key = keyLabel;
			selectedValue = getSliderValue(selectedValue, keyLabel);
			selectedValue.toFilter =
				selectedResponseItem.selectedValue?.toFilter ||
				selectedResponseItem.selectedValue?.toFilter === false
					? selectedResponseItem.selectedValue?.toFilter
					: false;
		}
	}
	return {
		updatedValue: selectedValue,
		newValue: selectedResponseData[0]?.selectedValue?.value,
		updatedFatPerTypes: fatPerTypes,
	};
};

export const useUpdatingRequirementsPatient = (
	setMacroNutrientsDetails,
	data,
) => {
	const nutrientAndVitaminDetails = useSelector(
		selectNutrientAndVitaminDetails,
	);

	const [userVitamins, setUserVitamins] = useState<UserNutrition[]>([]);
	const [nutrients, setNutrients] = useState<Nutrition[]>([]);
	const [vitaminMinerals, setVitaminMinerals] = useState<Nutrition[]>([]);
	const [practitionerExcluded, setPractitionerExcluded] = useState<
		Ingredient[]
	>([]);
	const [practitionerIncluded, setPractitionerIncluded] = useState<
		Ingredient[]
	>([]);
	const [practitionerIngredientsWarning, setPractitionerIngredientsWarning] =
		useState<Ingredient[]>([]);

	useEffect(() => {
		setUserVitamins([]);
		if (
			Array.isArray(nutrientAndVitaminDetails) &&
			nutrientAndVitaminDetails?.length > 0 &&
			data
		) {
			const { practitionerPreferences } = data;
			let patientNutrientsData: UserNutrition[] = [];
			if (
				practitionerPreferences &&
				practitionerPreferences.practitioner_nutritional_array &&
				practitionerPreferences.practitioner_nutritional_array.length > 0
			) {
				patientNutrientsData =
					practitionerPreferences.practitioner_nutritional_array;
			}
			const { macroNutrientsIds } = nutrientAndVitaminDetails[0];
			const newMacroSubDetailsState = macroNutrientsIds?.map(subDetails => {
				const { nutrientId, nutrientName, subNutrients } = subDetails;
				const numberOfSubNutrients = subNutrients[0].results?.length;
				return {
					nutrientId,
					nutrientName,
					numberOfSubNutrients,
				};
			});
			setMacroNutrientsDetails(newMacroSubDetailsState);
			let nutrientData = nutrientAndVitaminDetails[0].results;
			let value;
			nutrientData = nutrientData?.map(nutrient => {
				let selectedValue = {
					value: DEFAULT_VALUE,
					key: DEFAULT_KEY,
					toFilter: false,
				};

				let fatPerTypes: Array<Nutrition> = [];
				if (patientNutrientsData.length > 0) {
					const { updatedValue, updatedFatPerTypes, newValue } =
						convertValueToSliderType(
							patientNutrientsData,
							nutrient,
							selectedValue,
						);
					value = newValue;
					selectedValue = updatedValue;
					fatPerTypes = updatedFatPerTypes;
				}

				nutrient = { ...nutrient, selectedValue, fatPerTypes };
				if (selectedValue?.key === 'lowValue') {
					nutrient.lowValue = value;
				} else if (selectedValue?.key === 'highValue') {
					nutrient.highValue = value;
				}
				nutrient.sliderValues = [];
				nutrient.sliderValues.push({ value: LOW_VALUE });
				nutrient.sliderValues.push({ value: DEFAULT_VALUE });
				nutrient.sliderValues.push({ value: HIGH_VALUE });
				return nutrient;
			});
			// const _nutrientData = updateFarPerType(nutrientData);
			const n = checkWhichValueIsDefault(
				nutrientData,
				'nutrients',
				nutrientAndVitaminDetails,
			);
			setNutrients(n);

			if (nutrientAndVitaminDetails?.length > 1) {
				let vitaminData = nutrientAndVitaminDetails[1].results;
				let value;
				if (
					practitionerPreferences &&
					practitionerPreferences.practitioner_vitamins_array &&
					practitionerPreferences.practitioner_vitamins_array.length > 0
				) {
					setUserVitamins([
						...practitionerPreferences.practitioner_vitamins_array,
					]);
				}
				vitaminData = vitaminData.map(vitamin => {
					let selectedValue = { value: DEFAULT_VALUE, key: DEFAULT_KEY };
					if (
						practitionerPreferences?.practitioner_vitamins_array &&
						[...practitionerPreferences?.practitioner_vitamins_array]?.length
					) {
						const { updatedValue, newValue } = convertValueToSliderType(
							[...practitionerPreferences.practitioner_vitamins_array],
							vitamin,
							selectedValue,
						);
						selectedValue = updatedValue;
						value = newValue;
					}
					vitamin = { ...vitamin, selectedValue };
					if (selectedValue?.key === 'lowValue') {
						vitamin.lowValue = value;
					} else if (selectedValue?.key === 'highValue') {
						vitamin.highValue = value;
					}
					vitamin.selectedValue.toFilter = vitamin.selectedValue?.toFilter
						? vitamin.selectedValue?.toFilter
						: false;
					vitamin.sliderValues = [];
					vitamin.sliderValues.push({ value: LOW_VALUE, text: 'Low' });
					vitamin.sliderValues.push({
						value: DEFAULT_VALUE,
						text: 'No preference',
					});
					vitamin.sliderValues.push({ value: HIGH_VALUE, text: 'High' });
					return vitamin;
				});
				vitaminData.sort((a, b) => {
					if (a.sortOrder && b.sortOrder && a.sortOrder < b.sortOrder) {
						return -1;
					}
					if (a.sortOrder && b.sortOrder && a.sortOrder > b.sortOrder) {
						return 1;
					}
					return 0;
				});
				const v = checkWhichValueIsDefault(
					vitaminData,
					'vitamins',
					nutrientAndVitaminDetails,
				);
				setVitaminMinerals(v);

				if (
					practitionerPreferences &&
					practitionerPreferences.practitioner_excluded &&
					practitionerPreferences.practitioner_excluded.length > 0
				) {
					setPractitionerExcluded(
						practitionerPreferences.practitioner_excluded,
					);
				} else {
					setPractitionerExcluded([]);
				}
				if (
					practitionerPreferences &&
					practitionerPreferences.practitioner_included &&
					practitionerPreferences.practitioner_included.length > 0
				) {
					setPractitionerIncluded(
						practitionerPreferences.practitioner_included,
					);
				} else {
					setPractitionerIncluded([]);
				}
				if (
					practitionerPreferences &&
					practitionerPreferences.practitioner_ingredients_warning &&
					practitionerPreferences.practitioner_ingredients_warning.length > 0
				) {
					setPractitionerIngredientsWarning(
						practitionerPreferences.practitioner_ingredients_warning,
					);
				} else {
					setPractitionerIngredientsWarning([]);
				}
			}
		}
	}, [nutrientAndVitaminDetails, data]);

	return {
		userVitamins,
		setUserVitamins,
		nutrients,
		setNutrients,
		vitaminMinerals,
		setVitaminMinerals,
		practitionerExcluded,
		setPractitionerExcluded,
		practitionerIncluded,
		setPractitionerIncluded,
		practitionerIngredientsWarning,
		setPractitionerIngredientsWarning,
	};
};

export const useUpdatingFarPerType = (
	macroNutrientsDetails,
	nutrients,
	setNutrients,
) => {
	const nutrientsAndVitaminsSubDetails = useSelector(
		selectNutrientsAndVitaminsSubDetails,
	);
	const [loadingSubNutrients, setLoading] = useState(false);

	useEffect(() => {
		if (
			Array.isArray(nutrientsAndVitaminsSubDetails) &&
			nutrientsAndVitaminsSubDetails.length > 0 &&
			!loadingSubNutrients
		) {
			const currentMacroId = macroNutrientsDetails?.find(
				({ numberOfSubNutrients }) =>
					numberOfSubNutrients === nutrientsAndVitaminsSubDetails.length,
			)?.nutrientId;

			setLoading(true);
			let nutrientSubDetails = [...nutrientsAndVitaminsSubDetails];
			nutrientSubDetails = nutrientSubDetails.map(nutrient => {
				const userNutrientsValue = nutrients.find(
					n => n?.optionId === nutrient?.optionId,
				);
				const value =
					userNutrientsValue?.selectedValue?.value !== undefined &&
					userNutrientsValue?.selectedValue?.value !== DEFAULT_VALUE
						? userNutrientsValue?.selectedValue?.value
						: DEFAULT_VALUE;
				const selectedValue = {
					value,
					key: DEFAULT_KEY,
				};
				nutrient = { ...nutrient, selectedValue };
				nutrient.sliderValues = [];
				nutrient.sliderValues.push({ value: LOW_VALUE });
				nutrient.sliderValues.push({ value: DEFAULT_VALUE });
				nutrient.sliderValues.push({ value: HIGH_VALUE });
				return nutrient;
			});
			const nutrientData = [...nutrients];
			const index = nutrientData.findIndex(
				nutrient => nutrient?.optionId === currentMacroId,
			);
			if (nutrientData[index]) {
				nutrientData[index].fatPerTypes = nutrientSubDetails || [];
			}
			const _nutrientData = updateFarPerType(nutrientData);
			setNutrients([..._nutrientData]);
			setLoading(false);
		}
	}, [nutrientsAndVitaminsSubDetails]);
};

export const sendingDataToTemplateOrToUpdateUser = (
	nutrients,
	vitaminMinerals,
	practitionerExcluded,
	practitionerIncluded,
	practitionerIngredientsWarning,
	restrictions,
	patientDetail,
) => {
	const vitaminsWithUserValue = vitaminMinerals
		.map(vitamin => {
			if (vitamin?.selectedValue?.value !== DEFAULT_VALUE) {
				vitamin = getSelectedValue(vitamin);
				return vitamin;
			}
		})
		.filter(item => item);
	const userVitamins: UserNutrition[] = vitaminsWithUserValue
		.filter(item => item)
		.map(vitamin => {
			if (vitamin?.selectedValue?.value === LOW_VALUE) {
				vitamin.selectedValue.value = vitamin.lowValue;
			} else if (vitamin?.selectedValue?.value === HIGH_VALUE) {
				vitamin.selectedValue.value = vitamin.highValue;
			}
			return {
				optionId: vitamin?.optionId,
				selectedValue: vitamin?.selectedValue,
			};
		}) as UserNutrition[];

	const nutrientsWithUserValue = nutrients
		.map(nutrition => {
			if (nutrition?.selectedValue?.value !== DEFAULT_VALUE) {
				nutrition = getSelectedValue(nutrition);
				if (
					Array.isArray(nutrition.fatPerTypes) &&
					nutrition.fatPerTypes?.length > 0
				) {
					nutrition.fatPerTypes = nutrition.fatPerTypes.map(fatPerType => {
						return getSelectedValue(fatPerType);
					});
				}
				return nutrition;
			}
		})
		.filter(item => item);

	const userNutrients: UserNutrition[] = nutrientsWithUserValue
		.filter(item => item)
		.map(nutrient => {
			if (nutrient?.selectedValue?.value === LOW_VALUE) {
				nutrient.selectedValue.value = nutrient.lowValue;
			} else if (nutrient?.selectedValue?.value === HIGH_VALUE) {
				nutrient.selectedValue.value = nutrient.highValue;
			}
			return {
				optionId: nutrient?.optionId,
				selectedValue: nutrient?.selectedValue,
			};
		}) as UserNutrition[];

	let _restrictions = restrictions
		?.map(restriction => {
			if (restriction.select) {
				return {
					optionId: restriction?.optionId,
					optionCategoryIds: restriction?.optionCategoryIds,
					categoriesNames: restriction?.categoriesNames || [],
				};
			} else {
				return null;
			}
		})
		.filter(item => item);

	const updateDataForPatientDetail = {
		_id: patientDetail['_id'],
		restrictions: _restrictions,
		practitionerPreferences: [
			{
				key: 'VITAMINS_REQUIREMENTS',
				selectedOptions: userVitamins,
			},
			{
				key: 'NUTRITIONAL_REQUIREMENTS',
				selectedOptions: userNutrients,
			},
			{
				key: 'LIKES',
				selectedOptions: practitionerIncluded,
			},
			{
				key: 'DISLIKES',
				selectedOptions: practitionerExcluded,
			},
			{
				key: 'INGREDIENTS_WARNING',
				selectedOptions: practitionerIngredientsWarning,
			},
		],
	};

	return [userNutrients, userVitamins, updateDataForPatientDetail];
};

export const useResetNutrients = setNutrients => {
	const nutrientAndVitaminDetails = useSelector(
		selectNutrientAndVitaminDetails,
	);
	const resetNutrients = () => {
		let nutrientData = nutrientAndVitaminDetails[0].results;
		nutrientData = nutrientData?.map(nutrient => {
			let selectedValue = {
				value: DEFAULT_VALUE,
				key: DEFAULT_KEY,
				toFilter: false,
			};
			let fatPerTypes: Array<Nutrition> = [];
			nutrient = { ...nutrient, selectedValue, fatPerTypes };
			nutrient.sliderValues = [];
			nutrient.sliderValues.push({ value: LOW_VALUE });
			nutrient.sliderValues.push({ value: DEFAULT_VALUE });
			nutrient.sliderValues.push({ value: HIGH_VALUE });
			return nutrient;
		});
		const n = checkWhichValueIsDefault(
			nutrientData,
			'nutrients',
			nutrientAndVitaminDetails,
		);
		setNutrients(n);
	};
	return { resetNutrients };
};

export const useResetVitamins = setVitaminMinerals => {
	const nutrientAndVitaminDetails = useSelector(
		selectNutrientAndVitaminDetails,
	);
	const resetVitamins = () => {
		let vitaminData = nutrientAndVitaminDetails[1].results;
		vitaminData = vitaminData.map(vitamin => {
			let selectedValue = {
				value: DEFAULT_VALUE,
				key: DEFAULT_KEY,
				toFilter: false,
			};
			vitamin = { ...vitamin, selectedValue };
			vitamin.sliderValues = [];
			vitamin.sliderValues.push({ value: LOW_VALUE, text: 'Low' });
			vitamin.sliderValues.push({
				value: DEFAULT_VALUE,
				text: 'No preference',
			});
			vitamin.sliderValues.push({ value: HIGH_VALUE, text: 'High' });
			return vitamin;
		});
		vitaminData.sort((a, b) => {
			if (a.sortOrder && b.sortOrder && a.sortOrder < b.sortOrder) {
				return -1;
			}
			if (a.sortOrder && b.sortOrder && a.sortOrder > b.sortOrder) {
				return 1;
			}
			return 0;
		});
		const v = checkWhichValueIsDefault(
			vitaminData,
			'vitamins',
			nutrientAndVitaminDetails,
		);
		setVitaminMinerals(v);
	};
	return { resetVitamins };
};

export const useResetRestrictions = (restrictions, setRestrictions) => {
	const resetRestrictions = () => {
		let _restrictions: any = [];
		_restrictions = restrictions
			.filter(res => !res.mandatory)
			?.map(restriction => {
				return { ...restriction, select: false };
			});
		setRestrictions(_restrictions);
	};
	return { resetRestrictions };
};

export const useUpdatingRestrictions = data => {
	const useRestrictionsDetails: any = useSelector(selectRestrictionsDetails);
	const [restrictions, setRestrictions] = React.useState(
		useRestrictionsDetails,
	);
	useEffect(() => {
		let _restrictions: any = [];
		_restrictions = useRestrictionsDetails;
		_restrictions = _restrictions
			.filter(res => !res.mandatory)
			?.map(restriction => {
				let select = false;
				data?.dieteryPreferences?.restriction_array?.forEach(element => {
					if (element.optionId === restriction.optionId) {
						select = true;
					}
				});
				return { ...restriction, select };
			});
		setRestrictions(_restrictions);
	}, [data?.dieteryPreferences?.restriction_array, useRestrictionsDetails]);
	return { restrictions, setRestrictions };
};

export const filterListBySelectedPrevItems = (
	firstArray,
	secondArray,
	list,
) => {
	const filteredData = list?.filter(ingredient => {
		if (secondArray) {
			return !secondArray.some(secondArrayItem => {
				return ingredient._id === secondArrayItem._id;
			});
		}
		return true;
	});
	const filteredData2 = filteredData?.filter(ingredient => {
		if (firstArray) {
			return !firstArray.some(firstArrayItem => {
				return ingredient._id === firstArrayItem._id;
			});
		}
		return true;
	});
	return filteredData2;
};

export const saveChangeChildMacro = (
	optionId,
	value,
	type,
	nutrients,
	setNutrients,
) => {
	const nutrientData = [...nutrients];
	const index = nutrientData.findIndex(
		nutrient => nutrient.optionId === optionId,
	);
	if (type === 'lowValue') {
		nutrientData[index].lowValue = value;
		nutrientData[index].notDefault = type;
	} else if (type === 'highValue') {
		nutrientData[index].highValue = value;
		nutrientData[index].notDefault = type;
	}
	const _nutrientData = updateFarPerType(nutrientData, value, type);
	setNutrients([..._nutrientData]);
};
export const useResetValueToDefault = (
	nutrientId,
	nutrients,
	setNutrients,
	vitaminMinerals,
	setVitaminMinerals,
) => {
	const nutrientAndVitaminDetails = useSelector(
		selectNutrientAndVitaminDetails,
	);
	const resetValue = () => {
		for (const item of nutrientAndVitaminDetails) {
			const defaultObject = item.results.find(
				nut => nut.optionId === nutrientId,
			);
			if (defaultObject) {
				const nutrientType = item.label;
				if (nutrientType === 'Nutrients') {
					const nutrientData = [...nutrients];
					const index = nutrientData.findIndex(
						nutrient => nutrient?.optionId === nutrientId,
					);
					if (index > -1) {
						nutrientData[index].lowValue = defaultObject.lowValue;
						nutrientData[index].highValue = defaultObject.highValue;
					}
					const n = checkWhichValueIsDefault(
						nutrientData,
						'nutrients',
						nutrientAndVitaminDetails,
					);
					setNutrients([...n]);
				} else {
					const vitaminMineralsData = [...vitaminMinerals];
					const index = vitaminMineralsData.findIndex(
						nutrient => nutrient.optionId === nutrientId,
					);
					if (index > -1) {
						vitaminMineralsData[index].lowValue = defaultObject.lowValue;
						vitaminMineralsData[index].highValue = defaultObject.highValue;
					}
					const v = checkWhichValueIsDefault(
						vitaminMineralsData,
						'vitamins',
						nutrientAndVitaminDetails,
					);
					setVitaminMinerals([...v]);
				}
			}
		}
	};
	return [resetValue];
};
