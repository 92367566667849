import { colors, createTheme } from '@mui/material';

const theme = createTheme({
	direction: 'rtl',
	palette: {
		background: {
			default: colors.common.white,
			paper: colors.common.white,
		},
		primary: {
			main: '#40018C',
			light: '#E5E5E5',
			dark: '#3A1086',
			100: '#EFEFEF',
			200: '#F8F4FE',
		},
		secondary: {
			main: '#FE5AA7',
			dark: '#100323',
		},
		error: {
			main: '#F32E1B',
		},
		grey: {
			50: '#F5F5F6',
			100: '#0F172A',
			200: '#BEBEBE',
			300: '#c3c0c8',
			400: '#212121',
		},
		text: {
			primary: colors.blueGrey[900],
			secondary: colors.blueGrey[600],
		},
	},
	typography: {
		allVariants: {
			textTransform: 'none',
			fontFamily: `"Plus Jakarta Sans", "Roboto", "Helvetica", "Arial", sans-serif`,
		},
	},
});

export default theme;
