import React from 'react';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { useStyles } from './styles';
import InfoIcon from '../InfoIcon';

interface HeadingProps {
	title: string;
	subtitle?: string;
	infoText?: string;
	children?: any;
}
const Heading = ({ subtitle, title, children, infoText }: HeadingProps) => {
	const styles = useStyles();
	return (
		<Box className={clsx(styles.root)}>
			<Typography variant="subtitle2" className={clsx(styles.small)}>
				{subtitle}
			</Typography>
			<Box className={clsx(styles.titleBox)}>
				<Typography variant="h2" className={clsx(styles.heading)}>
					{title}
				</Typography>
				<InfoIcon infoText={infoText}>{children}</InfoIcon>
			</Box>
		</Box>
	);
};

export default Heading;
