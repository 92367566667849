import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	root: { marginBottom: '10px' },
	mainheading: {
		margin: '0',
		fontSize: '32px',
		color: theme.palette.grey[100],
		fontWeight: '700',
		'@media (max-width:600px)': {
			fontSize: 12,
		},
	},
	heading: {
		margin: '0',
		fontSize: '25px',
		color: theme.palette.grey[100],
		fontWeight: '700',
		'@media (max-width:600px)': {
			fontSize: 12,
		},
	},
	smallHeading: {
		margin: '0',
		fontSize: '14px',
		color: theme.palette.grey[100],
		fontWeight: '400',
		'@media (max-width:600px)': {
			fontSize: 12,
		},
	},
	small: { fontSize: '13px', color: 'GrayText' },
	titleBox: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
}));
