import React, { useEffect, useState } from 'react';
import {
	Box,
	Container,
	Button,
	ButtonGroup,
	TableRow,
	TableHead,
	TableBody,
	TableContainer,
	Table,
	Grid,
	InputLabel,
	TextField,
	Typography,
	IconButton,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { PractitionerRepoSaga } from '../../Practitioner/redux/saga';
import { sliceKey, reducer, actions } from '../../Practitioner/redux/slice';
import {
	actions as actionsClinic,
	sliceKey as sliceKeyClinic,
	reducer as reducerClinic,
} from '../../Clinics/redux/slice';
import { ClinicRepoSaga } from '../../Clinics/redux/saga';
import { selectActiveClinic } from 'app/containers/Clinics/redux/selector';
import { useInjectSaga, useInjectReducer } from 'redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectFrom,
	selectArchivePractitionersList,
	selectselectedName,
	selectUnArchivePractitionerOpen,
	selectDeletePractitionerOpen,
} from '../redux/selector';
import CustomModal from '../../../components/CustomModal';
import {
	IconTrashRed,
	IconTrash,
	IconSearch,
	IconBlueArchive,
	IconUnArchive,
} from '../../../../assets/images';
import { useStyles } from './styles';

const ArchiveList = () => {
	const classes = useStyles();
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: PractitionerRepoSaga });
	useInjectReducer({ key: sliceKeyClinic, reducer: reducerClinic });
	useInjectSaga({ key: sliceKeyClinic, saga: ClinicRepoSaga });
	const selectedName = useSelector(selectselectedName);
	const isUnArchivePractitionerOpen = useSelector(
		selectUnArchivePractitionerOpen,
	);
	const rows = useSelector(selectArchivePractitionersList);
	const activeClinic = useSelector(selectActiveClinic);
	const isDeletePractitionerOpen = useSelector(selectDeletePractitionerOpen);

	const dispatch = useDispatch();
	const onPractitionerArchiveSuccess = () => {
		//navigate('/clinics');
	};
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.common,
			color: '#645B70',
			fontWeight: '400',
			background: theme.palette.primary.light,
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
			fontWeight: '500',
		},
	}));

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'& .MuiTableCell-root': {
			padding: '10px 20px',
		},
		'&:hover': {
			backgroundColor: '#ece6f4',
			cursor: 'pointer',
			'& .MuiTableCell-root': {
				color: theme.palette.primary.main,
			},
		},
	}));

	const [archivePopup, setArchivePopup] = useState(false);

	const closeUnArchivePractitioner = () => {
		dispatch(actions.toggleUnArchivePractitioner());
	};
	const closeDeletePractitioner = () => {
		dispatch(actions.toggleDeletePractitioner());
	};

	const onUnArchiveSubmitForm = () => {
		try {
			dispatch(
				actions.updatePractitionerStatus({
					status: 0,
					callback: onPractitionerArchiveSuccess,
				}),
			);
		} catch (e) {
			console.log(e);
		}
	};
	const onDeleteSubmitForm = () => {
		try {
			dispatch(
				actions.deletePractitioner({ callback: onPractitionerArchiveSuccess }),
			);
		} catch (e) {
			console.log(e);
		}
	};
	const UnArchivePractitionerHandler = (id: string, name: string) => {
		try {
			dispatch(actions.toggleUnArchivePractitioner());
			dispatch(
				actions.setSelectedPractitioner({ selectedId: id, selectedName: name }),
			);
		} catch (e) {
			console.log(e);
		}
	};
	const DeletePractitionerHandler = (id: string, name: string) => {
		try {
			dispatch(actions.toggleDeletePractitioner());
			dispatch(
				actions.setSelectedPractitioner({ selectedId: id, selectedName: name }),
			);
		} catch (e) {
			console.log(e);
		}
	};

	// ===============useEffects===========================
	useEffect(() => {
		if (activeClinic.length > 0) {
			dispatch(
				actions.searchPractitionersArchived({
					searchTerm: '',
				}),
			);
			dispatch(
				actions.updateFormValue({ key: 'clinic_id', value: activeClinic }),
			);
		}
	}, [activeClinic]);

	return (
		<Box display="flex" alignItems={'center'}>
			<Container maxWidth={'xl'}>
				<Grid container>
					<Grid item lg={11} xs={12}>
						<TableContainer>
							<Table
								sx={{ width: 700 }}
								aria-label="simple table"
								style={{ width: '100%', padding: '0' }}
								stickyHeader
							>
								<TableHead>
									<TableRow>
										<StyledTableCell style={{ paddingLeft: '15px' }}>
											Practitioner Name
										</StyledTableCell>
										<StyledTableCell align="left">Email</StyledTableCell>
										<StyledTableCell align="center" style={{ width: '15%' }}>
											Actions
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((item, index) => (
										<StyledTableRow
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<StyledTableCell
												style={{ paddingLeft: '15px' }}
												component="th"
												scope="row"
											>
												{item.fullName}
											</StyledTableCell>
											<StyledTableCell
												align="left"
												style={{ paddingLeft: '15px' }}
											>
												{item.email}
											</StyledTableCell>
											<StyledTableCell
												align="left"
												sx={{ width: '15%', textAlign: 'center' }}
											>
												<ButtonGroup
													className="iconBtnGroup"
													variant="text"
													aria-label="text button group"
													sx={{ justifyContent: 'flex-end' }}
												>
													<Button
														onClick={() => {
															UnArchivePractitionerHandler(
																item._id,
																item.fullName,
															);
														}}
													>
														<img src={IconUnArchive} alt="Archive" />
													</Button>
													<Button
														onClick={() => {
															DeletePractitionerHandler(
																item._id,
																item.fullName,
															);
														}}
													>
														<img src={IconTrash} alt="trash" />
													</Button>
												</ButtonGroup>
											</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</Container>
			{/* Archive Popup */}
			<CustomModal
				btnTxt="Reassign"
				openModal={archivePopup}
				setOpen={setArchivePopup}
				Icon={IconBlueArchive}
				heading="Archive a practitioner "
				secondHeading="You are about  to archive the following practitioner."
				modalClass={[classes.ReassignMClass, classes.archive]}
			>
				<InputLabel className={classes.label}>Client’s name</InputLabel>
				<TextField
					className={classes.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					id="fullWidth"
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
				<Box component={`div`} className={classes.formGroup}>
					<Box component="div" pb={3}>
						<Typography variant="h5">Choose a practitioner</Typography>
						<Typography variant="body1">
							Please reassign associated clients to another practitioner.
						</Typography>
					</Box>
					<TextField
						placeholder="Search archived practitioner..."
						InputProps={
							<IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
								<img src={IconSearch} alt={'IconSearch'} />
							</IconButton>
						}
						className={classes.searchInput}
						onKeyDown={(e: any) => {
							if (e.key === 'Enter') {
								e.preventDefault();
							}
						}}
					/>
				</Box>
			</CustomModal>
			{/* ./Archive Popup */}
			{/* Delete Popup */}
			<CustomModal
				btnTxt="UnArchive"
				openModal={isUnArchivePractitionerOpen}
				setOpen={closeUnArchivePractitioner}
				Icon={IconUnArchive}
				heading="UnArchive"
				secondHeading="You are about to UnArchive a practitioner."
				modalClass={[classes.TrashMClass]}
				onSubmit={() => {
					onUnArchiveSubmitForm();
				}}
			>
				<InputLabel className={classes.label}>practitioner’s name</InputLabel>
				<TextField
					className={classes.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					id="fullWidth"
					value={selectedName}
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</CustomModal>
			{/* ./Delete Popup */}
			{/* Delete Popup */}
			<CustomModal
				btnTxt="Delete"
				openModal={isDeletePractitionerOpen}
				setOpen={closeDeletePractitioner}
				Icon={IconTrashRed}
				heading="Delete"
				secondHeading="You are about to delete a practitioner."
				modalClass={[classes.TrashMClass]}
				onSubmit={() => {
					onDeleteSubmitForm();
				}}
			>
				<InputLabel className={classes.label}>practitioner’s name</InputLabel>
				<TextField
					className={classes.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					id="fullWidth"
					value={selectedName}
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</CustomModal>
			{/* ./Delete Popup */}
		</Box>
	);
};

export default ArchiveList;
