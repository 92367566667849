export default function MinusIcon() {
	return (
		<svg
			width="32"
			height="33"
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="16" cy="16.25" r="16" fill="#F9F9F9" />
			<path
				d="M11.25 15.5H10.75V16V16.75V17.25H11.25H21H21.5V16.75V16V15.5H21H11.25Z"
				fill="#A1A1A1"
				stroke="#A1A1A1"
			/>
		</svg>
	);
}
