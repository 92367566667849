import CancelIcon from '@mui/icons-material/Cancel';
import { Box, CircularProgress, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
	selectLoadingIngredients,
	selectSelectedPatient,
} from 'app/containers/Patients/redux/selector';
import { actions } from 'app/containers/Patients/redux/slice';
import { PatientDetail } from 'app/containers/Patients/types';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from '../../components/Exclude/styles';
import { filterListBySelectedPrevItems } from '../../utils';
import { ConflictsModal } from '../ConflictsModal';
export type IngredientSelection = 'Exclude' | 'Include' | 'Warning' | null;
export type TxtBtn = 'Ignore' | 'Ok' | null;
export type TxtBtn2 = 'Prevent' | null;
const _txtBtn: TxtBtn2 = 'Prevent';

interface AutocompleteIncludeExcludeProps {
	practitionerExcluded: any;
	practitionerIncluded: any;
	practitionerIngredientsWarning: any;
	ingredientsList: any;
	ingredientSearchTerm: string;
	setPractitionerIngredients: any;
	practitionerIngredients: any;
	autocompleteType: IngredientSelection;
	typeIngredientSelection: any;
	setTypeIngredientSelection: any;
	restrictions: any;
}

const AutocompleteIncludeExclude = ({
	practitionerExcluded,
	practitionerIncluded,
	practitionerIngredientsWarning,
	ingredientsList,
	ingredientSearchTerm,
	setPractitionerIngredients,
	practitionerIngredients,
	autocompleteType,
	typeIngredientSelection,
	setTypeIngredientSelection,
	restrictions,
}: AutocompleteIncludeExcludeProps) => {
	const patientDetail: PatientDetail = useSelector(selectSelectedPatient);
	const FOOD_LABELS = 'Ingredients';
	const CATEGORIES_LABEL = 'Group of ingredients';
	const styles = useStyles();
	const dispatch = useDispatch();
	const useLoadingIngredients = useSelector(selectLoadingIngredients);
	const [options, setOptions] = useState<any[]>([]);
	const [message, setMessage] = useState<string>('');
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [txtBtn, setTxtBtn] = useState<TxtBtn>(null);
	const [value, setValue] = useState<any>(null);

	const onChangeHandle = (type, value) => {
		dispatch(actions.searchIngredients(value));
		setTypeIngredientSelection(type);
		if (!value) {
			resetOptions();
			return;
		}
	};
	const checkForExistenceAndSetOptions = useCallback(
		(firstArray, secondArray, list) => {
			if (list && list?.length > 0) {
				const filteredListBySelectedPrevItems = filterListBySelectedPrevItems(
					firstArray,
					secondArray,
					list,
				);
				const _processingData = structuredClone(
					filteredListBySelectedPrevItems,
				);
				setOptions(_processingData);
			} else {
				setOptions([]);
			}
		},
		[],
	);
	const resetOptions = useCallback(() => {
		setOptions([]);
		dispatch(actions.setIngredientsSearchTerm(''));
	}, [dispatch]);

	useEffect(() => {
		resetOptions();
	}, [
		practitionerIncluded,
		practitionerExcluded,
		practitionerIngredientsWarning,
		resetOptions,
	]);
	useEffect(() => {
		if (!options.length) {
			dispatch(actions.setLoadingIngredients(false));
		}
	}, [dispatch, options.length]);
	useEffect(() => {
		if (typeIngredientSelection === 'Include') {
			checkForExistenceAndSetOptions(
				[...practitionerExcluded, ...practitionerIngredientsWarning],
				practitionerIncluded,
				ingredientsList,
			);
		} else if (typeIngredientSelection === 'Exclude') {
			checkForExistenceAndSetOptions(
				[...practitionerIncluded, ...practitionerIngredientsWarning],
				practitionerExcluded,
				ingredientsList,
			);
		} else {
			checkForExistenceAndSetOptions(
				[...practitionerIncluded, ...practitionerExcluded],
				practitionerIngredientsWarning,
				ingredientsList,
			);
		}
	}, [
		ingredientsList,
		practitionerExcluded,
		practitionerIncluded,
		checkForExistenceAndSetOptions,
		typeIngredientSelection,
		practitionerIngredientsWarning,
	]);
	const checkForConflicts = (
		previouslySelectedIngredients,
		newSelectedIngredient,
	) => {
		let previouslyConflicts;
		const previouslySelectedIngredientsAndRestrictions = [
			...previouslySelectedIngredients,
		];

		for (let previouslyIngredient of previouslySelectedIngredientsAndRestrictions) {
			if (
				previouslyIngredient?.type === 'category' ||
				newSelectedIngredient?.type === 'category'
			) {
				if (
					(previouslyIngredient?.optionCategoryIds || [])
						?.concat(previouslyIngredient?.optionCategoryIdsForConflicts)
						?.some(num =>
							(newSelectedIngredient?.optionCategoryIds || [])
								?.concat(newSelectedIngredient?.optionCategoryIdsForConflicts)
								?.includes(num),
						)
				) {
					previouslyConflicts = previouslyIngredient;
				}
			} else {
				if (previouslyIngredient?.labelId === newSelectedIngredient?.labelId) {
					previouslyConflicts = previouslyIngredient;
				}
			}
		}
		if (previouslyConflicts) {
			return previouslyConflicts;
		}
	};

	const checkForConflictVSUser = newSelectedIngredient => {
		const userLifeStylesAndRestrictions = [
			...patientDetail?.dieteryPreferences?.life_style_array,
			...patientDetail?.dieteryPreferences?.restriction_array,
		];
		let previouslyConflicts;
		for (let previouslyIngredient of userLifeStylesAndRestrictions) {
			if (
				(previouslyIngredient?.optionCategoriesIds || [])
					?.concat(previouslyIngredient?.optionCategoryIdsForConflicts)
					?.some(num =>
						(newSelectedIngredient?.optionCategoryIds || [])
							?.concat(newSelectedIngredient?.optionCategoryIdsForConflicts)
							?.includes(num),
					)
			) {
				previouslyConflicts = previouslyIngredient;
			}
		}
		if (previouslyConflicts) {
			return previouslyConflicts;
		}
	};
	const checkConflicts = newValue => {
		const newSelectedIngredient = newValue[newValue.length - 1];
		let previouslyConflictsIncludeLabelVSExcludeCategory;
		let previouslyRestConflicts;
		let previouslyConflictsUserVSPractitioner;
		if (
			typeIngredientSelection === 'Include' &&
			newSelectedIngredient?.type !== 'category'
		) {
			previouslyConflictsIncludeLabelVSExcludeCategory = checkForConflicts(
				practitionerExcluded,
				newSelectedIngredient,
			);
		} else if (
			typeIngredientSelection === 'Exclude' &&
			newSelectedIngredient?.type === 'category'
		) {
			previouslyConflictsIncludeLabelVSExcludeCategory = checkForConflicts(
				practitionerIncluded,
				newSelectedIngredient,
			);
		}

		if (previouslyConflictsIncludeLabelVSExcludeCategory) {
			setMessage(
				`You selected "${
					newSelectedIngredient?.foodLabel
				}", this selection conflicts with your previous selection of "${
					previouslyConflictsIncludeLabelVSExcludeCategory?.foodLabel ||
					previouslyConflictsIncludeLabelVSExcludeCategory?.optionText
				}"`,
			);
			setOpenModal(true);
			setTxtBtn('Ok');
			return;
		}
		if (typeIngredientSelection === 'Include') {
			previouslyRestConflicts = checkForConflicts(
				[
					...practitionerExcluded,
					...practitionerIngredientsWarning,
					...restrictions?.filter(d => d.select),
				],
				newSelectedIngredient,
			);
		} else if (typeIngredientSelection === 'Exclude') {
			previouslyRestConflicts = checkForConflicts(
				[
					...practitionerIncluded,
					...practitionerIngredientsWarning,
					...restrictions?.filter(d => d.select),
				],
				newSelectedIngredient,
			);
		} else if (typeIngredientSelection === 'Warning') {
			previouslyRestConflicts = checkForConflicts(
				[...practitionerIncluded, ...practitionerExcluded],
				newSelectedIngredient,
			);
		}
		if (previouslyRestConflicts) {
			setMessage(
				`You selected "${
					newSelectedIngredient?.foodLabel
				}", this selection conflicts with your previous selection of "${
					previouslyRestConflicts?.foodLabel ||
					previouslyRestConflicts?.optionText
				}". Would you like to ignore this conflict or prevent it`,
			);

			setOpenModal(true);
			setTxtBtn('Ignore');
			setValue(newSelectedIngredient);
			return;
		}
		if (typeIngredientSelection === 'Include') {
			previouslyConflictsUserVSPractitioner = checkForConflictVSUser(
				newSelectedIngredient,
			);
			if (previouslyConflictsUserVSPractitioner) {
				setMessage(
					`You selected "${newSelectedIngredient?.foodLabel}", this selection conflicts with the patient's selection of "${previouslyConflictsUserVSPractitioner?.displayName}"`,
				);
				setOpenModal(true);
				setTxtBtn('Ok');
				return;
			}
		}
		addToPractitionerIngredients(newValue);
	};

	const addToPractitionerIngredients = (newValue: any) => {
		setPractitionerIngredients(p => [...p, ...newValue]);
		setValue(null);
	};

	const deleteItem = option => {
		const _practitionerIngredients = practitionerIngredients.filter(
			item => item._id !== option._id,
		);
		setPractitionerIngredients(_practitionerIngredients);
	};

	const handleIgnore = () => {
		setOpenModal(false);
		if (txtBtn === 'Ignore') {
			addToPractitionerIngredients([value]);
		}
	};

	return (
		<>
			<Autocomplete
				multiple
				value={[]}
				onFocus={() => setTypeIngredientSelection(autocompleteType)}
				filterOptions={x => x}
				id="tags-warnings"
				key={autocompleteType}
				className={styles.container}
				inputValue={
					typeIngredientSelection === autocompleteType
						? ingredientSearchTerm
						: ''
				}
				options={!typeIngredientSelection ? [] : options}
				groupBy={option =>
					option?.type === 'foodLabel' ? FOOD_LABELS : CATEGORIES_LABEL
				}
				getOptionLabel={option => option?.foodLabel ?? ''}
				isOptionEqualToValue={(option, value) => option?._id === value?._id}
				onChange={(event, newValue, reason) => {
					if (reason !== 'removeOption') {
						checkConflicts(newValue);
					} else {
						addToPractitionerIngredients(newValue);
					}
				}}
				onClose={() => resetOptions()}
				renderTags={(tagValue, getTagProps) => <></>}
				clearIcon={null}
				renderInput={params => (
					<TextField
						{...params}
						label=""
						placeholder="+ Add"
						inputProps={{
							...params.inputProps,
							onKeyDown: e => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							},
						}}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{typeIngredientSelection === autocompleteType &&
									ingredientSearchTerm &&
									useLoadingIngredients ? (
										<CircularProgress color="primary" size={20} />
									) : null}
								</React.Fragment>
							),
						}}
						onChange={ev => {
							if (ev.target.value !== null) {
								onChangeHandle(autocompleteType, ev.target.value);
							}
						}}
					/>
				)}
			/>
			<Box>
				{practitionerIngredients?.length > 0 &&
					practitionerIngredients
						?.slice()
						?.sort((a, b) => a?.foodLabel.localeCompare(b?.foodLabel))
						?.map((option: any, index) => (
							<Box className={clsx(styles.chipBox)} key={option?.foodLabel}>
								<Box className={clsx(styles.chipBox2)}>
									<Box className={clsx(styles.foodLabelBox)}>
										{option?.foodLabel}
									</Box>
									<Box
										onClick={() => deleteItem(option)}
										className={clsx(styles.cancelOutlinedIcon)}
									>
										<CancelIcon fontSize={'small'} />
									</Box>
								</Box>
							</Box>
						))}
			</Box>
			<ConflictsModal
				message={message}
				handleIgnore={handleIgnore}
				openModal={openModal}
				setOpenModal={setOpenModal}
				txtBtn={txtBtn}
				_txtBtn={_txtBtn}
			/>
		</>
	);
};

export default AutocompleteIncludeExclude;
