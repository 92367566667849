import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	text: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}));
