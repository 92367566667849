import React from 'react';

interface BoldTextProps {
	text: string;
}

const BoldText = ({ text }: BoldTextProps) => {
	return (
		<span
			style={{
				fontWeight: 'bold',
				color: '#40018C',
			}}
		>
			{text}
		</span>
	);
};

export default BoldText;
