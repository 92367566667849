import { t } from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { request } from 'utils/request';
import { actions } from './slice';

export function* saveAsTemplate(action) {
	const options = {
		method: 'POST',
		body: JSON.stringify(action?.payload),
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/template/add-new-template`,
			options,
		);
		if (response && response.messageCode !== 'MESSAGE.ADD_TEMPLATE.SUCCESS') {
			if (!response?.message) {
				yield put(actions.setAddTemplateError(t('something went wrong')));
			} else {
				yield put(actions.setAddTemplateError(t(`${response.message}`)));
			}
		} else {
			yield put(actions.setAddTemplateError(t('')));
			yield call(action?.payload?.callback);
			yield put(actions.setAddTemplateStatus(t(`${response.message}`)));
		}
	} catch (e: any) {
		let errors = JSON.parse(e.message);
		yield put(actions.setAddTemplateError(errors.message));
	}
}

export function* updateTemplate(action) {
	const options = {
		method: 'POST',
		body: JSON.stringify(action?.payload),
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/template/update-template`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.UPDATE_TEMPLATE.SUCCESS'
		) {
			if (!response?.message) {
				yield put(actions.setUpdateTemplateError(t('something went wrong')));
			} else {
				yield put(actions.setUpdateTemplateError(t(`${response.message}`)));
			}
		} else {
			yield put(actions.setUpdateTemplateError(t('')));
			yield call(action?.payload?.callback);
		}
	} catch (e: any) {
		let errors = JSON.parse(e.message);
		yield put(actions.setUpdateTemplateError(errors.message));
	}
}

export function* addNewTemplate(action) {
	const options = {
		method: 'POST',
		body: JSON.stringify(action?.payload),
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/template/new-template-name`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.ADD_TEMPLATE_NAME.SUCCESS'
		) {
			if (!response?.message) {
				yield put(actions.setAddTemplateNameError(t('something went wrong')));
			} else {
				yield put(actions.setAddTemplateNameError(t(`${response.message}`)));
			}
		} else {
			yield call(action?.payload?.callback);
			yield put(actions.setAddTemplatePopupOpen(false));
			yield put(actions.setAddTemplateNameError(''));
		}
	} catch (e: any) {
		let errors = JSON.parse(e.message);
		yield put(actions.setAddTemplateNameError(errors.message));
	}
}

export function* editTemplateName(action) {
	const options = {
		method: 'POST',
		body: JSON.stringify(action?.payload),
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/template/edit-template-name`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.EDIT_TEMPLATE_NAME.SUCCESS'
		) {
			if (!response?.message) {
				yield put(actions.setEditTemplateNameError(t('something went wrong')));
			} else {
				yield put(actions.setEditTemplateNameError(t(`${response.message}`)));
			}
		} else {
			yield call(action?.payload?.callback);
			yield put(actions.setEditTemplatePopupOpen(false));
			yield put(actions.setEditTemplateNameError(''));
		}
	} catch (e: any) {
		let errors = JSON.parse(e.message);
		yield put(actions.setEditTemplateNameError(errors.message));
	}
}

export function* deleteTemplate(action) {
	const options = {
		method: 'DELETE',
		body: JSON.stringify({ _id: action?.payload?.templateId }),
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/template/delete-template`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.DELETE_TEMPLATE.SUCCESS'
		) {
			if (!response?.message) {
				yield put(actions.setAddTemplateNameError(t('something went wrong')));
			} else {
				yield put(actions.setAddTemplateNameError(t(`${response.message}`)));
			}
		} else {
			yield put(actions.setAddTemplateNameError(t('')));
			yield call(action?.payload?.callback);
			yield put(actions.setDeleteTemplatePopupOpen(false));
			yield put(actions.setAddTemplateNameError(''));
		}
	} catch (e: any) {
		let errors = JSON.parse(e.message);
		yield put(actions.setAddTemplateNameError(errors.message));
	}
}

export function* getTemplateById(action) {
	const options = {
		method: 'POST',
		body: JSON.stringify({ _id: action?.payload?._id }),
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/template/get-template`,
			options,
		);
		if (response && response.messageCode !== 'MESSAGE.GET_TEMPLATE.SUCCESS') {
			if (!response?.message) {
				yield put(actions.setGetTemplateError(t('something went wrong')));
			} else {
				yield put(actions.setGetTemplateError(t(`${response.message}`)));
			}
		} else {
			yield put(actions.getTemplateData(response.data));
			if (action?.payload?.toUpdateState) {
				yield call(action?.payload?.gatTemplateCallback);
			}
		}
	} catch (e: any) {
		console.error('Error in getTemplateById:', e);
		let errorMessage = t('something went wrong');
		try {
			const errors = JSON.parse(e.message);
			errorMessage = errors.message;
		} catch (parseError) {
			console.error('Error parsing JSON:', parseError);
		}
		yield put(actions.setGetTemplateError(errorMessage));
	}
}

export function* getAnydishTemplate() {
	const options = {
		method: 'POST',
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/template/get-admin-template`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.GET_ADMIN_TEMPLATE.SUCCESS'
		) {
			if (!response?.message) {
			} else {
			}
		} else {
			yield put(actions.setAnydishTemplatesData(response.data));
		}
	} catch (e: any) {
		console.error('Error in getTemplateById:', e);
		let errorMessage = t('something went wrong');
		try {
			const errors = JSON.parse(e.message);
			errorMessage = errors.message;
		} catch (parseError) {
			console.error('Error parsing JSON:', parseError);
		}
		// yield put(actions.setAddTemplateError(errorMessage));
	}
}

export function* getPrivateTemplate() {
	const options = {
		method: 'POST',
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/template/get-private-template`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.GET_PRIVATE_TEMPLATE.SUCCESS'
		) {
			if (!response?.message) {
			} else {
			}
		} else {
			yield put(actions.setPrivateTemplatesData(response.data));
		}
	} catch (e: any) {
		console.error('Error in getTemplateById:', e);
		let errorMessage = t('something went wrong');
		try {
			const errors = JSON.parse(e.message);
			errorMessage = errors.message;
		} catch (parseError) {
			console.error('Error parsing JSON:', parseError);
		}
	}
}

export function* getClinicTemplate() {
	const options = {
		method: 'POST',
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/template/get-clinic-template`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.GET_CLINIC_TEMPLATE.SUCCESS'
		) {
			if (!response?.message) {
			} else {
			}
		} else {
			yield put(actions.setClinicTemplatesData(response.data));
		}
	} catch (e: any) {
		console.error('Error in getTemplateById:', e);
		let errorMessage = t('something went wrong');
		try {
			const errors = JSON.parse(e.message);
			errorMessage = errors.message;
		} catch (parseError) {
			console.error('Error parsing JSON:', parseError);
		}
	}
}

export function* getTemplatesList(action) {
	const options = {
		method: 'POST',
		body: JSON.stringify(action?.payload),
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/template/get-templates-list`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.GET_TEMPLATES_LIST.SUCCESS'
		) {
			if (!response?.message) {
			} else {
			}
		} else {
			yield put(actions.setTemplatesList(response.data));
		}
	} catch (e: any) {
		console.error('Error in getTemplateById:', e);
		let errorMessage = t('something went wrong');
		try {
			const errors = JSON.parse(e.message);
			errorMessage = errors.message;
		} catch (parseError) {
			console.error('Error parsing JSON:', parseError);
		}
	}
}

export function* setDuplicateTemplate(action) {
	const options = {
		method: 'POST',
		body: JSON.stringify({ _id: action?.payload?.template?._id }),
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/template/duplicate-template`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.DUPLICATE_TEMPLATE.SUCCESS'
		) {
			if (!response?.message) {
				yield put(actions.setAddTemplateError(t('something went wrong')));
			} else {
				yield put(actions.setAddTemplateError(t(`${response.message}`)));
			}
		} else {
			yield call(action?.payload?.callback);
		}
	} catch (e: any) {
		console.error('Error in getTemplateById:', e);
		let errorMessage = t('something went wrong');
		try {
			const errors = JSON.parse(e.message);
			errorMessage = errors.message;
		} catch (parseError) {
			console.error('Error parsing JSON:', parseError);
		}
		yield put(actions.setAddTemplateError(errorMessage));
	}
}

export function* TemplatesRepoSaga() {
	yield takeLatest(actions.saveAsTemplate.type, saveAsTemplate);
	yield takeLatest(actions.getTemplateById.type, getTemplateById);
	yield takeLatest(actions.addNewTemplate.type, addNewTemplate);
	yield takeLatest(actions.deleteTemplate.type, deleteTemplate);
	yield takeLatest(actions.editTemplateName.type, editTemplateName);
	yield takeLatest(actions.updateTemplate.type, updateTemplate);
	yield takeLatest(actions.setDuplicateTemplate.type, setDuplicateTemplate);
	yield takeLatest(actions.getAnydishTemplate.type, getAnydishTemplate);
	yield takeLatest(actions.getClinicTemplate.type, getClinicTemplate);
	yield takeLatest(actions.getPrivateTemplate.type, getPrivateTemplate);
	yield takeLatest(actions.getTemplatesList.type, getTemplatesList);
}
