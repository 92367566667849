export default function CloseIcon() {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.25 0.25C4.87695 0.25 0.5 4.62695 0.5 10C0.5 15.373 4.87695 19.75 10.25 19.75C15.623 19.75 20 15.373 20 10C20 4.62695 15.623 0.25 10.25 0.25ZM10.25 1.75C14.8145 1.75 18.5 5.43555 18.5 10C18.5 14.5645 14.8145 18.25 10.25 18.25C5.68555 18.25 2 14.5645 2 10C2 5.43555 5.68555 1.75 10.25 1.75ZM7.25 5.93945L6.18945 7L6.72266 7.52734L9.18945 10L6.72266 12.4727L6.18945 13L7.25 14.0605L7.77734 13.5273L10.25 11.0605L12.7227 13.5273L13.25 14.0605L14.3105 13L13.7773 12.4727L11.3105 10L13.7773 7.52734L14.3105 7L13.25 5.93945L12.7227 6.47266L10.25 8.93945L7.77734 6.47266L7.25 5.93945Z"
				fill="#BEBEBE"
			/>
		</svg>
	);
}
