import React from 'react';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { useStyles } from './styles';

interface HeadingProps {
	title: string;
	subtitle?: string;
}
const MainHeading = ({ subtitle, title }: HeadingProps) => {
	const styles = useStyles();
	return (
		<Box className={clsx(styles.root)}>
			<Typography variant="subtitle2" className={clsx(styles.small)}>
				{subtitle}
			</Typography>
			<Typography variant="h1" className={clsx(styles.mainheading)}>
				{title}
			</Typography>
		</Box>
	);
};

export default MainHeading;
