import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { Box } from '@mui/material';
import { useStyles } from './styles';

type MyComponentProps = {
	children: ReactNode;
};

const Exclude = ({ children }: MyComponentProps) => {
	const styles = useStyles();

	return (
		<Box
			className={clsx(styles.root)}
			component="form"
			sx={{
				'& .MuiTextField-root': { width: '25ch' },
			}}
			noValidate
			autoComplete="off"
		>
			{children}
		</Box>
	);
};

export default Exclude;
