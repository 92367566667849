import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { DASHBOARD_TOP_BAR_HEIGHT } from 'utils/constants';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: 'auto',
		background: '#F9F9F9',
		paddingTop: DASHBOARD_TOP_BAR_HEIGHT,
		display: 'flex',
		flexDirection: 'row',
		width: '100vw',
	},
	contentRoot: {
		width: '100vw',
	},
	shrink: {
		width: '75vw',
	},
}));
