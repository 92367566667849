import { Box, Grid } from '@mui/material';
import Heading from 'app/components/Heading';
import React, { useState } from 'react';
import MoreLessButton, { NUM_OF_RENDER } from '../MoreLessButton';
import OptionMenu from '../OptionsMenu';

interface VitaminMineralsBoxProps {
	vitaminMinerals: any;
	title: any;
	nutrients: any;
	setNutrients: any;
	setVitaminMinerals: any;
	nutrientAndVitaminDetails: any;
	allVitaminMinerals: any;
	setUserVitamins: any;
	userVitamins: any;
}

const VitaminMineralsBox = ({
	title,
	nutrients,
	setNutrients,
	setVitaminMinerals,
	vitaminMinerals,
	nutrientAndVitaminDetails,
	allVitaminMinerals,
	setUserVitamins,
	userVitamins,
}: VitaminMineralsBoxProps) => {
	const [numberOfRender, setNumberOfRender] = useState(NUM_OF_RENDER);

	return (
		<Grid item xs={12} md={3} lg={3} xl={3}>
			<Box className="Nutrients">
				<Heading title={title} />
				<Grid container spacing={2}>
					{vitaminMinerals?.map(
						(element: any, index: number) =>
							index < numberOfRender && (
								<Grid
									item
									xs={12}
									xl={12}
									lg={12}
									key={`${element.optionId}${index}`}
								>
									<OptionMenu
										element={element}
										index={index}
										nutrients={nutrients}
										setNutrients={setNutrients}
										setVitaminMinerals={setVitaminMinerals}
										vitaminMinerals={vitaminMinerals}
										nutrientAndVitaminDetails={nutrientAndVitaminDetails}
										allVitaminMinerals={allVitaminMinerals}
										setUserVitamins={setUserVitamins}
										userVitamins={userVitamins}
									/>
								</Grid>
							),
					)}
					<MoreLessButton
						numberOfRender={numberOfRender}
						setNumberOfRender={setNumberOfRender}
						data={vitaminMinerals}
					/>
				</Grid>
			</Box>
		</Grid>
	);
};

export default VitaminMineralsBox;
