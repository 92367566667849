import { createSlice } from 'utils/@reduxjs/toolkit';
import { InitialTemplatesState, TemplatesTypeEnum } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

export const initialState: InitialTemplatesState = {
	addTemplateError: '',
	addTemplateNameError: '',
	getTemplateError: '',
	addTemplateStatus: '',
	getTemplateData: {
		templateData: {
			templateReferences: '',
			dieteryPreferences: {},
			practitionerPreferences: {},
		},
	},
	isConflictsTemplatesPopupOpen: false,
	patientDetails: null,
	addTemplatePopupOpen: false,
	deleteTemplatePopupOpen: false,
	editTemplatePopupOpen: false,
	selectedTemplate: null,
	editTemplateError: '',
	updateTemplateError: '',
	anydishTemplatesData: [],
	clinicTemplatesData: [],
	privateTemplatesData: [],
	templatesList: [],
};

export const useTemplatesSlice = createSlice({
	name: 'templatesState',
	initialState,
	reducers: {
		saveAsTemplate: (state, action: PayloadAction<any>) => {},
		setAddTemplateError: (state, action: PayloadAction<any>) => {
			state.addTemplateError = action.payload;
		},
		setUpdateTemplateError: (state, action: PayloadAction<any>) => {
			state.updateTemplateError = action.payload;
		},
		setAddTemplateNameError: (state, action: PayloadAction<any>) => {
			state.addTemplateNameError = action.payload;
		},
		setAddTemplateStatus: (state, action: PayloadAction<any>) => {
			state.addTemplateStatus = action.payload;
		},
		setAddTemplatePopupOpen: (state, action: PayloadAction<any>) => {
			state.addTemplatePopupOpen = action.payload;
		},
		setEditTemplatePopupOpen: (state, action: PayloadAction<any>) => {
			state.editTemplatePopupOpen = action.payload;
		},
		setEditTemplateNameError: (state, action: PayloadAction<any>) => {
			state.editTemplateError = action.payload;
		},
		setDeleteTemplatePopupOpen: (state, action: PayloadAction<any>) => {
			state.deleteTemplatePopupOpen = action.payload;
		},
		setSelectedTemplate: (state, action: PayloadAction<any>) => {
			state.selectedTemplate = action.payload;
		},
		getTemplateById: (
			state,
			action: PayloadAction<{
				_id: string;
				toUpdateState: boolean;
				gatTemplateCallback: any;
			}>,
		) => {},
		getTemplateData: (state, action: PayloadAction<any>) => {
			state.getTemplateData = action.payload;
		},
		setGetTemplateError: (state, action: PayloadAction<any>) => {
			state.getTemplateError = action.payload;
		},
		setIsConflictsTemplatesPopupOpen: (state, action: PayloadAction<any>) => {
			state.isConflictsTemplatesPopupOpen = action.payload;
		},
		putPatientDetails: (state, action: PayloadAction<any>) => {
			state.patientDetails = action.payload;
		},
		addNewTemplate: (state, action: PayloadAction<any>) => {},
		deleteTemplate: (state, action: PayloadAction<any>) => {},
		editTemplateName: (state, action: PayloadAction<any>) => {},
		updateTemplate: (state, action: PayloadAction<any>) => {},
		setDuplicateTemplate: (state, action: PayloadAction<any>) => {},
		getAnydishTemplate: state => {},
		getClinicTemplate: state => {},
		getPrivateTemplate: state => {},
		getTemplatesList: (
			state,
			action: PayloadAction<{ templateType: TemplatesTypeEnum[] }>,
		) => {},
		setTemplatesList: (state, action: PayloadAction<any>) => {
			state.templatesList = action.payload;
		},
		setAnydishTemplatesData: (state, action: PayloadAction<any>) => {
			state.anydishTemplatesData = action.payload;
		},
		setPrivateTemplatesData: (state, action: PayloadAction<any>) => {
			state.privateTemplatesData = action.payload;
		},
		setClinicTemplatesData: (state, action: PayloadAction<any>) => {
			state.clinicTemplatesData = action.payload;
		},
	},
});

export const { reducer, actions, name: sliceKey } = useTemplatesSlice;
