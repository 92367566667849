export const countries = [
	{ code: 'AF', countryCode: '+93', flag: '🇦🇫', name: 'Afghanistan' },
	{ code: 'AL', countryCode: '+355', flag: '🇦🇱', name: 'Albania' },
	{ code: 'DZ', countryCode: '+213', flag: '🇩🇿', name: 'Algeria' },
	{ code: 'AS', countryCode: '+1-684', flag: '🇦🇸', name: 'American Samoa' },
	{ code: 'AD', countryCode: '+376', flag: '🇦🇩', name: 'Andorra' },
	{ code: 'AO', countryCode: '+244', flag: '🇦🇴', name: 'Angola' },
	{ code: 'AI', countryCode: '+1-264', flag: '🇦🇮', name: 'Anguilla' },
	{ code: 'AQ', countryCode: '+672', flag: '🇦🇶', name: 'Antarctica' },
	{
		code: 'AG',
		countryCode: '+1-268',
		flag: '🇦🇬',
		name: 'Antigua and Barbuda',
	},
	{ code: 'AR', countryCode: '+54', flag: '🇦🇷', name: 'Argentina' },
	{ code: 'AM', countryCode: '+374', flag: '🇦🇲', name: 'Armenia' },
	{ code: 'AW', countryCode: '+297', flag: '🇦🇼', name: 'Aruba' },
	{ code: 'AU', countryCode: '+61', flag: '🇦🇺', name: 'Australia' },
	{ code: 'AT', countryCode: '+43', flag: '🇦🇹', name: 'Austria' },
	{ code: 'AZ', countryCode: '+994', flag: '🇦🇿', name: 'Azerbaijan' },
	{ code: 'BS', countryCode: '+1-242', flag: '🇧🇸', name: 'Bahamas' },
	{ code: 'BH', countryCode: '+973', flag: '🇧🇭', name: 'Bahrain' },
	{ code: 'BD', countryCode: '+880', flag: '🇧🇩', name: 'Bangladesh' },
	{ code: 'BB', countryCode: '+1-246', flag: '🇧🇧', name: 'Barbados' },
	{ code: 'BY', countryCode: '+375', flag: '🇧🇾', name: 'Belarus' },
	{ code: 'BE', countryCode: '+32', flag: '🇧🇪', name: 'Belgium' },
	{ code: 'BZ', countryCode: '+501', flag: '🇧🇿', name: 'Belize' },
	{ code: 'BJ', countryCode: '+229', flag: '🇧🇯', name: 'Benin' },
	{ code: 'BM', countryCode: '+1-441', flag: '🇧🇲', name: 'Bermuda' },
	{ code: 'BT', countryCode: '+975', flag: '🇧🇹', name: 'Bhutan' },
	{ code: 'BO', countryCode: '+591', flag: '🇧🇴', name: 'Bolivia' },
	{
		code: 'BA',
		countryCode: '+387',
		flag: '🇧🇦',
		name: 'Bosnia and Herzegovina',
	},
	{ code: 'BW', countryCode: '+267', flag: '🇧🇼', name: 'Botswana' },
	{ code: 'BV', countryCode: '+47', flag: '🇧🇻', name: 'Bouvet Island' },
	{ code: 'BR', countryCode: '+55', flag: '🇧🇷', name: 'Brazil' },
	{
		code: 'IO',
		countryCode: '+246',
		flag: '🇮🇴',
		name: 'British Indian Ocean Territory',
	},
	{
		code: 'VG',
		countryCode: '+1-284',
		flag: '🇻🇬',
		name: 'British Virgin Islands',
	},
	{ code: 'BN', countryCode: '+673', flag: '🇧🇳', name: 'Brunei Darussalam' },
	{ code: 'BG', countryCode: '+359', flag: '🇧🇬', name: 'Bulgaria' },
	{ code: 'BF', countryCode: '+226', flag: '🇧🇫', name: 'Burkina Faso' },
	{ code: 'BI', countryCode: '+257', flag: '🇧🇮', name: 'Burundi' },
	{ code: 'KH', countryCode: '+855', flag: '🇰🇭', name: 'Cambodia' },
	{ code: 'CM', countryCode: '+237', flag: '🇨🇲', name: 'Cameroon' },
	{ code: 'CA', countryCode: '+1', flag: '🇨🇦', name: 'Canada' },
	{ code: 'CV', countryCode: '+238', flag: '🇨🇻', name: 'Cape Verde' },
	{
		code: 'BQ',
		countryCode: '+599',
		flag: '🇧🇶',
		name: 'Caribbean Netherlands',
	},
	{ code: 'KY', countryCode: '+1-345', flag: '🇰🇾', name: 'Cayman Islands' },
	{
		code: 'CF',
		countryCode: '+236',
		flag: '🇨🇫',
		name: 'Central African Republic',
	},
	{ code: 'TD', countryCode: '+235', flag: '🇹🇩', name: 'Chad' },
	{ code: 'CL', countryCode: '+56', flag: '🇨🇱', name: 'Chile' },
	{ code: 'CN', countryCode: '+86', flag: '🇨🇳', name: 'China' },
	{ code: 'CX', countryCode: '+61', flag: '🇨🇽', name: 'Christmas Island' },
	{
		code: 'CC',
		countryCode: '+61',
		flag: '🇨🇨',
		name: 'Cocos (Keeling) Islands',
	},
	{ code: 'CO', countryCode: '+57', flag: '🇨🇴', name: 'Colombia' },
	{ code: 'KM', countryCode: '+269', flag: '🇰🇲', name: 'Comoros' },
	{ code: 'CK', countryCode: '+682', flag: '🇨🇰', name: 'Cook Islands' },
	{ code: 'CR', countryCode: '+506', flag: '🇨🇷', name: 'Costa Rica' },
	{ code: 'HR', countryCode: '+385', flag: '🇭🇷', name: 'Croatia' },
	{ code: 'CU', countryCode: '+53', flag: '🇨🇺', name: 'Cuba' },
	{ code: 'CW', countryCode: '+599', flag: '🇨🇼', name: 'Curaçao' },
	{ code: 'CY', countryCode: '+357', flag: '🇨🇾', name: 'Cyprus' },
	{ code: 'CZ', countryCode: '+420', flag: '🇨🇿', name: 'Czech Republic' },
	{
		code: 'CD',
		countryCode: '+243',
		flag: '🇨🇩',
		name: 'Democratic Republic of the Congo',
	},
	{ code: 'DK', countryCode: '+45', flag: '🇩🇰', name: 'Denmark' },
	{ code: 'DJ', countryCode: '+253', flag: '🇩🇯', name: 'Djibouti' },
	{ code: 'DM', countryCode: '+1-767', flag: '🇩🇲', name: 'Dominica' },
	{
		code: 'DO',
		countryCode: '+1-809, +1-829, +1-849',
		flag: '🇩🇴',
		name: 'Dominican Republic',
	},
	{ code: 'EC', countryCode: '+593', flag: '🇪🇨', name: 'Ecuador' },
	{ code: 'EG', countryCode: '+20', flag: '🇪🇬', name: 'Egypt' },
	{ code: 'SV', countryCode: '+503', flag: '🇸🇻', name: 'El Salvador' },
	{ code: 'GQ', countryCode: '+240', flag: '🇬🇶', name: 'Equatorial Guinea' },
	{ code: 'ER', countryCode: '+291', flag: '🇪🇷', name: 'Eritrea' },
	{ code: 'EE', countryCode: '+372', flag: '🇪🇪', name: 'Estonia' },
	{ code: 'SZ', countryCode: '+268', flag: '🇸🇿', name: 'Eswatini' },
	{ code: 'ET', countryCode: '+251', flag: '🇪🇹', name: 'Ethiopia' },
	{
		code: 'FK',
		countryCode: '+500',
		flag: '🇫🇰',
		name: 'Falkland Islands (Malvinas)',
	},
	{ code: 'FO', countryCode: '+298', flag: '🇫🇴', name: 'Faroe Islands' },
	{ code: 'FJ', countryCode: '+679', flag: '🇫🇯', name: 'Fiji' },
	{ code: 'FI', countryCode: '+358', flag: '🇫🇮', name: 'Finland' },
	{ code: 'FR', countryCode: '+33', flag: '🇫🇷', name: 'France' },
	{ code: 'GF', countryCode: '+594', flag: '🇬🇫', name: 'French Guiana' },
	{ code: 'PF', countryCode: '+689', flag: '🇵🇫', name: 'French Polynesia' },
	{
		code: 'TF',
		countryCode: '+262',
		flag: '🇹🇫',
		name: 'French Southern Territories',
	},
	{ code: 'GA', countryCode: '+241', flag: '🇬🇦', name: 'Gabon' },
	{ code: 'GM', countryCode: '+220', flag: '🇬🇲', name: 'Gambia' },
	{ code: 'GE', countryCode: '+995', flag: '🇬🇪', name: 'Georgia' },
	{ code: 'DE', countryCode: '+49', flag: '🇩🇪', name: 'Germany' },
	{ code: 'GH', countryCode: '+233', flag: '🇬🇭', name: 'Ghana' },
	{ code: 'GI', countryCode: '+350', flag: '🇬🇮', name: 'Gibraltar' },
	{ code: 'GR', countryCode: '+30', flag: '🇬🇷', name: 'Greece' },
	{ code: 'GL', countryCode: '+299', flag: '🇬🇱', name: 'Greenland' },
	{ code: 'GD', countryCode: '+1-473', flag: '🇬🇩', name: 'Grenada' },
	{ code: 'GP', countryCode: '+590', flag: '🇬🇵', name: 'Guadeloupe' },
	{ code: 'GU', countryCode: '+1-671', flag: '🇬🇺', name: 'Guam' },
	{ code: 'GT', countryCode: '+502', flag: '🇬🇹', name: 'Guatemala' },
	{ code: 'GG', countryCode: '+44-1481', flag: '🇬🇬', name: 'Guernsey' },
	{ code: 'GN', countryCode: '+224', flag: '🇬🇳', name: 'Guinea' },
	{ code: 'GW', countryCode: '+245', flag: '🇬🇼', name: 'Guinea-Bissau' },
	{ code: 'GY', countryCode: '+592', flag: '🇬🇾', name: 'Guyana' },
	{ code: 'HT', countryCode: '+509', flag: '🇭🇹', name: 'Haiti' },
	{
		code: 'HM',
		countryCode: '+672',
		flag: '🇭🇲',
		name: 'Heard Island and McDonald Islands',
	},
	{ code: 'HN', countryCode: '+504', flag: '🇭🇳', name: 'Honduras' },
	{ code: 'HU', countryCode: '+36', flag: '🇭🇺', name: 'Hungary' },
	{ code: 'IS', countryCode: '+354', flag: '🇮🇸', name: 'Iceland' },
	{ code: 'IN', countryCode: '+91', flag: '🇮🇳', name: 'India' },
	{ code: 'ID', countryCode: '+62', flag: '🇮🇩', name: 'Indonesia' },
	{ code: 'IR', countryCode: '+98', flag: '🇮🇷', name: 'Iran' },
	{ code: 'IQ', countryCode: '+964', flag: '🇮🇶', name: 'Iraq' },
	{ code: 'IE', countryCode: '+353', flag: '🇮🇪', name: 'Ireland' },
	{ code: 'IM', countryCode: '+44-1624', flag: '🇮🇲', name: 'Isle of Man' },
	{ code: 'IL', countryCode: '+972', flag: '🇮🇱', name: 'Israel' },
	{ code: 'IT', countryCode: '+39', flag: '🇮🇹', name: 'Italy' },
	{ code: 'CI', countryCode: '+225', flag: '🇨🇮', name: 'Ivory Coast' },
	{ code: 'JM', countryCode: '+1-876', flag: '🇯🇲', name: 'Jamaica' },
	{ code: 'JP', countryCode: '+81', flag: '🇯🇵', name: 'Japan' },
	{ code: 'JE', countryCode: '+44-1534', flag: '🇯🇪', name: 'Jersey' },
	{ code: 'JO', countryCode: '+962', flag: '🇯🇴', name: 'Jordan' },
	{ code: 'KZ', countryCode: '+7', flag: '🇰🇿', name: 'Kazakhstan' },
	{ code: 'KE', countryCode: '+254', flag: '🇰🇪', name: 'Kenya' },
	{ code: 'KI', countryCode: '+686', flag: '🇰🇮', name: 'Kiribati' },
	{ code: 'XK', countryCode: '+383', flag: '🇽🇰', name: 'Kosovo' },
	{ code: 'KW', countryCode: '+965', flag: '🇰🇼', name: 'Kuwait' },
	{ code: 'KG', countryCode: '+996', flag: '🇰🇬', name: 'Kyrgyzstan' },
	{ code: 'LA', countryCode: '+856', flag: '🇱🇦', name: 'Laos' },
	{ code: 'LV', countryCode: '+371', flag: '🇱🇻', name: 'Latvia' },
	{ code: 'LB', countryCode: '+961', flag: '🇱🇧', name: 'Lebanon' },
	{ code: 'LS', countryCode: '+266', flag: '🇱🇸', name: 'Lesotho' },
	{ code: 'LR', countryCode: '+231', flag: '🇱🇷', name: 'Liberia' },
	{ code: 'LY', countryCode: '+218', flag: '🇱🇾', name: 'Libya' },
	{ code: 'LI', countryCode: '+423', flag: '🇱🇮', name: 'Liechtenstein' },
	{ code: 'LT', countryCode: '+370', flag: '🇱🇹', name: 'Lithuania' },
	{ code: 'LU', countryCode: '+352', flag: '🇱🇺', name: 'Luxembourg' },
	{ code: 'MG', countryCode: '+261', flag: '🇲🇬', name: 'Madagascar' },
	{ code: 'MW', countryCode: '+265', flag: '🇲🇼', name: 'Malawi' },
	{ code: 'MY', countryCode: '+60', flag: '🇲🇾', name: 'Malaysia' },
	{ code: 'MV', countryCode: '+960', flag: '🇲🇻', name: 'Maldives' },
	{ code: 'ML', countryCode: '+223', flag: '🇲🇱', name: 'Mali' },
	{ code: 'MT', countryCode: '+356', flag: '🇲🇹', name: 'Malta' },
	{ code: 'MH', countryCode: '+692', flag: '🇲🇭', name: 'Marshall Islands' },
	{ code: 'MQ', countryCode: '+596', flag: '🇲🇶', name: 'Martinique' },
	{ code: 'MR', countryCode: '+222', flag: '🇲🇷', name: 'Mauritania' },
	{ code: 'MU', countryCode: '+230', flag: '🇲🇺', name: 'Mauritius' },
	{ code: 'YT', countryCode: '+262', flag: '🇾🇹', name: 'Mayotte' },
	{ code: 'MX', countryCode: '+52', flag: '🇲🇽', name: 'Mexico' },
	{ code: 'FM', countryCode: '+691', flag: '🇫🇲', name: 'Micronesia' },
	{ code: 'MD', countryCode: '+373', flag: '🇲🇩', name: 'Moldova' },
	{ code: 'MC', countryCode: '+377', flag: '🇲🇨', name: 'Monaco' },
	{ code: 'MN', countryCode: '+976', flag: '🇲🇳', name: 'Mongolia' },
	{ code: 'ME', countryCode: '+382', flag: '🇲🇪', name: 'Montenegro' },
	{ code: 'MS', countryCode: '+1-664', flag: '🇲🇸', name: 'Montserrat' },
	{ code: 'MA', countryCode: '+212', flag: '🇲🇦', name: 'Morocco' },
	{ code: 'MZ', countryCode: '+258', flag: '🇲🇿', name: 'Mozambique' },
	{ code: 'MM', countryCode: '+95', flag: '🇲🇲', name: 'Myanmar (Burma)' },
	{ code: 'NA', countryCode: '+264', flag: '🇳🇦', name: 'Namibia' },
	{ code: 'NR', countryCode: '+674', flag: '🇳🇷', name: 'Nauru' },
	{ code: 'NP', countryCode: '+977', flag: '🇳🇵', name: 'Nepal' },
	{ code: 'NL', countryCode: '+31', flag: '🇳🇱', name: 'Netherlands' },
	{ code: 'NC', countryCode: '+687', flag: '🇳🇨', name: 'New Caledonia' },
	{ code: 'NZ', countryCode: '+64', flag: '🇳🇿', name: 'New Zealand' },
	{ code: 'NI', countryCode: '+505', flag: '🇳🇮', name: 'Nicaragua' },
	{ code: 'NE', countryCode: '+227', flag: '🇳🇪', name: 'Niger' },
	{ code: 'NG', countryCode: '+234', flag: '🇳🇬', name: 'Nigeria' },
	{ code: 'NU', countryCode: '+683', flag: '🇳🇺', name: 'Niue' },
	{ code: 'NF', countryCode: '+672', flag: '🇳🇫', name: 'Norfolk Island' },
	{ code: 'KP', countryCode: '+850', flag: '🇰🇵', name: 'North Korea' },
	{ code: 'MK', countryCode: '+389', flag: '🇲🇰', name: 'North Macedonia' },
	{
		code: 'MP',
		countryCode: '+1-670',
		flag: '🇲🇵',
		name: 'Northern Mariana Islands',
	},
	{ code: 'NO', countryCode: '+47', flag: '🇳🇴', name: 'Norway' },
	{ code: 'OM', countryCode: '+968', flag: '🇴🇲', name: 'Oman' },
	{ code: 'PK', countryCode: '+92', flag: '🇵🇰', name: 'Pakistan' },
	{ code: 'PW', countryCode: '+680', flag: '🇵🇼', name: 'Palau' },
	{ code: 'PS', countryCode: '+970', flag: '🇵🇸', name: 'Palestine' },
	{ code: 'PA', countryCode: '+507', flag: '🇵🇦', name: 'Panama' },
	{ code: 'PG', countryCode: '+675', flag: '🇵🇬', name: 'Papua New Guinea' },
	{ code: 'PY', countryCode: '+595', flag: '🇵🇾', name: 'Paraguay' },
	{ code: 'PE', countryCode: '+51', flag: '🇵🇪', name: 'Peru' },
	{ code: 'PH', countryCode: '+63', flag: '🇵🇭', name: 'Philippines' },
	{ code: 'PL', countryCode: '+48', flag: '🇵🇱', name: 'Poland' },
	{ code: 'PT', countryCode: '+351', flag: '🇵🇹', name: 'Portugal' },
	{ code: 'PR', countryCode: '+1-787', flag: '🇵🇷', name: 'Puerto Rico' },
	{ code: 'QA', countryCode: '+974', flag: '🇶🇦', name: 'Qatar' },
	{
		code: 'CG',
		countryCode: '+242',
		flag: '🇨🇬',
		name: 'Republic of the Congo',
	},
	{ code: 'RE', countryCode: '+262', flag: '🇷🇪', name: 'Réunion' },
	{ code: 'RO', countryCode: '+40', flag: '🇷🇴', name: 'Romania' },
	{ code: 'RU', countryCode: '+7', flag: '🇷🇺', name: 'Russia' },
	{ code: 'RW', countryCode: '+250', flag: '🇷🇼', name: 'Rwanda' },
	{ code: 'BL', countryCode: '+590', flag: '🇧🇱', name: 'Saint Barthélemy' },
	{ code: 'SH', countryCode: '+290', flag: '🇸🇭', name: 'Saint Helena' },
	{
		code: 'KN',
		countryCode: '+1-869',
		flag: '🇰🇳',
		name: 'Saint Kitts and Nevis',
	},
	{ code: 'LC', countryCode: '+1-758', flag: '🇱🇨', name: 'Saint Lucia' },
	{ code: 'MF', countryCode: '+590', flag: '🇲🇫', name: 'Saint Martin' },
	{
		code: 'PM',
		countryCode: '+508',
		flag: '🇵🇲',
		name: 'Saint Pierre and Miquelon',
	},
	{
		code: 'VC',
		countryCode: '+1-784',
		flag: '🇻🇨',
		name: 'Saint Vincent and the Grenadines',
	},
	{ code: 'WS', countryCode: '+685', flag: '🇼🇸', name: 'Samoa' },
	{ code: 'SM', countryCode: '+378', flag: '🇸🇲', name: 'San Marino' },
	{
		code: 'ST',
		countryCode: '+239',
		flag: '🇸🇹',
		name: 'São Tomé and Príncipe',
	},
	{ code: 'SA', countryCode: '+966', flag: '🇸🇦', name: 'Saudi Arabia' },
	{ code: 'SN', countryCode: '+221', flag: '🇸🇳', name: 'Senegal' },
	{ code: 'RS', countryCode: '+381', flag: '🇷🇸', name: 'Serbia' },
	{ code: 'SC', countryCode: '+248', flag: '🇸🇨', name: 'Seychelles' },
	{ code: 'SL', countryCode: '+232', flag: '🇸🇱', name: 'Sierra Leone' },
	{ code: 'SG', countryCode: '+65', flag: '🇸🇬', name: 'Singapore' },
	{ code: 'SX', countryCode: '+1-721', flag: '🇸🇽', name: 'Sint Maarten' },
	{ code: 'SK', countryCode: '+421', flag: '🇸🇰', name: 'Slovakia' },
	{ code: 'SI', countryCode: '+386', flag: '🇸🇮', name: 'Slovenia' },
	{ code: 'SB', countryCode: '+677', flag: '🇸🇧', name: 'Solomon Islands' },
	{ code: 'SO', countryCode: '+252', flag: '🇸🇴', name: 'Somalia' },
	{ code: 'ZA', countryCode: '+27', flag: '🇿🇦', name: 'South Africa' },
	{ code: 'KR', countryCode: '+82', flag: '🇰🇷', name: 'South Korea' },
	{ code: 'SS', countryCode: '+211', flag: '🇸🇸', name: 'South Sudan' },
	{ code: 'ES', countryCode: '+34', flag: '🇪🇸', name: 'Spain' },
	{ code: 'LK', countryCode: '+94', flag: '🇱🇰', name: 'Sri Lanka' },
	{ code: 'SD', countryCode: '+249', flag: '🇸🇩', name: 'Sudan' },
	{ code: 'SR', countryCode: '+597', flag: '🇸🇷', name: 'Suriname' },
	{
		code: 'SJ',
		countryCode: '+47',
		flag: '🇸🇯',
		name: 'Svalbard and Jan Mayen',
	},
	{ code: 'SZ', countryCode: '+268', flag: '🇸🇿', name: 'Swaziland' },
	{ code: 'SE', countryCode: '+46', flag: '🇸🇪', name: 'Sweden' },
	{ code: 'CH', countryCode: '+41', flag: '🇨🇭', name: 'Switzerland' },
	{ code: 'SY', countryCode: '+963', flag: '🇸🇾', name: 'Syria' },
	{ code: 'TW', countryCode: '+886', flag: '🇹🇼', name: 'Taiwan' },
	{ code: 'TJ', countryCode: '+992', flag: '🇹🇯', name: 'Tajikistan' },
	{ code: 'TZ', countryCode: '+255', flag: '🇹🇿', name: 'Tanzania' },
	{ code: 'TH', countryCode: '+66', flag: '🇹🇭', name: 'Thailand' },
	{ code: 'TL', countryCode: '+670', flag: '🇹🇱', name: 'Timor-Leste' },
	{ code: 'TG', countryCode: '+228', flag: '🇹🇬', name: 'Togo' },
	{ code: 'TK', countryCode: '+690', flag: '🇹🇰', name: 'Tokelau' },
	{ code: 'TO', countryCode: '+676', flag: '🇹🇴', name: 'Tonga' },
	{
		code: 'TT',
		countryCode: '+1-868',
		flag: '🇹🇹',
		name: 'Trinidad and Tobago',
	},
	{ code: 'TN', countryCode: '+216', flag: '🇹🇳', name: 'Tunisia' },
	{ code: 'TR', countryCode: '+90', flag: '🇹🇷', name: 'Turkey' },
	{ code: 'TM', countryCode: '+993', flag: '🇹🇲', name: 'Turkmenistan' },
	{
		code: 'TC',
		countryCode: '+1-649',
		flag: '🇹🇨',
		name: 'Turks and Caicos Islands',
	},
	{ code: 'TV', countryCode: '+688', flag: '🇹🇻', name: 'Tuvalu' },
	{ code: 'UG', countryCode: '+256', flag: '🇺🇬', name: 'Uganda' },
	{ code: 'UA', countryCode: '+380', flag: '🇺🇦', name: 'Ukraine' },
	{ code: 'AE', countryCode: '+971', flag: '🇦🇪', name: 'United Arab Emirates' },
	{ code: 'GB', countryCode: '+44', flag: '🇬🇧', name: 'United Kingdom' },
	{ code: 'US', countryCode: '+1', flag: '🇺🇸', name: 'United States' },
	{ code: 'UY', countryCode: '+598', flag: '🇺🇾', name: 'Uruguay' },
	{ code: 'UZ', countryCode: '+998', flag: '🇺🇿', name: 'Uzbekistan' },
	{ code: 'VU', countryCode: '+678', flag: '🇻🇺', name: 'Vanuatu' },
	{ code: 'VA', countryCode: '+39', flag: '🇻🇦', name: 'Vatican City' },
	{ code: 'VE', countryCode: '+58', flag: '🇻🇪', name: 'Venezuela' },
	{ code: 'VN', countryCode: '+84', flag: '🇻🇳', name: 'Vietnam' },
	{ code: 'WF', countryCode: '+681', flag: '🇼🇫', name: 'Wallis and Futuna' },
	{ code: 'YE', countryCode: '+967', flag: '🇾🇪', name: 'Yemen' },
	{ code: 'ZM', countryCode: '+260', flag: '🇿🇲', name: 'Zambia' },
	{ code: 'ZW', countryCode: '+263', flag: '🇿🇼', name: 'Zimbabwe' },
	{ code: 'HK', countryCode: '+852', flag: '🇭🇰', name: 'Hong Kong' },
	{ code: 'AX', countryCode: '+358-18', flag: '🇦🇽', name: 'Åland Islands' },
];
