export enum TemplatesTypeEnum {
	ANYDISH = 'ANYDISH',
	CLINIC = 'CLINIC',
	PRIVATE = 'PRIVATE',
}
export interface TemplatesState {
	addTemplateError: string;
	addTemplateNameError: string;
	getTemplateError: string;
	addTemplateStatus: string;
	getTemplateData: any;
	isConflictsTemplatesPopupOpen: boolean;
	patientDetails: any;
	addTemplatePopupOpen: boolean;
	deleteTemplatePopupOpen: boolean;
	editTemplatePopupOpen: boolean;
	selectedTemplate: any;
	editTemplateError: string;
	updateTemplateError: string;
	anydishTemplatesData: any;
	privateTemplatesData: any;
	clinicTemplatesData: any;
	templatesList: any;
}

export type InitialTemplatesState = TemplatesState;
