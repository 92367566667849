import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ActionType, HelpState } from '../types';

export const initialState: HelpState = {
	addTopicPopupOpen: false,
	deleteTopicPopupOpen: false,
	openFaq: false,
	faqList: [],
	faqListUser: [],
	error: '',
	actionType: 'Add',
	addQuestionError: '',
	selectedQuestion: {
		topic: '',
		question: '',
		answer: '',
		order: null,
		image: null,
	},
};

export const useHelpSlice = createSlice({
	name: 'helpState',
	initialState,
	reducers: {
		setOpenFaq: (state, action: PayloadAction<any>) => {
			state.openFaq = !state.openFaq;
		},
		closeFaq: (state, action: PayloadAction<any>) => {
			state.openFaq = action.payload;
		},
		setError: (state, action: PayloadAction<any>) => {
			state.error = action.payload;
		},
		getFaqListAdmin: state => {},
		getFaqListUser: state => {},
		setFaqList: (state, action: PayloadAction<any>) => {
			state.faqList = action.payload;
		},
		setFaqListUser: (state, action: PayloadAction<any>) => {
			state.faqListUser = action.payload;
		},
		setAddTopicPopupOpen: (state, action: PayloadAction<any>) => {
			state.addTopicPopupOpen = action.payload;
		},
		setDeleteTopicPopupOpen: (state, action: PayloadAction<any>) => {
			state.deleteTopicPopupOpen = action.payload;
		},
		addNewQuestion: (state, action: PayloadAction<any>) => {},
		setAddQuestionError: (state, action: PayloadAction<any>) => {
			state.addQuestionError = action.payload;
		},
		setSelectedQuestion: (state, action: PayloadAction<any>) => {
			state.selectedQuestion = action.payload;
		},
		setSelectedQuestionImage: (state, action: PayloadAction<any>) => {
			state.selectedQuestion.image = action.payload;
		},
		setActionType: (state, action: PayloadAction<ActionType>) => {
			state.actionType = action.payload;
		},
		deleteQuestion: (state, action: PayloadAction<any>) => {},
		setOrder: (state, action: PayloadAction<any>) => {},
	},
});

export const { reducer, actions, name: sliceKey } = useHelpSlice;
