import { IconLogo } from '../../../assets/images/index';

import React from 'react';

type Props = {
	width?: number;
	height?: number;
	full?: boolean;
	className?: string;
};

export const Logo = ({ width, height, full = true, className }: Props) => {
	const logoHandProps = {
		width: width ? width : 45,
		height: height ? height : 57,
	};

	const logoWTextProps = {
		width: width ? width : 200,
		height: height ? height : 52,
	};
	// const logo = IconLogo
	let props = full ? logoWTextProps : logoHandProps;

	return (
		<>
			<img
				className={className}
				src={IconLogo}
				alt="anydish logo"
				style={{ ...props, marginLeft: '15px' }}
			/>
		</>
	);
};
