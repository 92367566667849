import React from 'react';

export default function SideIcon() {
	return (
		<div>
			<svg
				width="24"
				height="25"
				viewBox="0 0 24 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M7 3.5C5.897 3.5 5 4.397 5 5.5V21.5H9V19.5H7V5.5H17V11.0586C17.174 11.1616 17.343 11.2734 17.5 11.4004C17.935 11.0494 18.446 10.7955 19 10.6465V5.5C19 4.397 18.103 3.5 17 3.5H7ZM9 7.5V9.5H11V7.5H9ZM13 7.5V9.5H15V7.5H13ZM9 11.5V13.5H11V11.5H9ZM15 12.5C14.4696 12.5 13.9609 12.7107 13.5858 13.0858C13.2107 13.4609 13 13.9696 13 14.5C13 15.0304 13.2107 15.5391 13.5858 15.9142C13.9609 16.2893 14.4696 16.5 15 16.5C15.5304 16.5 16.0391 16.2893 16.4142 15.9142C16.7893 15.5391 17 15.0304 17 14.5C17 13.9696 16.7893 13.4609 16.4142 13.0858C16.0391 12.7107 15.5304 12.5 15 12.5ZM20 12.5C19.4696 12.5 18.9609 12.7107 18.5858 13.0858C18.2107 13.4609 18 13.9696 18 14.5C18 15.0304 18.2107 15.5391 18.5858 15.9142C18.9609 16.2893 19.4696 16.5 20 16.5C20.5304 16.5 21.0391 16.2893 21.4142 15.9142C21.7893 15.5391 22 15.0304 22 14.5C22 13.9696 21.7893 13.4609 21.4142 13.0858C21.0391 12.7107 20.5304 12.5 20 12.5ZM9 15.5V17.5H11V15.5H9ZM15 18.5C12.815 18.5 11 19.4088 11 20.7168V21.5H16H19H24V20.7168C24 19.4088 22.185 18.5 20 18.5C19.0549 18.5 18.1897 18.6777 17.5 18.9766C16.8103 18.6777 15.9451 18.5 15 18.5Z"
					fill="#938E9C"
				/>
			</svg>
		</div>
	);
}
