import { PayloadAction } from '@reduxjs/toolkit';
import { SnackbarProp } from 'app/components/Snackbar';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { InitialClinicState, Clinic, ConatctPerson } from '../types';

export const initialState: InitialClinicState = {
	clinicList: [],
	searchTerm: '',
	loading: false,
	full_name: '',
	error: '',
	selected_clinic: '',
	selected_clinic_is_virtual: 0,
	selected_clinic_name: '',
	isAddClinicPopupOpen: false,
	isAddContactFromPopupOpen: false,
	snackbar: {
		open: false,
		message: '',
		variant: 'success',
	},
	contactpersonList: [],
	contact_person_form: {
		_id: '',
		name: '',
		email: '',
		clinic_id: '',
		error: '',
	},
};

export const useClinicSlice = createSlice({
	name: 'clinicState',
	initialState,
	reducers: {
		setSearchTerm: (state, action: PayloadAction<string>) => {
			state.searchTerm = action.payload;
			if (!action.payload) {
				state.clinicList = [];
			}
		},
		setActiveClinc: (state, action: PayloadAction<string>) => {
			state.selected_clinic = action.payload;
		},
		setActiveClincName: (state, action: PayloadAction<string>) => {
			state.selected_clinic_name = action.payload;
		},
		setActiveClincNameIsVirtual: (state, action: PayloadAction<number>) => {
			state.selected_clinic_is_virtual = action.payload;
		},
		searchClinics: (state, action: PayloadAction<string>) => {
			state.searchTerm = action.payload;
		},
		searchClinicsContactPerson: (state, action: PayloadAction<string>) => {
			state.searchTerm = action.payload;
		},

		addClinic: (state, action: PayloadAction<{ callback: () => void }>) => {
			state.loading = true;
			state.error = '';
		},
		addContactPerson: (
			state,
			action: PayloadAction<{ callback: () => void }>,
		) => {
			state.loading = true;
			state.contact_person_form.error = '';
			state.error = '';
		},
		editContactPerson: (
			state,
			action: PayloadAction<{ callback: () => void }>,
		) => {
			state.loading = true;
			state.contact_person_form.error = '';
			state.error = '';
		},
		deleteContactPerson: (
			state,
			action: PayloadAction<{ id: string; callback: () => void }>,
		) => {
			state.loading = true;
		},
		updateclinicList: (state, action: PayloadAction<Clinic[]>) => {
			state.clinicList = action.payload;
		},
		updateContactPersonList: (
			state,
			action: PayloadAction<ConatctPerson[]>,
		) => {
			state.contactpersonList = action.payload;
		},
		getEditContactPerson: (
			state,
			action: PayloadAction<{ callback: () => void }>,
		) => {
			state.loading = true;
		},
		setContactfromContactPerson: (state, action: PayloadAction<any>) => {
			state.contact_person_form = action.payload;
		},
		fetchEditContactPerson: (state, action: PayloadAction<string>) => {
			state.loading = true;
		},
		addSuccess(state) {
			state.full_name = '';
			state.loading = false;
			state.error = '';
		},
		addContactPersonSuccess(state) {
			state.contact_person_form.name = '';
			state.contact_person_form.email = '';
			state.loading = false;
			state.error = '';
		},
		updateFormValue: (
			state,
			action: PayloadAction<{ key: string; value: any }>,
		) => {
			state[action.payload.key] = action.payload.value;
			state.error = '';
		},
		updateContactFormValue: (
			state,
			action: PayloadAction<{ key: string; value: any }>,
		) => {
			state.contact_person_form[action.payload.key] = action.payload.value;
			state.error = '';
		},
		setError(state, action) {
			state.error = action.payload;
			state.loading = false;
		},
		setcontactError(state, action) {
			state.contact_person_form.error = action.payload;
			state.loading = false;
		},
		openSnackbar: (state, action: PayloadAction<string>) => {
			state.snackbar.open = true;
			state.snackbar.message = action.payload;
			state.snackbar.variant = 'error';
		},
		toggleSnackbar: (state, action: PayloadAction<SnackbarProp>) => {
			state.snackbar.open = action.payload.open || false;
			state.snackbar.message = action.payload.message || '';
			state.snackbar.variant = action.payload.variant || 'error';
		},
		closeSnackbar: state => {
			state.snackbar.open = false;
			state.snackbar.message = '';
		},
		toggleAddClinicPopup: state => {
			state.loading = false;
			state.contact_person_form.error = '';
			state.error = '';
			state.isAddClinicPopupOpen = !state.isAddClinicPopupOpen;
		},
		toggleAddContactFromPopup: state => {
			state.loading = false;
			state.contact_person_form.error = '';
			state.error = '';
			state.isAddContactFromPopupOpen = !state.isAddContactFromPopupOpen;
		},
		deleteSelectedClinic: (
			state,
			action: PayloadAction<{ id: string; callback: () => void }>,
		) => {},
	},
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
