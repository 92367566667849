import { Box, Button, IconButton, InputAdornment } from '@mui/material';
import { TextField } from 'app/components/TextField';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useStyles } from './style';
import CloseIcon from 'assets/images/JsIcons/CloseIcon';
export const initValue = '';

export function References({ title, templateData, setTemplateDate }) {
	const styles = useStyles();

	const onChange = (value, index) => {
		const _textFields = templateData;
		_textFields[index] = value;
		setTemplateDate([..._textFields]);
	};

	const addTextField = () => {
		setTemplateDate(p => [...p, initValue]);
	};

	const handleClear = index => {
		const _textFields = templateData?.slice();
		_textFields.splice(index, 1);
		setTemplateDate(_textFields);
	};

	return (
		<Box className={clsx(styles.root)}>
			<Box className={clsx(styles.buttonBox)}>
				<small style={{ fontSize: 16, color: 'GrayText' }}>{title}:</small>
				<Button
					className={clsx(styles.addButton)}
					onClick={() => {
						addTextField();
					}}
				>
					+ Add
				</Button>
			</Box>
			{templateData?.map((textField, index) => (
				<TextField
					key={index}
					fullWidth
					value={textField}
					onChange={event => {
						onChange(event.target.value, index);
					}}
					style={{
						borderRadius: '10px',
						background: '#FFF',
						margin: '5px 0px',
					}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={() => {
										handleClear(index);
									}}
								>
									<CloseIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			))}
		</Box>
	);
}
