import { Theme } from '@mui/material';
import { ImageForgetPassword } from 'assets/images';

import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		background: theme.palette.primary.light,
	},
	background: {
		backgroundImage: `url(${ImageForgetPassword})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		padding: 50,
		minHeight: '100vh',
		'@media (max-width:600px)': {
			padding: 0,
			paddingTop: 50,
		},
	},
	container: {
		maxWidth: '100%',
		width: '100%',
		padding: '0',
		height: '100%',
	},
	head: {
		marginBottom: '20px',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	topTitle: {
		color: '#000000',
		fontSize: '24px',
		fontWeight: '700',
		textAlign: 'center',
		width: '100%',
		marginTop: '20px',
		marginBottom: '15px',
		'@media (max-width:600px)': {
			width: '80%',
		},
	},
	topTxt: {
		color: '#000000',
		fontSize: '14px',
		fontWeight: '400',
		textAlign: 'center',
		width: '100%',
		'@media (max-width:600px)': {
			width: '80%',
		},
	},
	messageText: {
		color: '#000000',
		fontSize: '24px',
		fontWeight: '400',
		textAlign: 'center',
		width: '50%',
		'@media (max-width:600px)': {
			width: '80%',
		},
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '60%',
		'@media (max-width:600px)': {
			width: '100%',
		},
	},
	messageBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		'& .MuiFormControl-root': {
			'& .MuiOutlinedInput-root': {
				background: theme.palette.common.white,
				borderRadius: ' 40px',

				'& .MuiOutlinedInput-input': {
					color: ' #938E9C',
					fontWeight: 400,
					paddingLeft: '25px',
				},
			},
		},
		'@media (max-width:1440px)': {
			'& .MuiTypography-h6': {
				margin: '30px 0',
			},
		},
		'@media (max-width:1200px)': {
			'& .MuiTypography-h6': {
				margin: '15px 0',
			},
		},
	},
	logo: { width: '186px' },
	loginForm: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	formGroup: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	formControl: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: '70%',
		marginBottom: 10,
		'@media (max-width:600px)': {
			width: '90%',
		},
	},
	title: {
		margin: '0',
		color: `${theme.palette.primary.main} !important`,
		fontSize: '12px',
		fontWeight: '700',
		marginBottom: '5px',
		marginLeft: '30px !important',
	},
	img: {
		width: '100%',
		height: '100%',
	},
	FormControlBtn: {
		width: '60%',
		marginTop: '20px',
		'@media (max-width:600px)': {
			width: '80%',
		},
	},
	_btnBox: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		width: '100%',
		marginTop: '20px',
		'@media (max-width:600px)': {
			width: '100%',
			flexDirection: 'column',
		},
	},
	btnBox: {
		marginTop: '20px',
		background: theme.palette.primary.main,
		padding: '7px 11px',
		borderRadius: '40px',
		width: '40%',
		height: '56px',
		'@media (max-width:600px)': {
			width: '100%',
			marginBottom: '20px',
		},
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	selectInput: {
		borderRadius: 50,
		background: '#fff',
	},
	successHeading: {
		fontSize: 16,
		fontWeight: 500,
		textAlign: 'center',
		color: '#000',
	},
	iconMail: {
		fontSize: 53,
		color: '#FF923D',
		margin: '30px 0px 30px 0px',
	},
	mailSent: {
		color: '#3C4858',
		fontSize: 14,
		fontWeight: 600,
		marginBottom: 26,
		textAlign: 'center',
		maxWidth: 210,
		lineHeight: '16px',
	},
	descText: {
		fontSize: 14,
		fontWeight: 400,
		textAlign: 'center',
		color: '#999',
		maxWidth: 289,
	},
	error: {
		width: '50%',
		textAlign: 'center',
		justifyContent: 'center',
		marginBottom: 10,
		'@media (max-width:600px)': {
			width: '80%',
		},
	},
}));
