import {
	Button,
	ButtonGroup,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Grid,
	Pagination,
	Stack,
	Typography,
	Box,
} from '@mui/material';
import React from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { IconEdit, IconArchive, IconReassign } from '../../../../assets/images';
import { selectUserType } from 'app/containers/Auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/slice';
import { Patient } from '../types';
import {
	selectPatientsList,
	selectPatientsListtotalCount,
} from '../redux/selector';
import { useStyles } from './styles';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import {
	formatDate,
	formatTime,
} from 'app/containers/Practitioner/PractitionerTable';
import { useIsPractitionerUser } from 'utils/hooks/isPractitionerUser';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common,
		color: '#645B70',
		fontWeight: '400',
		background: 'transparent',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		fontWeight: '500',
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:hover': {
		backgroundColor: '#ece6f4',
		cursor: 'pointer',
		'& .MuiTableCell-root': {
			color: theme.palette.primary.main,
		},
	},
}));

const PatientListTable = () => {
	const styles = useStyles();
	const navigate = useNavigate();
	const userType = useSelector(selectUserType);
	const dispatch = useDispatch();
	const patientsList: Patient[] = useSelector(selectPatientsList);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [patientsPerPage] = React.useState(10);
	const isPractitionerUser = useIsPractitionerUser();

	const indexOfLastPatient = currentPage * patientsPerPage;
	const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
	const currentPatients = patientsList.slice(
		indexOfFirstPatient,
		indexOfLastPatient,
	);
	const PatientsListtotal = useSelector(selectPatientsListtotalCount);

	const pageNumbers: number[] = [];
	for (
		let i: number = 1;
		i <= Math.ceil(patientsList?.length / patientsPerPage);
		i++
	) {
		pageNumbers.push(i);
	}

	const handlePaginationChange = (
		event: React.ChangeEvent<unknown>,
		value: number,
	) => {
		setCurrentPage(value);
	};

	//   ===============================handlechange=====================
	const EditHandleOpen = (name, email, id, countryCode, phoneNumber) => {
		dispatch(actions.setShowAlert(false));
		dispatch(actions.updateFormType({ type: 'Edit' }));
		dispatch(actions.toggleAddPatientPopup(true));
		dispatch(
			actions.setSelectedPatient({
				selectedId: id,
				selectedName: name,
				email: email,
				countryCode: countryCode,
				mobileNumber: phoneNumber,
			}),
		);
	};
	const ArchivePaientHandler = (id: string, name: string) => {
		try {
			dispatch(actions.toggleArchivePatient());
			dispatch(
				actions.setSelectedPatient({ selectedId: id, selectedName: name }),
			);
		} catch (e) {
			console.log(e);
		}
	};
	const ReassignPatientHandler = (
		name,
		email,
		id,
		countryCode,
		phoneNumber,
	) => {
		try {
			dispatch(actions.toggleReassignPatient());
			dispatch(
				actions.setSelectedPatient({
					selectedId: id,
					selectedName: name,
					email: email,
					countryCode: countryCode,
					mobileNumber: phoneNumber,
				}),
			);
		} catch (e) {
			console.log(e);
		}
	};
	const navigateToPatientDetails = patientId => {
		userType === '2' ||
			(userType === 'PRACTITIONER' && navigate(`/patients/${patientId}`));
	};
	return (
		<>
			<Table
				sx={{ width: 700 }}
				aria-label="simple table"
				style={{ width: '100%', overflowX: 'auto' }}
			>
				<TableHead>
					<TableRow>
						<StyledTableCell style={{ paddingLeft: '15px' }}>
							Clients ({PatientsListtotal})
						</StyledTableCell>
						<StyledTableCell
							align="center"
							className={clsx(styles.hideOnSmallScreen)}
						>
							Created At
						</StyledTableCell>
						<StyledTableCell
							align="center"
							className={clsx(styles.hideOnSmallScreen)}
						>
							Last Update
						</StyledTableCell>
						<StyledTableCell
							align="center"
							className={clsx(styles.hideOnSmallScreen)}
						>
							Email
						</StyledTableCell>
						<StyledTableCell
							align="center"
							className={clsx(styles.hideOnSmallScreen)}
						>
							Contact Number
						</StyledTableCell>
						{!isPractitionerUser && (
							<StyledTableCell
								align="center"
								className={clsx(styles.hideOnSmallScreen)}
							>
								isPhoneVerified
							</StyledTableCell>
						)}
						<StyledTableCell align="center">Actions</StyledTableCell>
					</TableRow>
				</TableHead>
				{currentPatients && currentPatients.length > 0 && (
					<TableBody>
						{currentPatients.map((patient, index) => {
							return (
								<StyledTableRow
									key={index}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<StyledTableCell
										onClick={() => navigateToPatientDetails(patient._id)}
										style={{ paddingLeft: '15px' }}
										component="th"
										scope="row"
									>
										{patient.fullName}
									</StyledTableCell>
									<StyledTableCell
										className={clsx(styles.hideOnSmallScreen)}
										align="center"
										onClick={() => navigateToPatientDetails(patient._id)}
									>
										{formatDate(patient?.createdAt)}
										{'   '}
										{!isPractitionerUser && formatTime(patient?.createdAt)}
									</StyledTableCell>
									<StyledTableCell
										className={clsx(styles.hideOnSmallScreen)}
										align="center"
										onClick={() => navigateToPatientDetails(patient._id)}
									>
										{formatDate(patient?.lastPlan)}
										{'   '}
										{!isPractitionerUser && formatTime(patient?.lastPlan)}
									</StyledTableCell>
									<StyledTableCell
										className={clsx(styles.hideOnSmallScreen)}
										align="center"
										onClick={() => navigateToPatientDetails(patient._id)}
									>
										{patient.email}
									</StyledTableCell>
									<StyledTableCell
										className={clsx(styles.hideOnSmallScreen)}
										align="center"
										onClick={() => navigateToPatientDetails(patient._id)}
									>
										{`${patient?.countryCode || ''} ${
											patient?.mobileNumber || ''
										}`}
									</StyledTableCell>
									{!isPractitionerUser && (
										<StyledTableCell
											className={clsx(styles.hideOnSmallScreen)}
											align="center"
											onClick={() => navigateToPatientDetails(patient._id)}
										>
											{patient?.isPhoneVerified?.toString()}
										</StyledTableCell>
									)}
									<StyledTableCell
										align="left"
										sx={{ width: '15%', textAlign: 'center' }}
									>
										<ButtonGroup
											className="iconBtnGroup"
											variant="text"
											aria-label="text button group"
											sx={{ justifyContent: 'flex-end' }}
										>
											<Button
												onClick={() =>
													EditHandleOpen(
														patient.fullName,
														patient.email,
														patient._id,
														patient?.countryCode,
														patient?.mobileNumber,
													)
												}
											>
												<Tooltip title="Edit" placement="top">
													<img src={IconEdit} alt="edit" />
												</Tooltip>
											</Button>
											<Button
												onClick={() =>
													ReassignPatientHandler(
														patient.fullName,
														patient.email,
														patient._id,
														patient?.countryCode,
														patient?.mobileNumber,
													)
												}
											>
												<Tooltip title="Reassign" placement="top">
													<img src={IconReassign} alt="reassign" />
												</Tooltip>
											</Button>
											<Button
												onClick={() =>
													ArchivePaientHandler(patient._id, patient.fullName)
												}
											>
												<Tooltip title="Move to archive" placement="top">
													<img src={IconArchive} alt="Move to archive" />
												</Tooltip>
											</Button>
										</ButtonGroup>
									</StyledTableCell>
								</StyledTableRow>
							);
						})}
					</TableBody>
				)}
			</Table>
			{patientsList.length === 0 && (
				<Box
					style={{
						height: '45vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<p>No records found</p>
				</Box>
			)}
		</>
	);
};

export default PatientListTable;
