import {
	Box,
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from '../style';

const EditableTable = ({ tableCells, rowsData, handleChange, handleSave }) => {
	const styles = useStyles();
	const dispatch = useDispatch();

	return (
		<>
			<Paper sx={{ padding: 2 }}>
				<Box className={clsx(styles.buttonBox)}>
					<Button
						onClick={handleSave}
						className={clsx(styles.btn)}
						variant="contained"
					>
						Save changes
					</Button>
				</Box>

				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								{tableCells?.map(tableCell => (
									<TableCell key={tableCell?.title}>
										{tableCell?.title}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rowsData?.map((row, index) => {
								const key = Object.keys(row)[0];
								const value = Object.values(row)[0];
								return (
									<TableRow key={index}>
										{console.log('row', row)}
										<TableCell>{key}</TableCell>
										<TableCell>
											<TextField
												variant="outlined"
												value={value}
												onChange={e =>
													handleChange(index, 'name', e.target.value)
												}
											/>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</>
	);
};

export default EditableTable;
