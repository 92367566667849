import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
	},
	button: {
		borderRadius: '40px',
		padding: '8px 0px',
		width: '205px',
		height: '56px',
		fontWeight: '700',
		boxShadow: 'none',
		backgroundColor: '#40018C',
		color: '#fff',
		'@media (max-width:600px)': {
			padding: '0px 8px',
			fontSize: 10,
			width: '100%',
			height: '40px',
			fontWeight: '400',
		},
	},
	saveNameMClass: {},
	label: {
		paddingLeft: '24px',
		fontSize: '12px',
		color: theme.palette.primary.main,
		fontWeight: '700',
		marginBottom: '8px',
	},
	input: {
		'& .MuiOutlinedInput-root': {
			background: theme.palette.grey[50],
			borderRadius: '40px',
			fontWeight: '500',
			paddingLeft: '20px',
		},
		'& .MuiFormHelperText-root': {
			textAlign: 'right',
			color: theme.palette.primary.main,
			fontWeight: '700',
			fontSize: '12px',
			marginTop: 7,
		},
	},
	selectInput: {
		borderRadius: 50,
		background: theme.palette.grey[50],
		paddingLeft: 20,
	},
}));
