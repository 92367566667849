import { LoginResponse } from 'types/LoginResponse';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { createAction, PayloadAction } from '@reduxjs/toolkit';

import { ContainerState, LoginErrorType, PractitionerPayment } from './types';

export const logoutSuccess = createAction('LOGOUT_SUCCESS');
// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
	username: '',
	password: '',
	loading: false,
	error: null,
	name: '',
	email: '',
	payment: {
		status: false,
		details: { number_of_paid_customers: 1 },
	},
	practitionerTemplates: [],
	clinicId: '',
	practitionerRole: '',
	setAccountGuestsError: '',
	isTermsAgreed: false,
	token: localStorage.getItem('sessionToken') || '',
	userType: localStorage.getItem('userType') || '',
	forgotPasswordForm: {
		email: '',
		error: '',
	},
	resetForm: {
		password: '',
		confirm_password: '',
		error: '',
		resetPassToken: '',
	},
	setAccountForm: {
		fullName: '',
		password: '',
		confirmPassword: '',
		promoCode: '',
		error: '',
		setAccountToken: '',
	},
	payOffer: {
		paymentMethod: '',
		promoCode: '',
		companyName: '',
		clinic: '',
		daysMonths: '',
		fee: '',
		freeTrial: false,
		numberOfDaysMonths: '',
		packageName: '',
		paymentLink: '',
		paymentType: '',
		paypalPlanId: '',
		periodPayment: '',
		pricePerPatientPerMonth: '',
		upFrontPayment: '',
		currency: '',
	},
};

const formSlice = createSlice({
	name: 'authState',
	initialState,
	extraReducers: builder => {
		builder.addCase(logoutSuccess, state => {
			state.token = '';
			state.username = '';
			state.password = '';
			state.loading = false;
			state.error = null;
			state.name = '';
			state.email = '';
			state.userType = '';
		});
	},
	reducers: {
		changeUsername(state, action: PayloadAction<string>) {
			state.username = action.payload;
			state.error = null;
		},
		changePassword(state, action: PayloadAction<string>) {
			state.password = action.payload;
			state.error = null;
		},
		updateFormValue: (
			state,
			action: PayloadAction<{ key: string; value: any }>,
		) => {
			state[action.payload.key] = action.payload.value;
			state.error = null;
		},
		logout(state) {
			state.name = '';
			state.email = '';
		},
		loadUser() {},
		login(
			state,
			action: PayloadAction<{
				navigateCallback: () => void;
				popupCallback: () => void;
			}>,
		) {
			state.loading = true;
			state.error = null;
		},
		forgotPassword(state, action: PayloadAction<{ callback: () => void }>) {
			state.loading = true;
		},
		loginSuccess(state, action: PayloadAction<LoginResponse>) {
			state.username = '';
			state.password = '';
			state.loading = false;
			state.error = null;
			state.token = action.payload.token;
			state.isTermsAgreed = action.payload.isTermsAgreed;
		},
		loginError(state, action: PayloadAction<any>) {
			state.error = action.payload;
			state.loading = false;
		},
		setError(state, action) {
			state.error = action.payload;
			state.loading = false;
		},
		setForgotError(state, action: PayloadAction<string>) {
			state.loading = false;
			state.forgotPasswordForm.error = action.payload;
		},
		setForgotEmail(state, action: PayloadAction<string>) {
			state.forgotPasswordForm.email = action.payload;
		},
		forgotPasswordSuccess(state) {
			state.loading = false;
		},
		resetError: state => {
			state.forgotPasswordForm.error = '';
		},
		resetEmail: state => {
			state.forgotPasswordForm.email = '';
		},
		updateResetForm: (
			state,
			action: PayloadAction<{ name: string; value: any }>,
		) => {
			state.resetForm[action.payload.name] = action.payload.value;
		},
		updateUserInfo: (
			state,
			action: PayloadAction<{
				name: string;
				email: any;
				payment: PractitionerPayment;
				practitionerTemplates: any;
				clinicId: any;
				practitionerRole: any;
			}>,
		) => {
			state.name = action.payload.name;
			state.email = action.payload.email;
			state.payment = action.payload.payment;
			state.practitionerTemplates = action.payload.practitionerTemplates;
			state.clinicId = action.payload.clinicId;
			state.practitionerRole = action.payload.practitionerRole;
		},
		resetPassword: (
			state,
			action: PayloadAction<{ token: any; callback: () => void }>,
		) => {
			state.loading = true;
		},
		resetPasswordSuccess: state => {
			state.loading = false;
			state.resetForm.error = '';
			state.resetForm.password = '';
			state.resetForm.confirm_password = '';
			state.resetForm.resetPassToken = '';
		},
		updateUserType: (state, action: PayloadAction<string>) => {
			state.userType = action.payload;
		},
		setAgreedTerms: (
			state,
			action: PayloadAction<{ callback: () => void }>,
		) => {},
		updateSetAccountForm: (
			state,
			action: PayloadAction<{ name: string; value: any }>,
		) => {
			state.setAccountForm[action.payload.name] = action.payload.value;
		},
		setPassword: (
			state,
			action: PayloadAction<{
				location: any;
				token: any;
				paypalCallback: () => void;
				stripeCallback: (link) => void;
				freeCallback: (link) => void;
			}>,
		) => {
			state.loading = true;
		},
		setPasswordSuccess: (state, action: PayloadAction<any>) => {
			// state.setAccountForm.password = '';
			// state.setAccountForm.confirmPassword = '';
			// state.setAccountForm.promoCode = '';
			state.payOffer.promoCode = action.payload.promo_code;
			state.payOffer.companyName = action.payload.company_name;
			state.payOffer.clinic = action.payload.clinic;
			state.payOffer.daysMonths = action.payload.days_months;
			state.payOffer.fee = action.payload.fee;
			state.payOffer.freeTrial = action.payload.free_trial;
			state.payOffer.numberOfDaysMonths = action.payload.number_of_days_months;
			state.payOffer.packageName = action.payload.package_name;
			state.payOffer.paymentLink = action.payload.payment_link;
			state.payOffer.paymentMethod = action.payload.payment_method;
			state.payOffer.paymentType = action.payload.payment_type;
			state.payOffer.paypalPlanId = action.payload.paypal_plan_id;
			state.payOffer.periodPayment = action.payload.period_payment;
			state.payOffer.currency = action.payload.currency;
			state.payOffer.pricePerPatientPerMonth =
				action.payload.price_per_patient_per_month;
			state.payOffer.upFrontPayment = action.payload.up_front_payment;
			state.setAccountForm.error = '';
		},
		setPasswordField: state => {
			state.loading = false;
		},
		onApprove: (state, action: PayloadAction<any>) => {},
		setAccount: (state, action: PayloadAction<any>) => {
			state.loading = true;
		},
		setLoading: (state, action: PayloadAction<any>) => {
			state.loading = action.payload;
		},
		updateSetAccountGuestsError: (state, action: PayloadAction<any>) => {
			state.setAccountGuestsError = action.payload;
		},
		validateReCAPTCHAToken: (state, action: PayloadAction<any>) => {},
		checkVersion: (state, action: PayloadAction<any>) => {},
	},
});

export const { actions, reducer, name: sliceKey } = formSlice;
