import React from 'react';

export default function PlusIcon() {
	return (
		<div>
			<svg
				width="32"
				height="33"
				viewBox="0 0 32 33"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect y="0.5" width="32" height="32" rx="16" fill="#F5F5F6" />
				<path
					d="M15.3333 10.5V15.8333H10V17.1667H15.3333V22.5H16.6667V17.1667H22V15.8333H16.6667V10.5H15.3333Z"
					fill="#938E9C"
					stroke="#938E9C"
					strokeLinejoin="round"
				/>
			</svg>
		</div>
	);
}
