import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import clsx from 'clsx';
import { useStyles } from '../style';
import { FaqObjUser } from '../types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import QuestionLine from './QuestionLine';

type Props = {
	data: FaqObjUser;
};

const TopicLine = ({ data }: Props) => {
	const styles = useStyles();
	const [showQuestions, setShowQuestions] = useState(false);

	const openAnswer = () => {
		setShowQuestions(p => !p);
	};

	return (
		<>
			<Button
				onClick={() => {
					openAnswer();
				}}
				className={clsx([styles.helpQuestion])}
			>
				<p className={clsx([styles.topicText])}>{data?.topic}</p>
				<KeyboardArrowDownIcon />
			</Button>
			<Box className={clsx([styles.line])} />
			{showQuestions &&
				data?.questions?.map((faq, index) => (
					<QuestionLine key={index} data={faq} />
				))}
		</>
	);
};

export default TopicLine;
