import { DateTime } from 'luxon';

export const handleChange = (event, state, stateUpdater) => {
	const { name, value } = event.target;

	if (typeof state === 'object') {
		stateUpdater({ ...state, [name]: value });
	} else {
		stateUpdater(value);
	}
};

export const emailValidator = email => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

export const getFormattedDateTime = (
	date: any,
	format: string = 'dd-MM-yyyy, hh:mm a',
) => {
	return DateTime.fromISO(date).toFormat(format);
};

export const getDefaultHeaders = () => {
	return {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
	};
};
