import { Box } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './style';
import { useSelector } from 'react-redux';
import { selectClientReportError, selectReportData } from './redux/selectors';

interface ReportTableProps {
	patientDetail: any;
}

const reportData = [
	{
		title: 'Easy Baked Halibut',
		site: 'Diet Doctor',
		date: '4.21.23',
		percentage: '93% Match',
		matchBased: 'L. Carbs, H Protein, H Calcium',
		userExcludedLabels: 'H B12, L Sodium',
	},
	{
		title: 'hard lolo',
		site: 'Diet Doctor',
		date: '4.24.23',
		percentage: '88% Match',
		matchBased: 'L. Carbs, H Protein, H Calcium',
		userExcludedLabels: 'H B12, L Sodium',
	},
];

const ReportTable = ({ patientDetail }: ReportTableProps) => {
	const styles = useStyles();
	const useReportData = useSelector(selectReportData);
	const useClientReportError = useSelector(selectClientReportError);
	const RightContainer = ({ data }: any) => {
		return (
			<Box className={clsx(styles.rightContainer)}>
				<p className={clsx(styles.title)}>{data.title}</p>
				<p className={clsx(styles.site)}>{data.site}</p>
				<p className={clsx(styles.date)}>{data.date}</p>
			</Box>
		);
	};
	const LeftContainer = ({ data }: any) => {
		return (
			<Box className={clsx(styles.leftContainer)}>
				<p className={clsx(styles.title)}>{data.matchPercentage}</p>
				<Box className={clsx(styles.matchBasedsContainer)}>
					<Box className={clsx(styles.matchBasedContainer)}>
						<p className={clsx(styles.matchBased)}>
							{data?.matchBased?.length && data?.matchBased}
							{/* {data?.matchedLabels?.length &&
								data?.matchedLabels?.map(t => t?.name)} */}
						</p>
					</Box>
					<Box className={clsx(styles.userExcludedLabelsContainer)}>
						<p className={clsx(styles.userExcludedLabels)}>
							{data.userExcludedLabels}
						</p>
					</Box>
				</Box>
			</Box>
		);
	};
	const Row = ({ data, index, array }: any) => {
		return (
			<Box
				className={clsx([styles.rowContainer])}
				sx={{
					borderBottom:
						index === array?.length - 1
							? '0px solid #E5E5E5'
							: '1px solid #E5E5E5',
					borderTop: index === 0 ? '1px solid #E5E5E5' : '0px solid #E5E5E5',
				}}
			>
				<RightContainer data={data} />
				<LeftContainer data={data} />
			</Box>
		);
	};

	return (
		<Box className={clsx(styles.rowsContainer)}>
			{reportData?.map((data, index, array) => (
				<Row key={index} data={data} index={index} array={array} />
			))}
			{/* {useClientReportError && useClientReportError} */}
		</Box>
	);
};

export default ReportTable;
