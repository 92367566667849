import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		borderRadius: '10px',
		background: '#F9F9F9',
		fontWeight: '500',
		paddingLeft: '25px',
		color: '#838383',
		fontSize: '16px',
		minWidth: '85px',
	},
	tooltipContainer: {
		'& .MuiTooltip-tooltip': {
			padding: 20,
			minWidth: 300,
			maxHeight: 163,
			background: theme.palette.primary.main,
		},
	},
	rootSelected: {
		width: '100%',
		borderRadius: '10px',
		background: '#F9F9F9',
		fontWeight: '500',
		paddingLeft: '25px',
		color: '#ED67A5',
		fontSize: '16px',
		minWidth: '85px',
	},
	circle: {
		width: '7px',
		height: '8px',
		background: '#3A1086',
		display: 'inline-block',
		borderRadius: 50,
		marginRight: '10px',
		position: 'absolute',
		top: '45%',
		zIndex: 1,
		left: '7%',
	},
	circleSelected: {
		width: '7px',
		height: '8px',
		background: '#ED67A5',
		display: 'inline-block',
		borderRadius: 50,
		marginRight: '10px',
		position: 'absolute',
		top: '45%',
		zIndex: 1,
		left: '7%',
	},
	tooltipBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	button: {
		fontWeight: 'bold',
		textAlign: 'left',
		marginLeft: '5px',
		marginTop: '5px',
		borderRadius: '10px',
		height: '100%',
		justifyContent: 'flex-start',
		color: theme.palette.grey[400],
	},
	box: {
		borderRadius: '20px',
		padding: '2.5px 20px',
		background: theme.palette.common.white,
		border: 0,
	},
	switchToFilterBox: {
		marginBottom: 10,
	},
}));
