import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		background: theme.palette.primary.light,
	},
	container: {
		maxWidth: '100%',
		width: '100%',
		padding: '0',
		height: '100%',
	},
	head: {
		marginBottom: '20px',
		textAlign: 'center',
	},
	topTxt: {
		color: '#000000 ',
		fontSize: '14px',
		fontWeight: '400',
		margin: '56px 0',
		textAlign: 'left',
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		width: '20%',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		'& .MuiFormControl-root': {
			'& .MuiOutlinedInput-root': {
				background: theme.palette.common.white,
				borderRadius: ' 40px',

				'& .MuiOutlinedInput-input': {
					color: ' #938E9C',
					fontWeight: 400,
					paddingLeft: '25px',
				},
			},
		},
		'@media (max-width:1440px)': {
			// eslint-disable-line no-useless-computed-key
			width: '36%',
			'& .MuiTypography-h6': {
				margin: '30px 0',
			},
		},
		'@media (max-width:1200px)': {
			// eslint-disable-line no-useless-computed-key
			width: '40%',
			'& .MuiTypography-h6': {
				margin: '15px 0',
			},
		},
		'@media (max-width:1024px)': {
			// eslint-disable-line no-useless-computed-key
			width: '45%',
		},
		'@media (max-width:680px)': {
			// eslint-disable-line no-useless-computed-key
			width: '90%',
		},
	},
	logo: { width: '186px' },
	loginForm: {
		width: '100%',
	},
	formGroup: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	title: {
		margin: '0',
		color: `${theme.palette.primary.main} !important`,
		fontSize: '12px',
		fontWeight: '700',
		marginBottom: '5px',
		marginLeft: '30px !important',
	},
	img: {
		width: '100%',
		height: '100vh',
	},
	btnBox: {
		marginTop: '20px',
		'& .MuiButton-root': {
			background: theme.palette.primary.main,
			padding: '7px 11px',
			borderRadius: ' 40px',
			width: '100%',
			height: '56px',
		},
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	successHeading: {
		fontSize: 16,
		fontWeight: 500,
		textAlign: 'center',
		color: '#000',
	},
	iconMail: {
		fontSize: 53,
		color: '#FF923D',
		margin: '30px 0px 30px 0px',
	},
	mailSent: {
		color: '#3C4858',
		fontSize: 14,
		fontWeight: 600,
		marginBottom: 26,
		textAlign: 'center',
		maxWidth: 210,
		lineHeight: '16px',
	},
	descText: {
		fontSize: 14,
		fontWeight: 400,
		textAlign: 'center',
		color: '#999',
		maxWidth: 289,
	},
	error: {
		width: '100%',
		textAlign: 'center',
		justifyContent: 'center',
		marginBottom: 10,
	},
}));
