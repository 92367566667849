/* --- STATE --- */

export interface PractitionerPayment {
	status: any;
	details: { number_of_paid_customers: number };
	paymentMethod?: 'Stripe' | 'Paypal';
}
export interface AuthState {
	username: string;
	password: string;
	loading: boolean;
	error?: LoginErrorType | null;
	token: string;
	userType: string;
	name: string;
	email: string;
	payment: PractitionerPayment;
	practitionerTemplates: any;
	clinicId: any;
	practitionerRole: any;
	setAccountGuestsError: string;
	isTermsAgreed: boolean;
	forgotPasswordForm: {
		email: string;
		error: string;
	};
	resetForm: {
		password: string;
		confirm_password: string;
		error: string;
		resetPassToken: string;
	};
	setAccountForm: {
		fullName: string;
		password: string;
		confirmPassword: string;
		promoCode: string;
		error: string;
		setAccountToken: string;
	};
	payOffer: {
		paymentMethod: string;
		promoCode: string;
		companyName: string;
		clinic: string;
		daysMonths: string;
		fee: string;
		freeTrial: boolean;
		numberOfDaysMonths: any;
		packageName: string;
		paymentLink: string;
		paymentType: string;
		paypalPlanId?: string;
		periodPayment: string;
		pricePerPatientPerMonth: string;
		upFrontPayment: string;
		currency: string;
	};
}

export enum LoginErrorType {
	USERNAME_EMPTY = 10,
	PASSWORD_EMPTY = 20,
	USER_NOT_FOUND = 404,
	SUCCESS = 200,
	UNAUTHORIZED = 400,
	ERROR = 500,
	NO_PERMISSIONS = 401,
}

/*
  If you want to use 'ContainerState' keyword everywhere in your feature folder,
  instead of the 'HomePageState' keyword.
*/
export type ContainerState = AuthState;
