import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { DASHBOARD_TOP_BAR_HEIGHT } from 'utils/constants';

export const useStyles = makeStyles((theme: Theme) => ({
	main: {
		// position: 'fixed',
		// marginTop: DASHBOARD_TOP_BAR_HEIGHT,
		background: '#F9F9F9',
	},
	modal: {
		'& .MuiBackdrop-root': {
			background: 'rgb(29 1 73 / 60%)',
		},
		overflowY: 'scroll',
	},
	_fieldsContainer: {
		display: 'flex',
		flexDirection: 'row',
		height: '60vh',
	},
	fieldsContainer: {
		width: '80%',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
	},
	fieldsBox: {},
	selectInput: {
		borderRadius: 50,
		background: theme.palette.grey[50],
	},
	fbtn: {
		borderRadius: '40px',
		padding: '5px 24px',
		fontWeight: '700',
		color: theme.palette.primary.main,
	},
	fbtnWhite: {
		color: theme.palette.common.white,
	},
	btn: {
		background: theme.palette.primary.main,
		borderRadius: '40px',
		color: theme.palette.common.white,
		padding: ' 8px 0px',
		width: '220px',
		height: '50px',
		marginBottom: '24px',
		fontWeight: '700',
	},
	searchBox: {
		margin: '20px 0',
	},
	customCheckbox: {
		padding: 0,
		marginLeft: '24px',
		width: 18,
		height: 18,
	},
	popup: {
		background: theme.palette.common.white,
		borderRadius: '32px',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80vw',
		padding: '44px 24px 24px',
	},
	popupIcon: {
		position: 'absolute',
		top: ' 0',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '70px',
		height: '70px',
		borderRadius: '40px',
		background: theme.palette.common.white,
		'&:hover': {
			background: theme.palette.common.white,
		},
	},
	title: {
		color: theme.palette.secondary.dark,
		fontWeight: '700',
		marginBottom: '10px',
		fontSize: 24,
	},
	label: {
		paddingLeft: '24px',
		fontSize: '12px',
		color: theme.palette.primary.main,
		fontWeight: '700',
		marginBottom: '5px',
	},
	checkboxContainer: {},
	checkboxBox: {
		paddingTop: '8px',
		display: 'flex',
		flexDirection: 'row',
	},
	checkboxLabel: {
		fontWeight: '700',
		fontSize: '12px',
		color: theme.palette.primary.main,
		marginLeft: 10,
	},
	fieldBox: {
		maxWidth: '30%',
		marginRight: 5,
	},
	input: {
		'& .MuiOutlinedInput-root': {
			background: theme.palette.grey[50],
			borderRadius: '40px',
			fontWeight: '500',
		},
		'& .MuiFormHelperText-root': {
			textAlign: 'right',
			color: theme.palette.primary.main,
			fontWeight: '700',
			fontSize: '12px',
			marginTop: 7,
		},
		'& .MuiOutlinedInput-input': {
			paddingLeft: '20px',
		},
	},

	// Left Side Styles
	wrapper: { paddingTop: 50 },
	buttonProgress: {
		color: theme.palette.common.white,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	head: {
		width: '100%',
		'& .MuiBox-root': {
			marginBottom: '0',
		},
		'& .MuiButton-root': {
			marginBottom: '0',
		},
	},
	rightside: {
		height: '100%',
	},
	search_input: {
		borderRadius: '40px',
		width: '70%',
		background: theme.palette.common.white,
		paddingLeft: '15px',
	},
	TrashMClass: {
		'& .MuiTypography-h6': {
			color: '#DD2A19',
		},
		'& .MuiTypography-body1': {
			color: '#66130B',
		},
		'& .MuiInputLabel-root': {
			color: '#938E9C',
		},

		'& .MuiOutlinedInput-root': {
			background: 'transparent',
			border: '1px solid #F1F0F2',
		},

		'& .MuiButton-containedPrimary': {
			background: '#DD2A19 !important',
		},
		'& .MuiButton-textPrimary': {
			color: '#66130B !important',
		},
	},
	ReassignMClass: {
		'& .MuiOutlinedInput-root': {
			border: '1px solid #F1F0F2',
			background: 'transparent',
		},
		'& .MuiTypography-h5': {
			color: theme.palette.secondary.dark,
			fontWeight: '700',
			marginBottom: '10px',
			fontSize: '16px',
			paddingLeft: '24px',
		},
	},
	formGroup: {
		margin: '20px 0',
	},
	searchInput: {
		borderRadius: '40px',
		width: '100%',
		background: '#F1F0F2',
		paddingLeft: '15px',
	},
	patient: {},
	archive: {
		'& .MuiTypography-h5': {
			paddingLeft: 0,
		},
	},
}));
