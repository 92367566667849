import React, { useState, useEffect } from 'react';
import {
	Box,
	Button,
	Container,
	Grid,
	IconButton,
	Typography,
	Tabs,
	Tab,
	Stack,
	Pagination,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../components/Sidebar';
import Header from '../../components/Header';
import PractitionerTable from './PractitionerTable/index';
import { useStyles } from './styles';
import UserRightBar from 'app/components/UserRightBar';
import { TableSearch } from 'app/components/TableSearch';
import { IconBag, IconSearch, IconLine } from '../../../assets/images';
import Content from '../../components/content';
import ArchiveList from './ArchiveTable/index';
import { useInjectReducer } from 'redux-injectors';
import { useInjectSaga } from 'utils/redux-injectors';
import { PractitionerRepoSaga } from './redux/saga';
import { ClinicRepoSaga } from '../Clinics/redux/saga';
import {
	selectAddPractitionerOpen,
	selectPractitionersListtotal,
	selectPractitionersListtotalCount,
	selectSearchTerm,
	selectArchivedPractitionersListtotalCount,
	selectArchivedSearchTerm,
} from './redux/selector';
import {
	selectActiveClinicIsVirtual,
	selectActiveClinicName,
} from '../Clinics/redux/selector';
import { reducer, sliceKey, actions } from './redux/slice';
import {
	reducer as clinicsReducer,
	sliceKey as clinicsSliceKey,
} from '../Clinics/redux/slice';
import { selectClinicId } from '../Auth/selectors';
import { useIsPractitionerUser } from 'utils/hooks/isPractitionerUser';

export const pageSize = 10;
export const calculateTotalPages = (totalItems, itemsPerPage) => {
	if (totalItems < itemsPerPage) {
		return 1;
	}
	return Math.ceil(totalItems / itemsPerPage);
};
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const PractitionerPage = () => {
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: PractitionerRepoSaga });
	useInjectReducer({ key: clinicsSliceKey, reducer: clinicsReducer });
	useInjectSaga({ key: clinicsSliceKey, saga: ClinicRepoSaga });
	const styles = useStyles();
	const dispatch = useDispatch();
	const selectArchivedSearchTermData = useSelector(selectArchivedSearchTerm);
	const selectSearchTermData = useSelector(selectSearchTerm);
	const selectedClinicName = useSelector(selectActiveClinicName);
	const selectedClinicIsVirtual = useSelector(selectActiveClinicIsVirtual);
	const useClinicId = useSelector(selectClinicId);
	const [value, setValue] = useState(0);
	const [patientPopup, setPatientPopup] = useState(false);
	const isPractitionerUser = useIsPractitionerUser();

	const PatienthandleOpen = () => {
		dispatch(actions.toggleAddPractitioner());
		dispatch(actions.resetIsEditAction(false));
	};
	useEffect(() => {
		if (useClinicId) {
			dispatch(actions.getClinic({ clinicId: useClinicId }));
		}
	}, [useClinicId]);
	const PractitionersListtotal = useSelector(selectPractitionersListtotalCount);
	const ArchivedPractitionersListtotal = useSelector(
		selectArchivedPractitionersListtotalCount,
	);

	const PractitionersListpage = useSelector(selectPractitionersListtotal);
	const handleChange = (event, newValue) => {
		dispatch(actions.updatePageNo(1));
		setValue(newValue);
		handleSerach(1, newValue);
	};
	const handleSerach = (page = 1, newValue?) => {
		const cond = newValue === 0 || newValue === 1 ? newValue : value;
		if (cond == 0) {
			dispatch(
				actions.searchPractitioners({
					searchTerm: selectSearchTermData,
					page: page,
				}),
			);
		} else {
			dispatch(
				actions.searchPractitionersArchived({
					searchTerm: selectArchivedSearchTermData,
					page: page,
				}),
			);
		}
	};
	const onChangePagination = (e, page) => {
		handleSerach(page);
	};

	return (
		<Box
			display="flex"
			alignItems={'center'}
			style={{ backgroundColor: '#E5E5E5' }}
		>
			<Header />
			<Content>
				<Box display="flex" className={clsx(styles.main)}>
					<Grid container>
						<Grid item lg={12} xs={12}>
							<Box display="flex" style={{ minHeight: '100%' }}>
								{!isPractitionerUser && <SideBar />}
								<Container
									maxWidth={'xl'}
									className={clsx(styles.rightside)}
									style={{ position: 'relative' }}
									sx={{ height: 'calc( 100vh - 80px )', overflowY: 'scroll' }}
								>
									<Box
										className={clsx(styles.wrapper)}
										style={{
											position: 'fixed',
											width: '70%',
											backgroundColor: '#E5E5E5',
											zIndex: 5,
										}}
									>
										<Box
											className={clsx(styles.head)}
											display="flex"
											alignItems={'center'}
											justifyContent="space-between"
										>
											<Box component="div" display="flex" alignItems="center">
												<Typography
													className={clsx([styles.heading])}
													variant="h1"
												>
													{selectedClinicName}{' '}
													<span style={{ fontWeight: 300 }}>
														(
														{value == 0
															? PractitionersListtotal
															: ArchivedPractitionersListtotal}
														){' '}
													</span>
												</Typography>
											</Box>
											{selectedClinicIsVirtual == 0 && (
												<Button
													onClick={PatienthandleOpen}
													disabled={value !== 0}
													className={clsx(
														value == 0 ? styles.btn : styles.btnDeactive,
													)}
													variant="contained"
												>
													Add a new practitioner
												</Button>
											)}
										</Box>
										<Box component="div" className={clsx(styles.searchBox)}>
											<Container maxWidth={'xl'}>
												<Grid container>
													<Grid item lg={6} xs={12}>
														<Box
															component="div"
															className={clsx(styles.tabBox)}
															display="flex"
															alignItems="center"
														>
															<Tabs
																value={value}
																onChange={handleChange}
																aria-label="basic tabs example"
															>
																<Tab
																	label={<img src={IconLine} alt="IconLine" />}
																	{...a11yProps(0)}
																	className={clsx(styles.BtnCircle)}
																/>
																<Tab
																	label={<img src={IconBag} alt="IconBag" />}
																	{...a11yProps(1)}
																	className={clsx(styles.BtnCircle)}
																/>
															</Tabs>
															<TableSearch
																placeholder={
																	value == 0
																		? 'Search practitioner...'
																		: 'Search archived practitioner...'
																}
																type="name"
																endIcon={
																	<IconButton
																		type="button"
																		onClick={() => {
																			handleSerach();
																		}}
																		sx={{ p: '10px' }}
																		aria-label="search"
																	>
																		<img src={IconSearch} alt={'IconSearch'} />
																	</IconButton>
																}
																className={clsx(styles.SearchInput)}
																value={
																	value == 0
																		? selectSearchTermData
																		: selectArchivedSearchTermData
																}
																onKeyUp={e => {
																	if (e.key == 'Enter') {
																		handleSerach();
																	}
																}}
																onChanges={e => {
																	if (value == 0) {
																		dispatch(
																			actions.changesearchPractitioners(
																				e.target.value,
																			),
																		);
																	} else {
																		dispatch(
																			actions.changesearchArchivedPractitioners(
																				e.target.value,
																			),
																		);
																	}
																}}
															></TableSearch>
														</Box>
													</Grid>
													<Grid item lg={6} xs={12}></Grid>
												</Grid>
											</Container>
										</Box>
									</Box>
									<Box
										className={clsx(styles.wrapper)}
										style={{ marginTop: 150 }}
									>
										<Box component="div" style={{ overflowY: 'auto' }}>
											<TabPanel value={value} index={0}>
												<PractitionerTable
													patientPopup={patientPopup}
													setPatientPopup={setPatientPopup}
													selectedClinicIsVirtual={selectedClinicIsVirtual}
												/>
											</TabPanel>
											<TabPanel value={value} index={1}>
												<ArchiveList />
											</TabPanel>
										</Box>
										<Grid container style={{ padding: 50 }}>
											<Grid item xs={12} md={12}>
												<Box
													component={`div`}
													className={clsx(styles.pagination)}
												>
													<Stack mr={7}>
														<Pagination
															count={calculateTotalPages(
																PractitionersListtotal,
																pageSize,
															)}
															showFirstButton
															showLastButton
															onChange={(e, page) =>
																onChangePagination(e, page)
															}
															page={PractitionersListpage}
														/>
													</Stack>
													{/* <Typography color="inherit">
														Go to page{' '}
														<Button className={clsx(styles.pageBtn)}>1</Button>
													</Typography> */}
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Container>
								<UserRightBar />
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Content>
		</Box>
	);
};

export default PractitionerPage;
