import { PayloadAction } from '@reduxjs/toolkit';
import { SnackbarProp } from 'app/components/Snackbar';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
	InitialPractitionerState,
	Practitioner,
	PractitionerWithClinic,
	SearchPractitioner,
} from '../types';

export const initialState: InitialPractitionerState = {
	practitionersList: [],
	practitionersListArchived: [],
	sidemenuPractitionersList: [],
	pageCount: 0,
	totalCount: 0,
	pageCountArchived: 0,
	totalCountArchived: 0,
	selected_practitioner: '',
	selected_practitioner_name: '',
	searchTerm: '',
	searchTermArchieved: '',
	isAddPractitionerOpen: false,
	isArchivePractitionerOpen: false,
	isUnArchivePractitionerOpen: false,
	isSidebarOpen: false,
	isAddClinicPopupOpen: false,
	isDeletePractitionerOpen: false,
	selectedId: '',
	selectedName: '',
	selectedRole: '',
	selectedPaymentStatus: '',
	selectedNumOfClients: 1,
	is_archive: 0,
	_id: '',
	fullName: '',
	promoCode: '',
	email: '',
	error: '',
	clinic_id: '',
	loading: false,
	snackbar: {
		open: false,
		message: '',
		variant: 'success',
	},
	reAssignedPractitionerId: '',
	isEditAction: false,
};

export const usePatientSlice = createSlice({
	name: 'practitionerState',
	initialState,
	reducers: {
		setSearchTerm: (state, action: PayloadAction<string>) => {
			state.searchTerm = action.payload;
			if (!action.payload) {
				state.practitionersList = [];
			}
		},
		searchPractitionersClinic: (state, action: PayloadAction<string>) => {},
		getClinic: (state, action: PayloadAction<any>) => {},
		searchPractitioners: (state, action: PayloadAction<SearchPractitioner>) => {
			state.searchTerm = action.payload.searchTerm;
			state.pageCount = action.payload.page || 1;
		},
		searchPractitionersArchived: (
			state,
			action: PayloadAction<SearchPractitioner>,
		) => {
			state.searchTerm = action.payload.searchTerm;
			state.pageCount = action.payload.page || 1;
		},
		setActivePractitioner: (state, action: PayloadAction<string>) => {
			state.selected_practitioner = action.payload;
		},
		setActivePractitionerName: (state, action: PayloadAction<string>) => {
			state.selected_practitioner_name = action.payload;
		},
		changesearchPractitioners: (state, action: PayloadAction<string>) => {
			state.searchTerm = action.payload;
		},
		changesearchArchivedPractitioners: (
			state,
			action: PayloadAction<string>,
		) => {
			state.searchTermArchieved = action.payload;
		},
		addPractitioner: (
			state,
			action: PayloadAction<{ callback: () => void }>,
		) => {
			state.loading = true;
			state.error = '';
		},
		updatepractitionersList: (state, action: PayloadAction<Practitioner[]>) => {
			state.practitionersList = action.payload;
		},
		updatepractitionersclinicList: (
			state,
			action: PayloadAction<PractitionerWithClinic[]>,
		) => {
			state.sidemenuPractitionersList = action.payload;
		},
		updateArchivedpractitionersList: (
			state,
			action: PayloadAction<Practitioner[]>,
		) => {
			state.practitionersListArchived = action.payload;
		},
		toggleMenu: state => {
			state.isSidebarOpen = !state.isSidebarOpen;
		},
		toggleAddPractitioner: state => {
			state.error = '';
			state.isAddPractitionerOpen = !state.isAddPractitionerOpen;
		},
		toggleArchivePractitioner: state => {
			state.error = '';
			state.isArchivePractitionerOpen = !state.isArchivePractitionerOpen;
		},
		toggleUnArchivePractitioner: state => {
			state.error = '';
			state.isUnArchivePractitionerOpen = !state.isUnArchivePractitionerOpen;
		},
		setSelectedPractitioner: (state, action: PayloadAction<any>) => {
			state.error = '';
			state.selectedId = action.payload.selectedId;
			state.selectedName = action.payload.selectedName;
			state.selectedPaymentStatus = action.payload?.paymentStatus
				? action.payload?.paymentStatus
				: '';
			state.selectedNumOfClients = action.payload?.numOfClients;
		},
		setSelectedPractitionerRole: (state, action: PayloadAction<any>) => {
			state.error = '';
			state.selectedId = action.payload.selectedId;
			state.selectedName = action.payload.selectedName;
			state.selectedRole = action.payload?.selectedRole
				? action.payload?.selectedRole
				: '';
		},
		setSelectedStatus: (state, action: PayloadAction<any>) => {
			state.selectedPaymentStatus = action.payload;
		},
		setSelectedRole: (state, action: PayloadAction<any>) => {
			state.selectedRole = action.payload;
		},
		setSelectedNumOfClients: (state, action: PayloadAction<any>) => {
			state.selectedNumOfClients = action.payload;
		},
		updatePractitionerStatus: (
			state,
			action: PayloadAction<{ status: number; callback: () => void }>,
		) => {
			state.loading = true;
		},
		resetIsEditAction: (state, action) => {
			state.isEditAction = action.payload;
		},
		addSuccess(state) {
			state._id = '';
			state.fullName = '';
			state.promoCode = '';
			state.email = '';
			state.loading = false;
			state.isEditAction = false;
			state.error = '';
		},
		updateFormValue: (
			state,
			action: PayloadAction<{ key: string; value: any }>,
		) => {
			state[action.payload.key] = action.payload.value;
			state.error = '';
		},
		setError(state, action) {
			state.error = action.payload;
			state.loading = false;
		},
		toggleAddClinicPopup: state => {
			state.isAddClinicPopupOpen = !state.isAddClinicPopupOpen;
		},
		toggleDeletePractitioner: state => {
			state.isDeletePractitionerOpen = !state.isDeletePractitionerOpen;
		},

		openSnackbar: (state, action: PayloadAction<string>) => {
			state.snackbar.open = true;
			state.snackbar.message = action.payload;
			state.snackbar.variant = 'error';
		},
		toggleSnackbar: (state, action: PayloadAction<SnackbarProp>) => {
			state.snackbar.open = action.payload.open || false;
			state.snackbar.message = action.payload.message || '';
			state.snackbar.variant = action.payload.variant || 'error';
		},
		closeSnackbar: state => {
			state.snackbar.open = false;
			state.snackbar.message = '';
		},
		updatePageNo: (state, action: PayloadAction<number>) => {
			state.pageCount = action.payload;
		},
		updatePageNoArchived: (state, action: PayloadAction<number>) => {
			state.pageCountArchived = action.payload;
		},
		updateTotalCount: (state, action: PayloadAction<number>) => {
			state.totalCount = action.payload;
		},
		updateTotalCountArchived: (state, action: PayloadAction<number>) => {
			state.totalCountArchived = action.payload;
		},
		editPractitioner: (
			state,
			action: PayloadAction<{ callback: () => void }>,
		) => {
			//state.loading = true;
			state.error = '';
		},
		fetchEditPractitioner: (state, action: PayloadAction<string>) => {
			state.isAddPractitionerOpen = true;
			//state.loading = true;
		},
		deletePractitioner: (
			state,
			action: PayloadAction<{ callback: () => void }>,
		) => {
			//state.loading = true;
		},
		deleteSelectedPractitioner: (
			state,
			action: PayloadAction<{ id: string; callback: () => void }>,
		) => {},
		setApprovedPractitioner: (
			state,
			action: PayloadAction<{
				id: string;
				paymentStatus: string;
				numOfClients?: number;
				callback: () => void;
			}>,
		) => {},
		setPractitionerRole: (
			state,
			action: PayloadAction<{
				id: string;
				role: string;
				callback: any;
			}>,
		) => {},
		setConsumerStatus: (
			state,
			action: PayloadAction<{
				id: string;
				paymentStatus: string;
				callback: () => void;
			}>,
		) => {},
		setReAssignPractitionerId: (
			state,
			action: PayloadAction<{ id: string }>,
		) => {
			state.reAssignedPractitionerId = action.payload.id;
		},
	},
});

export const { reducer, actions, name: sliceKey } = usePatientSlice;
