export const SAVED = 'Saved';
export const COOKED = 'Cooked';

export interface ClientReportsState {
	reportData: any;
	isLoading: boolean;
	error: string;
}

export type InitialTemplatesState = ClientReportsState;
