import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
const SliderShadow =
	'0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';
export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		borderRadius: '25px',
		background: theme.palette.common.white,
		flexDirection: 'column',
		'& .MuiPaper-root': {
			position: 'relative',
		},
		width: 380,
		padding: '18px 29px 20px 24px',
		'@media (max-width:600px)': {
			width: '100%',
		},
	},
	tooltipContainer: {
		'& .MuiTooltip-tooltip': {
			padding: 20,
			minWidth: 300,
			maxHeight: 163,
			background: theme.palette.primary.main,
		},
	},
	toolTipRoot: {
		padding: '10px',
		borderRadius: '25px',
	},
	titleBox: {
		marginBottom: 5,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	line: {
		background: theme.palette.common.white,
		width: '100%',
		height: 2,
	},
	editView: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		marginBottom: 0,
		height: 40,
	},
	editInnerView: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '75%',
	},

	acc: {
		boxShadow: 'none',
		width: '100%',
	},
	head: {
		display: 'flex',
	},
	headTxt: {
		display: 'flex',
		flex: '1',
	},
	AccordionDetails: {
		borderTop: `1px solid ${theme.palette.primary[100]}`,
	},
	tooltipBox: {
		marginBottom: 30,
		marginRight: -10,
	},
	tooltipInnerBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
	},
	tooltipInnerBoxFlex: {
		display: 'flex',
	},
	button: {
		fontWeight: 'bold',
		textAlign: 'left',
		margin: '0px',
		justifyContent: 'flex-start',
		color: theme.palette.grey[400],
	},
	titleButton: {
		justifyContent: 'flex-start',
		fontSize: '14px',
		fontWeight: '600',
	},
	box: {
		flexDirection: 'column',
		alignItems: 'flex-start',
		width: '100%',
	},
	sliderBox: {
		padding: '0 20px',
	},

	switchBox: {
		marginTop: 20,
		marginBottom: 10,
	},
	itemRoot: {
		backgroundColor: theme.palette.primary.dark,
	},
	title: {
		fontSize: '16px',
		fontWeight: '700',
		color: theme.palette.grey[400],
		flex: '0 0 30%',
	},
	BoxTitle: { color: theme.palette.common.white },

	desc: {
		fontSize: '15px',
		fontWeight: '700',
	},
	btn: {
		borderRadius: '30px',
		backgroundColor: '#F9F9F9',
		padding: '5px 4px 6px 12px',
		border: 'none',
		width: 80,
		height: '100%',
		fontSize: '15px',
		color: '#4D4D4D',
	},
	inputBtn: {
		backgroundColor: theme.palette.primary[100],
		padding: '5px 0px 5px 12px',
		borderRadius: '12px',
		fontSize: '15px',
		width: 97,
		color: '#4D4D4D',
		'& .MuiOutlinedInput-input': {
			padding: '0',
		},
	},
	innerSlider: {
		display: 'flex',
		flexDirection: 'column',
		background: 'transparent',
		justifyContent: 'space-between',
		width: '100%',
	},
	innerBox: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'space-between',
	},
	innerSliderBox: {
		width: '100%',
		paddingLeft: '20px',
	},
	innerSliderClass: {
		width: '100%',
	},
	innerTitle: {
		fontSize: 13,
		width: 150,
		fontWeight: 400,
		color: theme.palette.grey[400],
	},
	sliderMark: {
		'& .MuiSlider-thumb': {
			width: 'max-content',
			padding: '0px 8px',
			height: 24,
			borderRadius: 10,
			color: theme.palette.common.white,
			fontSize: 12,
			fontWeight: 600,
			margin: '0 auto',
			'&:focus, &:hover, &.Mui-active': {
				boxShadow:
					'0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
				// Reset on touch devices, it doesn't add specificity
				'@media (hover: none)': {
					boxShadow: SliderShadow,
				},
			},
		},

		'& .MuiSlider-rail': {
			backgroundColor: theme.palette.grey[300],
		},
		'& .MuiSlider-mark': {
			backgroundColor: '#EFEFEF',
			height: 15,
			width: 2,
			borderRadius: 8,
			'&.MuiSlider-markActive': {
				opacity: 1,
				backgroundColor: '#EFEFEF',
			},
		},
		'& .MuiSlider-track': {
			background: 'none',
			border: 'none',
		},
	},
}));
