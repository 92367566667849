import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	infoButton: {
		color: '#3A1086',
	},
	tooltipContainer: {
		'& .MuiTooltip-tooltip': {
			padding: 20,
			background: theme.palette.common.white,
		},
	},
	tooltipText: {
		fontSize: '14px',
		color: theme.palette.grey[100],
		fontWeight: '400',
		'@media (max-width:600px)': {
			fontSize: 12,
		},
	},
}));
