import React from 'react';
import { Link, Navigate } from 'react-router-dom';

import { ThumbUp } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { Theme } from '@mui/material';

type Props = {};

const useStyles = makeStyles((theme: Theme) => ({
	heading: {
		fontSize: 16,
		fontWeight: 500,
		textAlign: 'center',
		color: '#000',
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	button: {
		width: '100%',
		height: 40,
		fontSize: 22,
		marginTop: 11,
	},
	icon: {
		width: 77,
		height: 70,
		color: '#000',
		margin: '53px 0px 60px 0px',
	},
	input: {
		marginTop: 24,
	},
	subHeading: {
		fontSize: 20,
		lineHeight: '23.87px',
		textAlign: 'center',
	},
	loginLink: {
		color: '#F26329',
	},
	btnBox: {
		marginTop: '20px',
		'& .MuiButton-root': {
			background: theme.palette.primary.main,
			padding: '7px 11px',
			borderRadius: ' 40px',
			width: '100%',
			height: '56px',
		},
	},
}));

const ThankYou = (props: Props) => {
	const styles = useStyles();
	return (
		<Box className={styles.form}>
			<Box mb={2} className={styles.heading}>
				Paassword Reset
			</Box>
			<ThumbUp className={styles.icon} />
			<div className={styles.subHeading}>
				Your Password has been reset. Please{' '}
				<Link className={styles.loginLink} to="/login">
					Click Here
				</Link>{' '}
				to log in
			</div>
		</Box>
	);
};

export default ThankYou;
