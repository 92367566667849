import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	main: {
		position: 'fixed',
		top: ' 80px',
		width: '100%',
		height: '100vh',
		background: theme.palette.primary.light,
	},
	fbtn: {
		borderRadius: '40px',
		padding: '5px 24px',
		fontWeight: '700',
	},
	sidebar: {
		background: '#23024d',
		padding: '40px 0 0 110px',
		width: '395px',
		height: '100vh',
		opacity: '0',
		visibility: 'hidden',
		display: 'none',
		transition: 'opacity 600ms, visibility 600ms',
	},
	activesidebar: {
		background: '#23024d',
		padding: '40px 0 40px 20px',
		width: '25%',
		opacity: '1',
		display: 'block',
		overflowX: 'hidden',
		overflowY: 'scroll',
		height: '100vh',
	},
	clinicList: {
		paddingBottom: '40px',
	},
	inner: {
		height: '100%',
	},
	sidebarBtn: {
		background: theme.palette.primary.main,
		borderRadius: '40px',
		color: theme.palette.common.white,
		padding: ' 8px 0px',
		width: '90%',
		height: '56px',
		margin: '0 24px 24px 0',
		fontWeight: '700',
	},
	link: {
		textDecoration: 'none',

		'& .MuiButtonBase-root': {
			color: '#938E9C',
			fontSize: '16px',
			padding: '13px 10px',
			borderRadius: '8px 0px 0px 8px',
		},
		'& .MuiListItemText-root .MuiTypography-root': {
			fontWeight: '600',
		},
		'& .MuiListItemIcon-root': {
			minWidth: '34px',
		},
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,

			'& .MuiListItemIcon-root svg': {
				filter: 'brightness(0) invert(1)',
			},
			'& .MuiButtonBase-root': {
				'& .MuiListItemText-root': {
					filter: 'brightness(0) invert(1)',
				},
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.common.white,
			},
		},
	},
	dropLink: {
		textDecoration: 'none',
		borderRadius: '8px 0px 0px 8px',
		'& .MuiButtonBase-root': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
			fontSize: '16px',
			padding: '13px 10px',
			borderRadius: '8px 0px 0px 8px',
		},
		// padding: "13px 10px 13px 20px",
		'& .MuiListItemText-root .MuiTypography-root': {
			fontWeight: '600',
		},
		'& .MuiListItemIcon-root svg': {
			filter: 'brightness(0) invert(1)',
		},
		'& .MuiListItemIcon-root': {
			minWidth: '34px',
		},
	},
}));
