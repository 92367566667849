import { createSlice } from 'utils/@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { ClientReportsState } from '../types';

export const initialState: ClientReportsState = {
	reportData: [],
	isLoading: false,
	error: '',
};

export const useClientReportSlice = createSlice({
	name: 'clientReportState',
	initialState,
	reducers: {
		getClientReport: (state, action: PayloadAction<any>) => {},
		setClientReportLoading: (state, action: PayloadAction<any>) => {
			state.isLoading = action.payload;
		},
		setClientReport: (state, action: PayloadAction<any>) => {
			state.reportData = action.payload;
		},
		setClientReportError: (state, action: PayloadAction<any>) => {
			state.error = action.payload;
		},
	},
});

export const { reducer, actions, name: sliceKey } = useClientReportSlice;
