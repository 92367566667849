import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
	},
	button: {
		marginTop: 10,
		float: 'right',
		backgroundColor: 'transparent',
		color: '#938E9C',
		textDecorationLine: 'underline',
		'@media (max-width:600px)': {
			display: 'none',
		},
	},
	saveNameMClass: {},
}));
