import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.patientState || initialState;
export const selectPatientsList = createSelector(
	[selectDomain],
	state => state.patientsList,
);
// export const selectNutrientsAndVitamins = createSelector([selectDomain], state => state.patientNutrientsVitaminsIncludeExlude);
export const selectNutrientsAndVitaminsSubDetails = createSelector(
	[selectDomain],
	state => state.patientNutrientsAndVitaminsSubDetails,
);
export const selectNutrientAndVitaminDetails = createSelector(
	[selectDomain],
	state => state.patientNutrientAndVitaminDetails,
);
export const selectRestrictionsDetails = createSelector(
	[selectDomain],
	state => state.restrictionsDetails,
);
export const selectOptionId = createSelector(
	[selectDomain],
	state => state.optionId,
);
export const selectPatientId = createSelector(
	[selectDomain],
	state => state.patientId,
);
export const selectArchivedSearchTerm = createSelector(
	[selectDomain],
	state => state.searchTermArchieved,
);
export const selectArchivePatientsList = createSelector(
	[selectDomain],
	state => state.patientsListArchived,
);
export const selectSearchTerm = createSelector(
	[selectDomain],
	state => state.searchTerm,
);
export const selectShowAlert = createSelector(
	[selectDomain],
	state => state.showAlert,
);
export const selectIngredientSearchTerm = createSelector(
	[selectDomain],
	state => state.ingredientSearchTerm,
);
export const selectSendMessage = createSelector(
	[selectDomain],
	state => state.sendMessage,
);
export const selectCountryCode = createSelector(
	[selectDomain],
	state => state.countryCode,
);
export const selectIngredientsList = createSelector(
	[selectDomain],
	state => state.ingredientsList,
);
export const selectSnacbar = createSelector(
	[selectDomain],
	state => state.snackbar,
);
export const selectSidebarOpen = createSelector(
	[selectDomain],
	state => state.isSidebarOpen,
);
export const selectSubMenuOpen = createSelector(
	[selectDomain],
	state => state.isSubMenuOpen,
);
export const selectUserSidebarOpen = createSelector(
	[selectDomain],
	state => state.isUserSidebarOpen,
);
export const selectLoadingIngredients = createSelector(
	[selectDomain],
	state => state.loadingIngredients,
);
export const selectAddPatientOpen = createSelector(
	[selectDomain],
	state => state.isAddPatientOpen,
);
export const selectIsPrePaymentOpen = createSelector(
	[selectDomain],
	state => state.isPrePaymentOpen,
);
export const selectFrom = createSelector([selectDomain], state => state);
export const selectPatientsListtotal = createSelector(
	[selectDomain],
	state => state.pageCount,
);
export const selectPatientsListtotalCount = createSelector(
	[selectDomain],
	state => state.totalCount,
);
export const selectArchivedPatientsListtotalCount = createSelector(
	[selectDomain],
	state => state.totalCountArchived,
);
export const selectArchivePatientOpen = createSelector(
	[selectDomain],
	state => state.isArchivePatientOpen,
);
export const selectUnArchivePatientOpen = createSelector(
	[selectDomain],
	state => state.isUnArchivePatientOpen,
);
export const selectReassignPatientOpen = createSelector(
	[selectDomain],
	state => state.isReassignPatientOpen,
);
export const selectDeletePatientOpen = createSelector(
	[selectDomain],
	state => state.isDeletePatientOpen,
);
export const selectSelectedPatient = createSelector(
	[selectDomain],
	state => state.selectedPatient,
);
export const selectReAssignedPractitionerId = createSelector(
	[selectDomain],
	state => state.reAssignedPractitionerId,
);
export const selectPatientLoading = createSelector(
	[selectDomain],
	state => state.loading,
);
export const selectPatientSummery = createSelector(
	[selectDomain],
	state => state.patientClinicalSummeryUpdate,
);
export const selectPatientDetails = createSelector(
	[selectDomain],
	state => state.patientUpadateDetails,
);
export const selectSnackBarStatus = createSelector(
	[selectDomain],
	state => state.snackBarStatus,
);
export const selectPatientPrescriptionMessage = createSelector(
	[selectDomain],
	state => state.prescriptionMessage,
);
export const selectAddPatient = createSelector(
	[selectDomain],
	state => state.addPatient,
);
export const selectOpenAllVitamins = createSelector(
	[selectDomain],
	state => state.openAllVitamins,
);
