import {
	Alert,
	Button,
	Container,
	FormControl,
	FormLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { TextField } from 'app/components/TextField';
import { IconLogo } from 'assets/images';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../slice';
import { selectSetAccountGuestsError } from '../selectors';
import { emailValidator } from 'utils/helpers';
import Reaptcha from 'reaptcha';
import { IS_DEVELOPMENT, ReCAPTCHA_KEY } from 'utils/constants';

type Props = {};

const SignUp = (props: Props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const styles = useStyles();
	const useSetAccountGuestsError = useSelector(selectSetAccountGuestsError);

	const [error, setError] = useState('');
	const [showMessage, setShowMessage] = useState(false);
	const [disableContinueBtn, setDisableContinueBtn] = useState(
		// false,
		IS_DEVELOPMENT ? false : true,
	);
	const [form, setForm] = useState({
		fullName: '',
		email: '',
		mobileNumber: '',
		hearAboutUs: '',
		companyName: '',
		website: '',
		businessAddress: '',
		clientCount: '',
		comment: '',
	});

	useEffect(() => {
		setError(useSetAccountGuestsError);
	}, [useSetAccountGuestsError]);

	const sendError = err => {
		setError(err);
		return false;
	};
	const validateFields = () => {
		console.log('emailValidator(form.email)', emailValidator(form.email));

		if (!form.fullName) {
			return sendError('Please enter full name');
		} else if (!emailValidator(form.email)) {
			return sendError(`Please enter correct email`);
		} else return true;
	};

	const onSuccess = () => {
		// navigate('/');
		setShowMessage(true);
	};

	const handleSubmit = e => {
		setError('');
		e.preventDefault();
		if (validateFields()) {
			dispatch(
				actions.setAccount({
					data: form,
					callback: onSuccess,
				}),
			);
		}
	};

	const onChange = (e, name) => {
		const { value } = e.target;
		setForm({ ...form, [name]: value });
		setError('');
		dispatch(actions.updateSetAccountGuestsError(''));
	};

	const onValidateSuccess = () => {
		setDisableContinueBtn(false);
	};

	const onChangeReCAPTCHA = token => {
		dispatch(
			actions.validateReCAPTCHAToken({
				data: token,
				callback: onValidateSuccess,
			}),
		);
	};

	const fields = [
		{
			type: 'name',
			required: true,
			name: 'fullName',
			title: 'Full name',
			value: form.fullName,
			isSelect: false,
		},
		{
			type: 'email',
			required: true,
			name: 'email',
			title: 'Email',
			value: form.email,
			isSelect: false,
		},
		// {
		// 	type: 'phone',
		// 	required: false,
		// 	name: 'mobileNumber',
		// 	title: 'Phone',
		// 	value: form.mobileNumber,
		// 	isSelect: false,
		// },
		{
			type: 'name',
			required: false,
			name: 'hearAboutUs',
			title: 'How did you hear about us?',
			value: form.hearAboutUs,
			isSelect: true,
			options: ['Referral', 'Online search', 'Conference', 'Other'],
		},
		{
			type: 'name',
			required: false,
			name: 'companyName',
			title: 'Company name',
			value: form.companyName,
			isSelect: false,
		},
		{
			type: 'name',
			required: false,
			name: 'website',
			title: 'Website',
			value: form.website,
			isSelect: false,
		},
		{
			type: 'name',
			required: false,
			name: 'businessAddress',
			title: 'Business address',
			value: form.businessAddress,
			isSelect: false,
		},
		{
			type: 'name',
			required: false,
			name: 'clientCount',
			title: 'Number of clients',
			value: form.clientCount,
			isSelect: true,
			options: ['1-10', '11-50', '51-200', '201-1000'],
		},
		{
			type: 'name',
			required: false,
			name: 'comment',
			title: `Is there anything else you'd like to share with us?`,
			value: form.comment,
			isSelect: false,
		},
	];
	return (
		<Box display="flex" alignItems={'center'} className={clsx(styles.root)}>
			<Container className={clsx(styles.container)}>
				<Box className={clsx(styles.background)}>
					{showMessage ? (
						<Box component={`div`} className={clsx(styles.messageBox)}>
							<p className={clsx(styles.messageText)}>
								Please access your email to establish a password for your
								anydish account
							</p>
						</Box>
					) : (
						<Box component={`div`} className={clsx(styles.content)}>
							<Box component={`div`} className={clsx(styles.head)}>
								<img src={IconLogo} alt="logo" className={clsx(styles.logo)} />
								<Typography className={clsx(styles.topTitle)}>
									for professionals
								</Typography>
								<Typography className={clsx(styles.topTxt)}>
									Let’s start your registration
								</Typography>
							</Box>
							<Box
								component="form"
								noValidate
								className={clsx(styles.loginForm)}
							>
								<Box className={clsx([styles.formContainer])}>
									{fields.map(field =>
										!field.isSelect ? (
											<FormControl
												variant="outlined"
												className={clsx([styles.formControl])}
												key={field.name}
											>
												<Box
													component={`div`}
													className={clsx([styles.formGroup])}
												>
													<FormLabel className={clsx([styles.title])}>
														{field.title}
														{field.required && ' *'}
													</FormLabel>
												</Box>
												<TextField
													height={51}
													variant="outlined"
													margin="normal"
													required={field.required}
													type={field.type}
													fullWidth
													id={field.name}
													name={field.name}
													autoComplete={field.value}
													value={field.value}
													onChange={e => onChange(e, field.name)}
													onKeyDown={(e: any) => {
														if (e.key === 'Enter') {
															e.preventDefault();
														}
													}}
												/>
											</FormControl>
										) : (
											<Box
												className={clsx([styles.formControl])}
												key={field.name}
											>
												<FormLabel className={clsx(styles.title)}>
													{field.title}
												</FormLabel>
												<Select
													value={field?.value}
													name={field.name}
													id={field.name}
													fullWidth
													onChange={e => onChange(e, field.name)}
													className={clsx(styles.selectInput)}
												>
													{field?.options?.map(option => (
														<MenuItem value={option} key={option}>
															{option}
														</MenuItem>
													))}
												</Select>
											</Box>
										),
									)}
								</Box>
								{error && (
									<Alert className={styles.error} severity="error">
										{error}
									</Alert>
								)}
								<FormControl
									variant="outlined"
									className={clsx([styles.FormControlBtn])}
								>
									<Box component={`div`} className={clsx([styles._btnBox])}>
										<Reaptcha
											sitekey={ReCAPTCHA_KEY}
											onVerify={onChangeReCAPTCHA}
										/>
										<Button
											className={clsx([styles.btnBox])}
											type="button"
											variant="contained"
											onClick={handleSubmit}
											disabled={disableContinueBtn}
										>
											Check your email
										</Button>
									</Box>
								</FormControl>
							</Box>
						</Box>
					)}
				</Box>
			</Container>
		</Box>
	);
};

export default SignUp;
