import { Box, InputAdornment } from '@mui/material';
import { TextField } from 'app/components/TextField';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './style';

export function Description({ title, templateData, setTemplateDate }) {
	const styles = useStyles();

	return (
		<Box className={clsx(styles.root)}>
			<small style={{ fontSize: 16, color: 'GrayText' }}>{title}:</small>
			<TextField
				fullWidth
				value={templateData}
				rows={3}
				multiline
				onChange={event => {
					setTemplateDate(event.target.value);
				}}
				name={'clinical_summary_input'}
				InputProps={{
					style: {
						fontSize: 15,
						lineHeight: '26px',
						fontWeight: '500',
						flexDirection: 'column',
						justifyContent: 'space-around',
						borderRadius: '20px',
						padding: '24px',
						width: '100%',
					},
					endAdornment: (
						<InputAdornment
							position="end"
							variant="filled"
							style={{ marginLeft: 'auto' }}
						></InputAdornment>
					),
				}}
				style={{
					borderRadius: '10px',
					background: '#FFF',
					margin: '5px 0px',
				}}
			/>
		</Box>
	);
}
