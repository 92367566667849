import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DASHBOARD_TOP_BAR_HEIGHT } from 'utils/constants';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		top: 0,
		width: '100%',
		position: 'absolute',
		height: DASHBOARD_TOP_BAR_HEIGHT,
		background: theme.palette.common.white,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 9,
	},
	logo: {
		'&:hover': {
			background: 'transparent',
		},
		marginRight: 20,
	},
	wrapper: {
		display: 'flex',
		margin: '0 auto',
	},
	logo_search_wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		height: '100%',
		alignItems: 'center',
	},
	search_box_parent: {
		position: 'relative',
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
	},
	search_input: {
		borderRadius: '50px',
		width: '70%',
		background: theme.palette.primary.light,
		paddingLeft: '15px',
	},
	nav_icons__container: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		// background: "cyan",
		// width: "30%",
		// paddingRight: "10px",
	},
	nav_icons_user_container: {
		// position: "relative",
		// marginRight:"10px",
		flexDirection: 'row',
		display: 'flex',
		'& .BaseBadge-root': {
			'& .BaseBadge-badge': {
				color: theme.palette.common.white,
				fontWeight: '600',
				zIndex: 'auto',
			},
		},
	},
	text: {
		width: '100px',
	},
	toggle: {
		marginLeft: '18px',
	},
	user_icon__span: {
		position: 'absolute',
		top: '-10px',
		left: '15px',
		background: theme.palette.secondary.main,
		color: theme.palette.common.white,
		fontSize: '10px',
		borderRadius: '5px',
		padding: '0 5px',
	},
	// nav_icons: {
	//     marginRight: "15px",
	// },

	search_box: {
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		top: '50%',
		left: '64%',
		padding: '25px 16px',
		boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
		borderRadius: '0px 0px 24px 24px',
		width: '70%',
		transform: 'translate(-50%, 11%)',
		display: 'none',
	},

	title: {
		color: theme.palette.grey[400],
		fontSize: '15px',
		lineHeight: '46px',
		fontWeight: '400',
		borderRadius: ' 16px',
		padding: '0 15px',
		transition: 'all 0.3s ease',
		cursor: 'pointer',
		'&:hover': {
			background: theme.palette.primary[200],
			color: theme.palette.primary.dark,
			fontWeight: '600',
		},
	},
	modalContent: {
		display: 'flex',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		borderRadius: '30px',
		outline: 'none',
		height: '90vh',
		width: '150vh',
		overflow: 'hidden', // Hide overflow to prevent scrollbar in modal
		'@media (max-width:600px)': {
			height: '40vh',
		},
	},
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		'@media (max-width:600px)': {
			height: '40vh',
		},
	},
	videoContainer: {
		flex: 1,
		position: 'relative',
		overflow: 'hidden', // Hide overflow in the container
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	closeButton: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(3),
	},
	iframe: {
		position: 'absolute',
		border: 'none',
		'@media (max-width:600px)': {
			position: 'relative',
		},
	},
}));
