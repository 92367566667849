import React, { useEffect, useState } from 'react';
import {
	Alert,
	Box,
	Button,
	Container,
	Grid,
	InputLabel,
	TextField,
} from '@mui/material';
import MainHeading from 'app/components/Heading/MainHeading';
import clsx from 'clsx';
import SideBar from '../../components/Sidebar';
import Header from '../../components/Header';
import ClinicList from './ClinicList';
import { useStyles } from './styles';
import CustomModal from '../../components/CustomModal';
import UserRightBar from '../../components/UserRightBar';
import { IconBluePlus, IconTrashRed } from '../../../assets/images';
import {
	selectContactPersonForm,
	selectActiveClinic,
	selectActiveClinicName,
	selectAddContactFromPopupOpen,
	selectActiveClinicIsVirtual,
} from '../../containers/Clinics/redux/selector';
import Content from '../../components/content';
import {
	actions,
	sliceKey,
	reducer,
} from '../../containers/Clinics/redux/slice';
import { actions as actionsPractitioner } from '../../containers/Practitioner/redux/slice';
import { ClinicRepoSaga } from '../../containers/Clinics/redux/saga';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch, useSelector } from 'react-redux';

export interface Props {}

const ClinicInfo = (props: Props) => {
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: ClinicRepoSaga });
	const [deletePopup, setDeletePopup] = useState(false);
	const form = useSelector(selectContactPersonForm);
	const selectedClinic = useSelector(selectActiveClinic);
	const selectedClinicName = useSelector(selectActiveClinicName);
	const selectedClinicIsVirtual = useSelector(selectActiveClinicIsVirtual);
	const selectedcontactPersonName = useSelector(selectAddContactFromPopupOpen);
	const dispatch = useDispatch();
	const styles = useStyles();
	const [patientPopup, setPatientPopup] = React.useState<any>(false);
	const [deleteClinicPopup, setDeleteClinicPopup] = React.useState<any>(false);
	const [isEdit, setisEdit] = useState(false);
	const [deleteId, setdeleteId] = useState('');
	const [deleteName, setDeleteName] = useState('');

	const PatientHandleOpen = (val: any) => {
		dispatch(actions.updateContactFormValue({ key: 'name', value: '' }));
		dispatch(actions.updateContactFormValue({ key: 'email', value: '' }));
		dispatch(actions.updateContactFormValue({ key: '_id', value: '' }));
		dispatch(actions.toggleAddContactFromPopup());
		setisEdit(false);
	};
	const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = evt.target;
		dispatch(actions.updateContactFormValue({ key: name, value }));
	};
	const closeContactFrom = () => {
		dispatch(actions.toggleAddContactFromPopup());
	};
	const onContactPersonAddSuccess = () => {
		dispatch(actions.updateContactFormValue({ key: 'name', value: '' }));
		dispatch(actions.updateContactFormValue({ key: 'email', value: '' }));
		dispatch(actions.updateContactFormValue({ key: '_id', value: '' }));
		dispatch(actions.toggleAddContactFromPopup());
	};
	const onContactPersonDeleteSuccess = () => {
		setdeleteId('');
		setDeletePopup(false);
	};
	useEffect(() => {
		dispatch(
			actions.updateContactFormValue({
				key: 'clinic_id',
				value: selectedClinic,
			}),
		);
	}, [selectedClinic]);

	const onSubmitForm = () => {
		try {
			if (form._id.length > 0) {
				dispatch(
					actions.editContactPerson({ callback: onContactPersonAddSuccess }),
				);
			} else {
				dispatch(
					actions.addContactPerson({ callback: onContactPersonAddSuccess }),
				);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const onDeleteContactPersonForm = () => {
		try {
			if (deleteId.length > 0) {
				dispatch(
					actions.deleteContactPerson({
						id: deleteId,
						callback: onContactPersonDeleteSuccess,
					}),
				);
			}
		} catch (e) {
			console.log(e);
		}
	};
	const onDeleteSuccess = () => {
		setDeleteClinicPopup(false);
		dispatch(actionsPractitioner.searchPractitionersClinic(''));
		dispatch(actions.setActiveClincName(''));
		dispatch(actions.setActiveClinc(''));
	};
	const deleteClinic = clinicId => {
		dispatch(
			actions.deleteSelectedClinic({
				id: clinicId,
				callback: onDeleteSuccess,
			}),
		);
	};

	return (
		<Box display="flex" alignItems={'center'}>
			<Header />
			<Content>
				<Box display="flex" className={clsx(styles.main)}>
					<Grid container>
						<Grid item lg={12} xs={12}>
							<Box display="flex" style={{ height: '100%' }}>
								<SideBar />
								{selectedClinic?.length > 0 && (
									<Container maxWidth={'xl'} className={clsx(styles.rightside)}>
										<Box
											className={clsx(styles.head)}
											display="flex"
											alignItems={'center'}
											justifyContent="space-between"
											style={{
												position: 'fixed',
												width: '75%',
												zIndex: 5,
												backgroundColor: '#F9F9F9',
												paddingTop: 30,
											}}
										>
											<MainHeading title={selectedClinicName} />
											{selectedClinicIsVirtual == 0 && (
												<Box
													display="flex"
													flexDirection={'column'}
													justifyContent="space-between"
													height={102}
												>
													<Button
														onClick={PatientHandleOpen}
														className={clsx(styles.btn)}
														variant="contained"
														color="primary"
													>
														Add a new contact
													</Button>
													<Button
														onClick={() => setDeleteClinicPopup(true)}
														className={clsx(styles.btn)}
														variant="contained"
														color="primary"
													>
														Delete clinic
													</Button>
												</Box>
											)}
										</Box>
										<Box
											className={clsx(styles.wrapper)}
											style={{ backgroundColor: '#F9F9F9' }}
										>
											<Box style={{ paddingTop: 30 }}>
												<ClinicList
													{...patientPopup}
													setPatientPopup={(data: any, id: string) => {
														// dispatch(actions.toggleAddContactFromPopup());
														setisEdit(true);
														dispatch(actions.fetchEditContactPerson(id));
													}}
													deleteContactPerson={(id: string, name: string) => {
														setdeleteId(id);
														setDeleteName(name);
														setDeletePopup(true);
													}}
												/>
											</Box>
										</Box>
									</Container>
								)}
								<UserRightBar />
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Content>
			<CustomModal
				btnTxt="Save"
				openModal={selectedcontactPersonName}
				setOpen={closeContactFrom}
				Icon={IconBluePlus}
				heading={` ${isEdit ? 'edit' : 'add a new'} contact person`}
				secondHeading="Register a new contact person, make sure all the details are correct!"
				modalClass={[styles.patient]}
				onSubmit={() => {
					onSubmitForm();
				}}
			>
				<Box component={`div`} className={styles.formGroup}>
					{form?.error?.length > 0 && (
						<Alert severity="error">{form.error}</Alert>
					)}
					<Box>
						<InputLabel className={styles.label}>
							Contact person’s full name{' '}
						</InputLabel>
						<TextField
							className={styles.input}
							InputLabelProps={{ style: { color: '#645B70' } }}
							type="text"
							name="name"
							fullWidth
							placeholder="Search input text"
							id="fullWidth"
							value={form.name}
							onChange={handleFieldChange}
							onKeyDown={(e: any) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
					</Box>
					<Box>
						<InputLabel className={styles.label}>
							Contact person’s email
						</InputLabel>
						<TextField
							className={styles.input}
							InputLabelProps={{ style: { color: '#645B70' } }}
							fullWidth
							type="email"
							name="email"
							placeholder="Contact person’s email"
							id="fullWidth"
							value={form.email}
							onChange={handleFieldChange}
							onKeyDown={(e: any) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
					</Box>
				</Box>
			</CustomModal>
			<CustomModal
				btnTxt="Delete"
				openModal={deletePopup}
				setOpen={setDeletePopup}
				Icon={IconTrashRed}
				heading="Delete"
				secondHeading="You are about to delete a contact person."
				modalClass={[styles.TrashMClass]}
				onSubmit={() => {
					onDeleteContactPersonForm();
				}}
			>
				<InputLabel className={styles.label}>Contact person’s name</InputLabel>
				<TextField
					className={styles.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					id="fullWidth"
					value={deleteName}
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</CustomModal>
			<CustomModal
				btnTxt="Delete"
				openModal={deleteClinicPopup}
				onSubmit={() => deleteClinic(selectedClinic)}
				setOpen={setDeleteClinicPopup}
				Icon={IconTrashRed}
				heading="Delete"
				secondHeading="You are about to delete a clinic."
				modalClass={[styles.TrashMClass]}
			>
				<InputLabel className={styles.label}>clinic's name</InputLabel>
				<TextField
					className={styles.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					placeholder="Aurora Johnson"
					id="fullWidth"
					value={selectedClinicName}
					disabled
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</CustomModal>
		</Box>
	);
};

export default ClinicInfo;
