import React, { useState } from 'react';
import UserInfoAccordion from '../UserInfo/UserInfoAccordion';
import { Box, Button, Tab } from '@mui/material';
import { useStyles } from './style';
import clsx from 'clsx';
import ReportTable from './ReportTable';
import { COOKED, SAVED } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from './redux/slice';
import { selectPatientId } from 'app/containers/Patients/redux/selector';
import { TabContext, TabList, TabPanel } from '@mui/lab';

interface ClientReportProps {
	patientDetail: any;
}

const mainActionButtonsInitValue = [
	{ text: SAVED, value: SAVED, pressed: true },
	{ text: COOKED, value: COOKED, pressed: false },
];
const actionButtonsInitValue = [
	{ text: 'Today', value: 'TODAY', pressed: true },
	{ text: 'Last 7 days', value: '7D', pressed: false },
	{ text: 'Last 30 days', value: '30D', pressed: false },
];
const ClientReport = ({ patientDetail }: ClientReportProps) => {
	const styles = useStyles();
	const dispatch = useDispatch();
	const usePatientId = useSelector(selectPatientId);
	const [mainActionButtons, setMainActionButtons] = useState(
		mainActionButtonsInitValue,
	);
	const [actionButtons, setActionButtons] = useState(actionButtonsInitValue);

	const getReport = value => {
		dispatch(actions.getClientReport({ type: value, patientId: usePatientId }));
	};

	const disableWhileAlreadyPressed = (array: any, value: any) => {
		return array?.filter(button => {
			return button?.value === value;
		});
	};

	const onClickReport = (array: any, value: any, setButtons: any) => {
		const [res] = disableWhileAlreadyPressed(array, value);
		if (res?.pressed) {
			return;
		}
		let _array = [...array];
		_array = _array.map(button => {
			if (button?.value === value) {
				return {
					...button,
					pressed: true,
				};
			} else {
				return {
					...button,
					pressed: false,
				};
			}
		});
		setButtons(_array);
		getReport(value);
	};

	const Btn = ({ text, pressed, onClick, style }: any) => {
		return (
			<Button
				className={clsx([style])}
				sx={{
					borderRadius: '50px',
					// marginLeft: '20px',
					fontWeight: pressed ? 700 : 400,

					// color: pressed ? '#40018C' : '#938E9C',
					color: '#938E9C',
					...style,
				}}
				onClick={onClick}
			>
				{text}
			</Button>
		);
	};
	const [value, setValue] = React.useState('1');

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};
	return (
		<UserInfoAccordion title="Client Report" marginSize={10}>
			<Box className={clsx(styles.buttonsContainer)}>
				<Box className={clsx(styles.buttonsBox)}>
					<TabContext value={value}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList
								onChange={handleChange}
								aria-label="lab API tabs example"
							>
								<Tab label={SAVED} value="1" />
								<Tab label={COOKED} value="2" />
							</TabList>
						</Box>
						{/* <TabPanel value="1">Item One</TabPanel> */}
						{/* <TabPanel value="2">Item Two</TabPanel> */}
					</TabContext>
					{/* {mainActionButtons?.map((actionButton, index) => (
						<Box key={index} className={clsx(styles.lineBox)}>
							<Btn
								value={actionButton?.value}
								text={actionButton?.text}
								pressed={actionButton?.pressed}
								onClick={() => {
									onClickReport(
										mainActionButtons,
										actionButton?.value,
										setMainActionButtons,
									);
								}}
								style={{ fontSize: 15 }}
							/>
							{index < mainActionButtons?.length - 1 && (
								<Box className={clsx(styles.line)} />
							)}
						</Box>
					))} */}
				</Box>
				<Box className={clsx(styles.tableBox)}>
					<Box className={clsx(styles.buttonsBox)}>
						{actionButtons?.map((actionButton, index) => (
							<Btn
								key={index}
								value={actionButton?.value}
								text={actionButton?.text}
								pressed={actionButton?.pressed}
								onClick={() => {
									onClickReport(
										actionButtons,
										actionButton?.value,
										setActionButtons,
									);
								}}
								style={{
									textDecorationLine: 'none',
									fontSize: 12,
									color: actionButton?.pressed ? '#565656' : '#938E9C',
								}}
							/>
						))}
					</Box>
					<ReportTable patientDetail={patientDetail} />
				</Box>
			</Box>
		</UserInfoAccordion>
	);
};

export default ClientReport;
