import { Backdrop, Box, CircularProgress } from '@mui/material';
import React from 'react';

const Loader = () => {
	return (
		<div>
			<Backdrop sx={{ color: '#40018C', zIndex: '100000' }} open={true}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Box
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			>
				<CircularProgress style={{ color: '#40018C' }} />
			</Box>
		</div>
	);
};

export default Loader;
