import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions as paypalActions } from 'app/containers/Auth/slice';

const style: any = {
	shape: 'pill',
	color: 'white',
	label: 'subscribe',
};
export const SET_PASSWORD = 'setPassword';
export const ButtonWrapper = ({
	showSpinner,
	onSuccessStatusCallback,
	link,
	sourceComponent,
}) => {
	const [{ isPending }] = usePayPalScriptReducer();

	const [token, setToken] = useState<any>('');
	useEffect(() => {
		if (sourceComponent === SET_PASSWORD && !token) {
			setToken(localStorage.getItem('token'));
		}
	}, [sourceComponent, token]);

	const dispatch = useDispatch();

	const onSuccess: any = () => {
		onSuccessStatusCallback();
	};
	const onField: any = data => {
		alert(`Something was wrong: ${JSON.stringify(data)}`);
	};
	const onApprove: any = data => {
		dispatch(
			paypalActions.onApprove({
				data: data,
				token: token,
				sourceComponent: sourceComponent,
				onSuccess: onSuccess,
				onField: onField,
			}),
		);
	};
	function onCancel(data) {
		console.log('onCancel, data:', data);
	}
	function onError(data) {
		console.log(`Error: ${JSON.stringify(data)}`);
	}
	function extractPlanId(string) {
		const keyword = 'plan_id';
		const index = string.indexOf(keyword);
		if (index !== -1) {
			return string.slice(index + keyword.length + 1);
		} else {
			return '';
		}
	}
	function createSubscription(data, actions) {
		if (link) {
			return actions.subscription.create({
				plan_id: extractPlanId(link),
			});
		}
	}
	return (
		<>
			{showSpinner && isPending && <div className="spinner" />}
			<PayPalButtons
				style={style}
				disabled={false}
				forceReRender={[style]}
				fundingSource={undefined}
				createSubscription={createSubscription}
				onApprove={onApprove}
				onCancel={onCancel}
				onError={onError}
			/>
		</>
	);
};
