import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useStyles } from '../style';
import { selectError, selectFaqListUser } from '../redux/selector';
import { useDispatch, useSelector } from 'react-redux';
import TopicLine from './TopicLine';
import CloseIcon from '@mui/icons-material/Close';
import { actions } from '../redux/slice';

type Props = {};

const HelpPage = (props: Props) => {
	const styles = useStyles();
	const useError = useSelector(selectError);
	const useFaqList = useSelector(selectFaqListUser);
	const [visible, setVisible] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		setVisible(true);
	}, [useFaqList]);

	return (
		<>
			<Box className={clsx(styles.root, { [styles.visible]: visible })}>
				<Box className={clsx(styles.titleBox)}>
					<Box className={clsx(styles._titleBox)}>
						<p className={clsx(styles.title)}>FAQ</p>
					</Box>
					<Box onClick={() => dispatch(actions.closeFaq(false))}>
						<CloseIcon />
					</Box>
				</Box>
				{useError && useError}
				{useFaqList?.map((faq, index) => (
					<TopicLine key={index} data={faq} />
				))}
			</Box>
		</>
	);
};

export default HelpPage;
