import { createSlice } from 'utils/@reduxjs/toolkit';
import { InitialPromoCodeState } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

export const initialState: InitialPromoCodeState = {
	promoCode: '',
	companyName: '',
	freeTrial: false,
	daysMonths: '',
	amount: '',
	link: '',
	planId: '',
	error: '',
	isAddPromoCodePopupOpen: false,
	loading: false,
	successAlert: '',
	paymentLink: '',
	code: '',
	paypalPlanId: '',
	promoCodeForm: {
		_id: '',
		companyName: '',
		daysMonths: '',
		paymentLink: '',
		amount: '',
		code: '',
		paypalPlanId: '',
		error: '',
		successAlert: '',
		freeTrial: false,
		packageName: '',
		paymentType: '',
		periodPayment: '',
		fee: '',
		pricePerPatientPerMonth: '',
		upFrontPayment: '',
		paymentMethod: '',
		clinic: '',
		currency: '',
	},
	promoCodeList: [],
	selectedPromoCode: {},
	isEditAction: false,
};

export const usePatientSlice = createSlice({
	name: 'promoCodeState',
	initialState,
	reducers: {
		toggleAddPromoCodePopup: state => {
			state.promoCodeForm.error = '';
			state.isAddPromoCodePopupOpen = !state.isAddPromoCodePopupOpen;
		},
		addPromoCode: (
			state,
			action: PayloadAction<{ isHaveFreeTrial: boolean; callback: () => void }>,
		) => {
			state.loading = true;
			state.promoCodeForm.error = '';
		},
		setPromoCodeError(state, action) {
			state.promoCodeForm.error = action.payload;
			state.loading = false;
		},
		setSuccessAlertPromoCode(state, action) {
			state.successAlert = action.payload;
			state.loading = false;
		},
		getPromoCodeList(state) {
			state.loading = true;
		},
		setPromoCodeList(state, action) {
			state.loading = false;
			state.promoCodeList = action.payload;
		},
		selectedPromoCode(state, action) {
			state.selectedPromoCode = action.payload;
		},
		cleanFormPromoSelected(state) {
			state.selectedPromoCode = '';
		},

		deletePromoCode(state, action) {},
		updateFormPromoCodeForEdit(state) {
			state.promoCodeForm._id = state.selectedPromoCode._id;
			state.promoCodeForm.companyName = state.selectedPromoCode.company_name;
			state.promoCodeForm.amount =
				state.selectedPromoCode.number_of_days_months;
			state.promoCodeForm.code = state.selectedPromoCode.promo_code;
			state.promoCodeForm.daysMonths =
				state.selectedPromoCode.days_months === 'months' ? 'Months' : 'Days';
			state.promoCodeForm.paymentLink = state.selectedPromoCode.payment_link;
			state.promoCodeForm.paypalPlanId = state.selectedPromoCode.paypal_plan_id;
			state.promoCodeForm.freeTrial = state.selectedPromoCode.free_trial;
			state.promoCodeForm.packageName = state.selectedPromoCode.package_name;
			state.promoCodeForm.paymentType = state.selectedPromoCode.payment_type;
			state.promoCodeForm.periodPayment =
				state.selectedPromoCode.period_payment;
			state.promoCodeForm.fee = state.selectedPromoCode.fee;
			state.promoCodeForm.currency = state.selectedPromoCode.currency;
			state.promoCodeForm.pricePerPatientPerMonth =
				state.selectedPromoCode.price_per_patient_per_month;
			state.promoCodeForm.upFrontPayment =
				state.selectedPromoCode.up_front_payment;
			state.promoCodeForm.clinic = state.selectedPromoCode.clinic;
			state.promoCodeForm.paymentMethod =
				state.selectedPromoCode.payment_method;
		},
		updateFormPromoCodeValue: (
			state,
			action: PayloadAction<{ key: string; value: any }>,
		) => {
			state.promoCodeForm[action.payload.key] = action.payload.value;
			state.promoCodeForm.error = '';
			state.loading = false;
		},
		handleFieldFreeTrialChange: (state, action) => {
			state.promoCodeForm.freeTrial = action.payload;
			if (!action.payload) {
				state.promoCodeForm.daysMonths = '';
				state.promoCodeForm.amount = '';
			}
			state.promoCodeForm.error = '';
		},
		cleanFormPromoCode: state => {
			state.promoCodeForm._id = '';
			state.promoCodeForm.companyName = '';
			state.promoCodeForm.daysMonths = '';
			state.promoCodeForm.paymentLink = '';
			state.promoCodeForm.amount = '';
			state.promoCodeForm.code = '';
			state.promoCodeForm.paypalPlanId = '';
			state.promoCodeForm.packageName = '';
			state.promoCodeForm.paymentType = '';
			state.promoCodeForm.periodPayment = '';
			state.promoCodeForm.fee = '';
			state.promoCodeForm.pricePerPatientPerMonth = '';
			state.promoCodeForm.currency = '';
			state.promoCodeForm.upFrontPayment = '';
			state.promoCodeForm.paymentMethod = '';
			state.promoCodeForm.error = '';
			state.promoCodeForm.clinic = '';
			state.promoCodeForm.freeTrial = false;
			state.loading = false;
		},
		isEditAction: (state, action) => {
			state.isEditAction = action.payload;
		},
	},
});

export const { reducer, actions, name: sliceKey } = usePatientSlice;
