import { selectUserType } from 'app/containers/Auth/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useIsPractitionerUser = () => {
	const userType = useSelector(selectUserType);
	const [isPractitionerUser, setIsPractitionerUser] = useState(false);
	useEffect(() => {
		const _isPractitionerUser = userType === '2' || userType === 'PRACTITIONER';
		setIsPractitionerUser(_isPractitionerUser);
	}, [userType]);

	return isPractitionerUser;
};
