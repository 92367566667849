import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLoginForm } from '../app/containers/Auth/selectors';
import Loader from 'app/components/Loader';

function PrivateRoute({ children }: any) {
	const [loading, setloading] = useState(true);
	const [isAuthenticated, setisAuthenticated] = useState(false);
	const userData = useSelector(selectLoginForm);

	useEffect(() => {
		setisAuthenticated(userData.token !== '');
		setloading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData.token]);

	return !loading ? (
		isAuthenticated ? (
			children
		) : (
			<Navigate to={'/login'} />
		)
	) : (
		<Loader />
	);
}
export default PrivateRoute;
