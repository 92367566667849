import { Box } from '@mui/material';
import React from 'react';
import BoldText from '../BoldText';

const ExplanatoryNote = () => {
	return (
		<Box
			style={{
				width: '98%',
				padding: '20px',
				backgroundColor: 'white',
				margin: '0 auto',
			}}
		>
			Please note - you can edit each nutrient and set it as{' '}
			<BoldText text="Required" />, that will determine whether a recipe will
			appear in your result, whereas <BoldText text="Preferred" /> merely
			influences the score of each recipe shown in those results.
		</Box>
	);
};

export default ExplanatoryNote;
