import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	// TODO REMOVE EXTRA CSS
	// main: {
	//   position: 'fixed',
	//   top: ' 80px',
	//   width: '100%',
	//   height: '100vh',
	//   background: theme.palette.primary.light,
	// },
	// modal: {
	//   "& .MuiBackdrop-root": {
	//     background: "rgb(29 1 73 / 60%)"
	//   }
	// },
	// fbtn: {
	//   borderRadius: '40px',
	//   padding: '5px 24px',
	//   fontWeight: '700',
	// },
	// btn: {
	//   background: theme.palette.grey[200],
	//   borderRadius: '40px',
	//   color: theme.palette.common.white,
	//   padding: ' 8px 0px',
	//   width: '173px',
	//   height: '56px',
	//   marginBottom: '24px',
	//   fontWeight: '700',
	//   boxShadow: "none"
	// },
	// searchBox: {
	//   margin: "20px 0",

	// },
	// BtnCircle: {
	//   background: theme.palette.primary.main,
	//   borderRadius: '100%',
	//   width: '48px',
	//   height: '48px',
	//   minWidth: "48px",
	//   marginRight: "24px"
	// },
	// BtnWhite: {
	//   background: theme.palette.common.white,
	// },
	// popup: {
	//   background: theme.palette.common.white,
	//   borderRadius: '32px',
	//   position: 'absolute',
	//   top: '50%',
	//   left: '50%',
	//   transform: 'translate(-50%, -50%)',
	//   width: 400,
	//   padding: '54px 24px 24px',
	// },
	// popupIcon: {
	//   position: 'absolute',
	//   top: ' 0',
	//   left: '50%',
	//   transform: 'translate(-50%, -50%)',
	//   width: '70px',
	//   height: '70px',
	//   borderRadius: '40px',
	//   background: theme.palette.common.white,
	//   '&:hover': {
	//     background: theme.palette.common.white,
	//   },
	// },
	// title: {
	//   color: theme.palette.secondary.dark,
	//   fontWeight: '700',
	//   marginBottom: '10px',
	// },
	label: {
		paddingLeft: '24px',
		fontSize: '12px',
		color: theme.palette.primary.main,
		fontWeight: '700',
		marginBottom: '8px',
	},
	input: {
		'& .MuiOutlinedInput-root': {
			background: theme.palette.grey[300],
			borderRadius: '40px',
			fontWeight: '500',
			paddingLeft: '20px',
		},
		'& .MuiFormHelperText-root': {
			textAlign: 'right',
			color: theme.palette.primary.main,
			fontWeight: '700',
			fontSize: '12px',
			marginTop: 7,
		},
	},
	// Left Side Styles
	// wrapper: { marginTop: '30px' },
	// head: {
	//   width: '100%',
	//   '& .MuiBox-root': {
	//     marginBottom: '0',
	//   },
	//   '& .MuiButton-root': {
	//     marginBottom: '0',
	//   },
	// },
	// rightside: {
	//   height: '100%',
	// },
	// search_input: {
	//   borderRadius: "40px",
	//   width: "70%",
	//   background: theme.palette.common.white,
	//   paddingLeft: "15px"
	// },
	TrashMClass: {
		'& .MuiTypography-h6': {
			color: '#DD2A19',
		},
		'& .MuiTypography-body1': {
			color: '#66130B',
		},
		'& .MuiInputLabel-root': {
			color: '#938E9C',
		},

		'& .MuiOutlinedInput-root': {
			background: 'transparent',
			border: '1px solid #F1F0F2',
		},

		'& .MuiButton-containedPrimary': {
			background: '#DD2A19 !important',
		},
		'& .MuiButton-textPrimary': {
			color: '#66130B !important',
		},
	},
	ReassignMClass: {
		'& .MuiOutlinedInput-root': {
			border: '1px solid #F1F0F2',
			background: 'transparent',
		},
		'& .MuiTypography-h5': {
			color: theme.palette.secondary.dark,
			fontWeight: '700',
			marginBottom: '10px',
			fontSize: '16px',
			paddingLeft: '24px',
		},
	},
	formGroup: { margin: '20px 0' },
	searchInput: {
		borderRadius: '40px',
		width: '100%',
		background: '#F1F0F2',
		paddingLeft: '15px',
	},
	patient: {},
	archive: {
		'& .MuiTypography-h5': {
			paddingLeft: 0,
		},
	},
	// heading: {
	//   margin: "0", fontSize: "25px", color: "#0F172A", fontWeight: "500"
	// },
	// headingGray: {
	//   marginRight: 10, fontSize: "32px", color: "#938E9C", fontWeight: "700"

	// },
	// pageBtn: {
	//   marginLeft: 10,
	//   borderRadius: 30,
	//   backgroundColor: theme.palette.common.white,
	//   minHeight: 35,
	//   padding: 0,
	//   minWidth: 50,
	// },
}));
