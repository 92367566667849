import React from 'react';

export default function PersonOutline() {
	return (
		<div>
			<svg
				width="16"
				height="18"
				viewBox="0 0 16 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M5.08333 0.833496C5.08333 0.833496 4.66667 4.16683 4.66667 5.8335C4.66667 7.67183 6.16167 9.16683 8 9.16683C9.83833 9.16683 11.3333 7.67183 11.3333 5.8335C11.3333 4.16683 10.9167 0.833496 10.9167 0.833496H5.08333ZM6.57096 2.50016H9.42904C9.48237 3.01016 9.53949 3.59766 9.58366 4.16683H6.41634C6.46134 3.59766 6.51763 3.01016 6.57096 2.50016ZM6.33333 5.8335H9.66667C9.66667 6.75266 8.91917 7.50016 8 7.50016C7.08083 7.50016 6.33333 6.75266 6.33333 5.8335ZM8 11.6668C5.3825 11.6668 0.5 12.9552 0.5 15.4168V17.5002H15.5V15.4168C15.5 12.9552 10.6175 11.6668 8 11.6668ZM6.57259 13.4556L8 15.8335L9.42741 13.4556C11.7128 13.7941 13.8333 14.8174 13.8333 15.4168V15.8335H8H2.16667V15.4168C2.16667 14.8174 4.2872 13.7941 6.57259 13.4556Z"
					fill="#938E9C"
				/>
			</svg>
		</div>
	);
}
