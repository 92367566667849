import { t } from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { request } from 'utils/request';
import { actions } from './slice';

export function* getClientReport(action) {
	const type = action.payload?.type;
	const patientId = action.payload?.patientId;
	const data = {
		pageSize: 50,
		isSaved: type === 'Saved' ? true : false,
		pageNo: 1,
		patientId,
	};
	const options = {
		method: 'POST',
		body: JSON.stringify({ ...data }),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/patient/get-client-report`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.GET_CLIENT_REPORT.SUCCESS'
		) {
			yield put(actions.setClientReportLoading(false));
		} else {
			yield put(actions.setClientReport(response?.data?.results));
			yield put(actions.setClientReportLoading(false));
			return;
		}
	} catch (e: any) {
		console.error('Error getClientReport:', e);
		yield put(actions.setClientReportLoading(false));
		let errorMessage = t('something went wrong');
		try {
			const errors = JSON.parse(e.message);
			errorMessage = errors.message;
		} catch (parseError) {
			console.error('Error parsing JSON:', parseError);
		}
		yield put(actions.setClientReportError(errorMessage));
	}
}

export function* ClientReportRepoSaga() {
	yield takeLatest(actions.getClientReport.type, getClientReport);
}
