import { TextField } from 'app/components/TextField';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useStyles } from './styles';
import { Lock, LockReset } from '@mui/icons-material';
import {
	Alert,
	Button,
	Container,
	FormControl,
	FormLabel,
	InputAdornment,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';

import { selectResetPasswordForm } from '../selectors';
import { actions } from '../slice';
import ThankYou from '../Thankyou';
import clsx from 'clsx';
import { IconLogo, ImageForgetPassword } from 'assets/images';

type Props = {};

const ResetPassword = (props: Props) => {
	const styles = useStyles();
	const [isUpdated, setIsUpdated] = useState(false);
	const { password, confirm_password, error } = useSelector(
		selectResetPasswordForm,
	);
	const [searchParams, setSearchParams] = useSearchParams();
	const dispatch = useDispatch();
	const tokenId = searchParams.get('token');
	const onSuccess = () => {
		setIsUpdated(true);
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (password === confirm_password) {
			dispatch(
				actions.resetPassword({
					token: searchParams.get('token'),
					callback: onSuccess,
				}),
			);
		} else {
			dispatch(
				actions.updateResetForm({
					name: 'error',
					value: "Password doesn't match",
				}),
			);
		}
	};

	const onChange = e => {
		const { name, value } = e.target;
		dispatch(actions.updateResetForm({ name, value }));
	};

	if (!tokenId || tokenId?.length <= 0) {
		return (
			<Navigate
				to={{
					pathname: '/login',
				}}
			/>
		);
	}
	if (tokenId) {
		dispatch(
			actions.updateResetForm({ name: 'resetPassToken', value: tokenId }),
		);
	}

	const resetPassword = (
		<Box display="flex" alignItems={'center'} className={clsx(styles.root)}>
			<Container className={clsx(styles.container)}>
				<Box>
					<img
						src={ImageForgetPassword}
						alt="right bg"
						className={clsx(styles.img)}
					/>
					<Box component={`div`} className={clsx(styles.content)}>
						<Box component={`div`} className={clsx(styles.head)}>
							<img src={IconLogo} alt="logo" className={clsx(styles.logo)} />
							<Typography variant={`h6`} className={clsx(styles.topTxt)}>
								Enter the email address associated with your account, and we’ll
								send you a link to reset your password.{' '}
							</Typography>
						</Box>

						<Box
							component="form"
							noValidate
							sx={{
								display: 'grid',
								gridTemplateColumns: { sm: '1fr' },
								gap: 1,
								columnGap: 7,
							}}
							className={clsx(styles.loginForm)}
						>
							{error && (
								<Alert className={styles.error} severity="error">
									{error}
								</Alert>
							)}
							<FormControl variant="outlined">
								<Box component={`div`} className={clsx([styles.formGroup])}>
									<FormLabel className={clsx([styles.title])}>
										New Password
									</FormLabel>
								</Box>
								<TextField
									height={51}
									variant="outlined"
									margin="normal"
									required
									type="password"
									fullWidth
									id="password"
									placeholder="New Password"
									name="password"
									autoComplete="password"
									value={password}
									autoFocus
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Lock />
											</InputAdornment>
										),
									}}
									onChange={onChange}
									onKeyDown={(e: any) => {
										if (e.key === 'Enter') {
											e.preventDefault();
										}
									}}
								/>
							</FormControl>
							<FormControl variant="outlined">
								<Box component={`div`} className={clsx([styles.formGroup])}>
									<FormLabel className={clsx([styles.title])}>
										Confirm Password
									</FormLabel>
								</Box>
								<TextField
									height={51}
									variant="outlined"
									margin="normal"
									type="password"
									required
									fullWidth
									id="confirmPassword"
									placeholder="Confirm Password"
									name="confirm_password"
									autoComplete="confirmPassword"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Lock />
											</InputAdornment>
										),
									}}
									value={confirm_password}
									onChange={onChange}
									onKeyDown={(e: any) => {
										if (e.key === 'Enter') {
											e.preventDefault();
										}
									}}
								/>
							</FormControl>
							<FormControl sx={{ width: '100' }} variant="outlined">
								<Box component={`div`} className={clsx([styles.btnBox])}>
									<Button
										type="button"
										variant="contained"
										onClick={handleSubmit}
									>
										Continue
									</Button>
								</Box>
							</FormControl>
						</Box>
					</Box>
				</Box>
			</Container>
		</Box>
	);
	return !isUpdated ? resetPassword : <ThankYou />;
};

export default ResetPassword;
