import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Link, Modal } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './styles';

interface TopbarProps {
	showWatchTraining?: boolean;
}
const WatchTraining = ({ showWatchTraining }: TopbarProps) => {
	const styles = useStyles();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={clsx(styles.modal)}
			>
				<div className={clsx(styles.modalContent)}>
					<div className={clsx(styles.videoContainer)}>
						<IconButton
							aria-label="close"
							onClick={handleClose}
							className={clsx(styles.closeButton)}
						>
							<CloseIcon />
						</IconButton>
						<iframe
							src="https://player.vimeo.com/video/872591084?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"
							width="1000"
							height="600"
							allow={'autoplay; fullscreen; picture-in-picture'}
							allowFullScreen={true}
							title="demo"
							className={clsx(styles.iframe)}
						/>
					</div>
				</div>
			</Modal>
			<Link
				component="button"
				variant="body2"
				className={clsx(styles.text)}
				onClick={() => {
					handleOpen();
				}}
			>
				Watch training
			</Link>
		</>
	);
};

export default WatchTraining;
