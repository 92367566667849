import { selectEmail } from 'app/containers/Auth/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VIRTUAL_PRACTITIONER_EMAIL } from 'utils/constants';

export const useVirtualPractitionerEmail = () => {
	const useEmail = useSelector(selectEmail);

	const [isVirtualPractitioner, setIsVirtualPractitioner] = useState(false);
	useEffect(() => {
		const _isVirtualPractitioner = useEmail === VIRTUAL_PRACTITIONER_EMAIL;
		setIsVirtualPractitioner(_isVirtualPractitioner);
	}, [useEmail]);

	return { isVirtualPractitioner };
};
