import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import clsx from 'clsx';
import { useStyles } from '../style';
import { FaqObj } from '../types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type Props = {
	data: FaqObj;
};

const QuestionLine = ({ data }: Props) => {
	const styles = useStyles();
	const [showAnswer, setShowAnswer] = useState(false);

	const openAnswer = () => {
		setShowAnswer(p => !p);
	};

	return (
		<>
			<Button
				onClick={() => {
					openAnswer();
				}}
				className={clsx([styles.helpQuestion])}
			>
				<p className={clsx([styles.questionText])}>{data?.question}</p>
			</Button>
			{showAnswer && (
				<p className={clsx([styles.answerText])}>{data?.answer}</p>
			)}
		</>
	);
};

export default QuestionLine;
