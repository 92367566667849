import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DASHBOARD_TOP_BAR_HEIGHT } from 'utils/constants';

export const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: '100%',
		'& .MuiFormControl-root': {
			width: '100%',
		},
		'& .MuiAutocomplete-popupIndicator svg': {
			color: '#000',
		},
		'& .MuiAutocomplete-clearIndicatorDirty': {
			display: 'none',
		},
	},
	button: {
		marginBottom: 10,
		borderRadius: '40px',
		padding: '8px 0px',
		width: '205px',
		height: '56px',
		fontWeight: '700',
		boxShadow: 'none',
		backgroundColor: '#40018C',
		color: '#fff',
		'@media (max-width:600px)': {
			padding: '0px 8px',
			fontSize: 10,
			width: '100%',
			height: '40px',
			fontWeight: '400',
		},
	},
	wrapper: {
		margin: '0 auto',
		padding: '30px 0px',
		background: 'none',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},
	textNameBox: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	nameAndTemplateBox: {
		width: '50%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	nameAndTemplateBox2: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
	},
	root: {
		background: theme.palette.common.white,
		borderRadius: '20px',
		padding: '10px',
		minHeight: 150,
	},
	form: {
		borderRadius: '24px',
		borderColor: theme.palette.common.white,
		marginBottom: '16px',
	},
	legend: { marginLeft: '20px' },
	chips: {
		padding: '18px 12px',
		borderRadius: '30px',
		background: theme.palette.common.white,
		margin: '5px',
		fontSize: '15px',
		fontFamily: 'Plus Jakarta Sans',
	},
	userInfoBox: {
		display: 'flex',
		flexWrap: 'wrap',
		backgroundColor: '#FFFFFF',
		borderRadius: '30px',
		padding: '2rem',
		paddingTop: '1rem',
	},
	userInfoBoxItem: {
		flex: '40%',
		marginBottom: '10px',
		borderBottom: '1px solid #E5E5E5',
	},
	modal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
	},
	modalContent: {
		display: 'flex',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		borderRadius: '30px',
		outline: 'none',
		height: '90vh',
		width: '150vh',
		overflow: 'hidden', // Hide overflow to prevent scrollbar in modal
	},
	closeButton: {
		position: 'absolute',
		top: theme.spacing(1),
		right: theme.spacing(3),
	},
	videoContainer: {
		flex: 1,
		position: 'relative',
		overflow: 'hidden', // Hide overflow in the container
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	iframe: {
		position: 'absolute',
		border: 'none',
	},
	contentContainer: {
		// padding: theme.spacing(2),
		// overflow: 'auto',
	},
	warningBox: {
		display: 'flex',
		flexDirection: 'row',
		height: 35,
	},
	warningButton: {
		display: 'flex',
		flex: 1,
		padding: '10px 20px',
	},
	warningDisableButton: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 5,
	},
	warningButtonText: {
		fontWeight: '600',
		fontSize: '14px',
		color: '#3A1086',
	},
	checkboxBox: {
		paddingTop: '8px',
		display: 'flex',
		flexDirection: 'row',
	},
	checkboxLabel: {
		fontWeight: '700',
		fontSize: '12px',
		color: theme.palette.common.black,
		marginLeft: 10,
	},
	customCheckbox: {
		padding: 0,
		marginLeft: '24px',
		width: 18,
		height: 18,
	},
	resetTemplateView: {
		display: 'flex',
		flexDirection: 'row',
	},
	chipBox: {
		backgroundColor: '#EBE6F3',
		color: '#3A1086',
		marginRight: 8,
		marginBottom: 8,
		borderRadius: 30,
		padding: '5px 10px',
		display: 'inline-block',
		flexDirection: 'row',
	},
	chipBox2: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	foodLabelBox: {
		marginRight: 2,
		fontWeight: '500',
		fontSize: '13px',
	},
	cancelOutlinedIcon: {
		'&:hover': {
			color: '#938E9C',
		},
	},
	saveNameMClass: {},
	label: {
		paddingLeft: '24px',
		fontSize: '12px',
		color: theme.palette.primary.main,
		fontWeight: '700',
		marginBottom: '8px',
	},
}));
