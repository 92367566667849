import { delay, put, select, takeLatest, call } from 'redux-saga/effects';
import { actions } from './slice';
import { API_URL } from 'utils/constants';
import { request } from 'utils/request';
import { t } from 'i18next';
import { PromoCodeForm } from './types';
import { selectIsEditAction, selectPromoCodeForm } from './selectors';

export function* addPromoCodeRequest(action) {
	yield delay(500);
	const useIsEditAction: boolean = yield select(selectIsEditAction);
	const form: PromoCodeForm = yield select(selectPromoCodeForm);
	if (form?.companyName?.length === 0) {
		yield put(actions.setPromoCodeError('Please enter company name'));
		return;
	}

	if (!form?.code) {
		yield put(actions.setPromoCodeError('Please enter code'));
		return;
	}
	if (!form?.clinic) {
		yield put(actions.setPromoCodeError('Please choose clinic'));
		return;
	}
	if (form?.paymentMethod !== 'Free' && !form?.paymentLink) {
		yield put(actions.setPromoCodeError('Please enter payment link'));
		return;
	}
	if (!form?.paymentMethod) {
		yield put(actions.setPromoCodeError('Please choose payment method'));
		return;
	}
	if (action.payload.isHaveFreeTrial) {
		if (form?.daysMonths === '') {
			yield put(
				actions.setPromoCodeError(
					'Please choose unit of measure of the trial period',
				),
			);
			return;
		}
		if (
			form?.amount === 0 ||
			form?.amount === '' ||
			form?.amount === null ||
			form?.amount === undefined
		) {
			yield put(
				actions.setPromoCodeError('Please enter amount of the trial period'),
			);
			return;
		}
	}
	const data = {
		isEditAction: useIsEditAction,
		isHaveFreeTrial: action.payload?.isHaveFreeTrial || false,
		...form,
	};
	const options = {
		method: 'POST',
		body: JSON.stringify(data),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/clinic/add-promo-code`,
			options,
		);
		if (response && response.messageCode === 'MESSAGE.PROMO_CODE_ADD.ERROR') {
			yield put(actions.setPromoCodeError(t(`${response.message}`)));
			return;
		} else if (
			response &&
			response.messageCode !== 'MESSAGE.PROMO_CODE_ADD.SUCCESS'
		) {
			yield put(actions.setPromoCodeError(t(`${response.message}`)));
			return;
		}
		yield put(actions.setSuccessAlertPromoCode(t(`${response.message}`)));
		yield put(actions.setPromoCodeList(response.data));
		yield call(action?.payload?.callback);
	} catch (e: any) {
		let erros = JSON.parse(e.message);
		if (erros.errors) {
			for (let index = 0; index < erros.errors.length; index++) {
				const element = erros.errors[index];
				let keys = Object.keys(element.constraints);
				if (keys.length > 0) {
					yield put(actions.setPromoCodeError(element.constraints[keys[0]]));
				}
			}
		} else {
			yield put(actions.setPromoCodeError('something went wrong'));
		}
	}
}

export function* getPromoCode() {
	yield delay(500);
	const options = {
		method: 'GET',
	};
	// let sessionToken = localStorage.getItem('sessionToken');
	// if (!sessionToken) {
	// 	sessionToken = yield select(selectToken);
	// 	console.log('_sessionToken', sessionToken);

	// 	if (sessionToken) {
	// 		yield localStorage.setItem('sessionToken', sessionToken);
	// 	}
	// }
	try {
		const response = yield call(
			request,
			`${API_URL}/clinic/promo-code-get-list`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.PROMO_CODE_GET_LIST.SUCCESS'
		) {
			if (!response?.message) {
				yield put(actions.setPromoCodeError(t('something went wrong')));
			} else {
				yield put(actions.setPromoCodeError(t(`${response.message}`)));
			}
			return;
		}
		yield put(actions.setPromoCodeList(response.data));
	} catch (e: any) {
		let errors = JSON.parse(e.message);
		yield put(actions.setPromoCodeError(errors));
	}
}

export function* deletePromoCode(action) {
	yield delay(500);
	const options = {
		method: 'DELETE',
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/clinic/promo-code/${action.payload.id}`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.PROMO_CODE_DELETE.SUCCESS'
		) {
			if (!response?.message) {
				yield put(actions.setPromoCodeError(t('something went wrong')));
			} else {
				yield put(actions.setPromoCodeError(t(`${response.message}`)));
			}
			return;
		}
		yield put(actions.setPromoCodeList(response.data));
		yield call(action?.payload?.callback);
	} catch (e: any) {
		let errors = JSON.parse(e.message);
		yield put(actions.setPromoCodeError(errors));
	}
}

export function* PromoCodeRepoSaga() {
	yield takeLatest(actions.addPromoCode.type, addPromoCodeRequest);
	yield takeLatest(actions.getPromoCodeList.type, getPromoCode);
	yield takeLatest(actions.deletePromoCode.type, deletePromoCode);
}
