import { TextField } from 'app/components/TextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import {
	Box,
	Button,
	Alert,
	CircularProgress,
	FormControl,
	FormLabel,
	Typography,
} from '@mui/material';
import { useStyles } from './styles';
import {
	selectError,
	selectLoading,
	selectLoginForm,
	selectIsAgreed,
} from '../../selectors';
import { actions } from '../../slice';
import { LoginErrorType } from '../../types';
import { IconLogo, IconWebsiteLogo } from '../../../../../assets/images';
import CustomModal from 'app/components/CustomModal';

export function LoginForm({ path, className }) {
	const classes = useStyles();
	const form = useSelector(selectLoginForm);
	const isLoading = useSelector(selectLoading);
	const error = useSelector(selectError);
	// const isAgreed = useSelector(selectIsAgreed);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [errorMsg, setErrorMsg] = useState('');
	const [showTermsPopUp, setShowTermsPopUp] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		if (error) setErrorMsg(t(`${error}`));
		// eslint-disable-next-line
	}, [error]);
	useEffect(() => {
		dispatch(actions.updateFormValue({ key: 'path', value: path }));
	}, [dispatch, path]);

	const onLoginSuccessShowPopUp = () => {
		setShowTermsPopUp(true);
	};

	const onLoginSuccessNavigate = () => {
		navigate('/');
	};

	const onTermsSuccess = () => {
		navigate('/');
	};

	const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = evt.target;
		dispatch(actions.updateFormValue({ key: name, value }));
	};

	const onSubmitForm = (evt?: React.FormEvent<HTMLFormElement>) => {
		try {
			setErrorMsg('');
			if (evt !== undefined && evt.preventDefault) {
				evt.preventDefault();
				if (!form.username || !form.password) {
					setErrorMsg('Please Enter Email ID and Password');
					return;
				}
				dispatch(
					actions.login({
						navigateCallback: onLoginSuccessNavigate,
						popupCallback: onLoginSuccessShowPopUp,
					}),
				);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const handleSubmitTerms = () => {
		setShowTermsPopUp(false);
		dispatch(actions.setAgreedTerms({ callback: onTermsSuccess }));
	};

	const errorText = errorMsg;
	return (
		<>
			<Box component={`div`} className={clsx(classes.content)}>
				<Box component={`div`} className={clsx(classes.head)}>
					<Typography variant={`h6`} className={clsx(classes.topTxt)}>
						Welcome to{' '}
					</Typography>
					<img src={IconLogo} className={clsx(classes.logo)} alt="right bg" />
				</Box>
			</Box>
			<form className={classes.form} noValidate onSubmit={onSubmitForm}>
				{errorText && (
					<Alert className={classes.error} severity="error">
						{errorText}
					</Alert>
				)}
				<FormControl variant="outlined" sx={{ width: '100%' }}>
					<Box component={`div`} className={clsx([classes.formGroup])}>
						<FormLabel className={clsx([classes.title])}>
							Email address
						</FormLabel>
					</Box>
					<TextField
						variant="outlined"
						required
						id="username"
						placeholder="JennyS@gmail.com"
						name="username"
						autoComplete="username"
						error={loginError(error)?.username}
						autoFocus
						value={form.username}
						onChange={handleFieldChange}
						className={classes.email}
						onKeyDown={(e: any) => {
							if (e.key === 'Enter') {
								e.preventDefault();
							}
						}}
					/>
				</FormControl>
				<FormControl variant="outlined" sx={{ width: '100%' }}>
					<Box component={`div`} className={clsx([classes.formGroup])}>
						<FormLabel className={clsx([classes.title])}>Password</FormLabel>
					</Box>
					<TextField
						variant="outlined"
						required
						error={loginError(error)?.password}
						name="password"
						placeholder="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						value={form.password}
						onChange={handleFieldChange}
						className={classes.password}
						onKeyDown={(e: any) => {
							if (e.key === 'Enter') {
								e.preventDefault();
							}
						}}
					/>
				</FormControl>
				<FormControl
					variant="outlined"
					sx={{ width: '100%', marginTop: '10px' }}
				>
					<Box
						component={`div`}
						className={clsx([classes.formGroup, classes.textWrapper])}
					>
						<Link to="/forgot-password" className={classes.forgotPassword}>
							Forgot Password?
						</Link>
					</Box>
				</FormControl>
				<div className={classes.btnBox}>
					<Button
						variant="contained"
						color="secondary"
						disabled={isLoading}
						type="submit"
						style={{
							boxShadow: 'none',
						}}
					>
						{t('login.button_text')}
					</Button>
					{isLoading && (
						<CircularProgress size={24} className={classes.buttonProgress} />
					)}
				</div>
			</form>
			{showTermsPopUp && (
				<CustomModal
					openModal={showTermsPopUp}
					setOpen={setShowTermsPopUp}
					heading={`anydish terms`}
					secondHeading={
						'I, the undersigned hereby declare that I hold and/or am fully covered under a valid malpractice insurance policy. I shall indemnify and hold anydish harmless from and against any and all claims, losses, damages, or expenses of any kind arising out of or in connection with any breach of my representations and/or warranties.'
					}
					modalClass={[classes.modalWidth]}
					Icon={IconWebsiteLogo}
					btnTxt={'I agree'}
					onSubmit={handleSubmitTerms}
				/>
			)}
		</>
	);
}

export const loginError = (error: LoginErrorType | undefined | null) => {
	if (!error) return null;
	switch (error) {
		case LoginErrorType.ERROR:
			return {
				message: 'An error has occurred! 😞',
				username: true,
				password: true,
			};
		case LoginErrorType.USERNAME_EMPTY:
			return { message: 'Please enter username', username: true };
		case LoginErrorType.PASSWORD_EMPTY:
			return { message: 'Please enter password', password: true };
		default:
			return { message: error, password: true, username: true };
	}
};
export default LoginForm;
