import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
	state.clientReportState || initialState;
export const selectReportData = createSelector(
	[selectDomain],
	state => state.reportData,
);
export const selectClientReportError = createSelector(
	[selectDomain],
	state => state.error,
);
