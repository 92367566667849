import * as React from 'react';

import {
	Backdrop,
	Box,
	Modal,
	Fade,
	Button,
	Typography,
	InputLabel,
	TextField,
	Alert,
	CircularProgress,
} from '@mui/material';
import clsx from 'clsx';
import { useStyles } from './styles';
import {
	actions,
	sliceKey,
	reducer,
} from '../../containers/Patients/redux/slice';
import {
	selectClinincForm,
	selectAddClinicPopupOpen,
} from '../../containers/Clinics/redux/selector';
import {
	actions as actionsClinic,
	sliceKey as sliceKeyClinic,
	reducer as reducerClinic,
} from '../../containers/Clinics/redux/slice';
import { IconBluePlus } from '../../../assets/images';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import { PatientRepoSaga } from '../Patients/redux/saga';
import { ClinicRepoSaga } from '../../containers/Clinics/redux/saga';
import {
	actions as actionsPractitioner,
	sliceKey as sliceKeyPractitioner,
	reducer as reducerPractitioner,
} from '../../containers/Practitioner/redux/slice';
import { PractitionerRepoSaga } from '../../containers/Practitioner/redux/saga';
import { useInjectSaga } from 'redux-injectors';
import { useEffect, useState } from 'react';

export const ClinicPopup = () => {
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: PatientRepoSaga });
	useInjectReducer({ key: sliceKeyClinic, reducer: reducerClinic });
	useInjectSaga({ key: sliceKeyClinic, saga: ClinicRepoSaga });
	useInjectReducer({ key: sliceKeyClinic, reducer: reducerClinic });
	useInjectSaga({ key: sliceKeyClinic, saga: ClinicRepoSaga });
	const styles = useStyles();
	const form = useSelector(selectClinincForm);
	const dispatch = useDispatch();
	const isAddClinicPopupOpen = useSelector(selectAddClinicPopupOpen);
	function handlePopupClose() {
		dispatch(actionsClinic.updateFormValue({ key: 'full_name', value: '' }));
		dispatch(actionsClinic.toggleAddClinicPopup());
	}
	const onClinicAddSuccess = () => {
		dispatch(actionsPractitioner.searchPractitionersClinic(''));
		handlePopupClose();
		//navigate('/clinics');
	};
	const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = evt.target;
		dispatch(actionsClinic.updateFormValue({ key: name, value }));
		// setstate({})
	};
	const onSubmitForm = () => {
		try {
			/* istanbul ignore next  */

			dispatch(actionsClinic.addClinic({ callback: onClinicAddSuccess }));
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={isAddClinicPopupOpen}
				onClose={handlePopupClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				className={clsx(styles.modal)}
			>
				<Fade in={isAddClinicPopupOpen}>
					<Box className={clsx(styles.popup)}>
						<Button className={clsx(styles.popupIcon)}>
							<img src={IconBluePlus} alt="plus icon" />{' '}
						</Button>
						<Typography
							id="transition-modal-title"
							variant="h6"
							component="h2"
							className={clsx(styles.title)}
						>
							Add a new clinic
						</Typography>
						<Typography id="transition-modal-description" sx={{ mt: 2 }}>
							Register a new clinic, make sure all the details are correct!
						</Typography>
						<Box
							component="form"
							sx={{ width: 500, maxWidth: '100%' }}
							style={{ marginTop: '30px' }}
						>
							{form?.error?.length > 0 && (
								<Alert severity="error">{form.error}</Alert>
							)}
							<Box>
								<InputLabel className={clsx(styles.label)}>
									Clinic full name
								</InputLabel>
								<TextField
									className={clsx(styles.input)}
									InputLabelProps={{ style: { color: '#645B70' } }}
									name="full_name"
									fullWidth
									placeholder="Search input text"
									id="fullWidth"
									value={form.full_name}
									onChange={handleFieldChange}
									onKeyDown={(e: any) => {
										if (e.key === 'Enter') {
											e.preventDefault();
										}
									}}
								/>
							</Box>
							<Box display="flex" justifyContent="flex-end" pt={3}>
								<Button
									className={clsx([styles.fbtn, styles.fbtnWhite])}
									variant="contained"
									type="button"
									disabled={form.loading}
									onClick={onSubmitForm}
								>
									{form.loading ? (
										<CircularProgress
											size={24}
											className={clsx(styles.buttonProgress)}
										/>
									) : (
										'Save'
									)}
								</Button>
								<Button
									onClick={() => {
										handlePopupClose();
									}}
									className={clsx(styles.fbtn)}
									variant="text"
								>
									Cancel
								</Button>
							</Box>
						</Box>
					</Box>
				</Fade>
			</Modal>
		</div>
	);
};
