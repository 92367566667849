export enum StatusType {
	NEW,
	TAKEN_CARE_OF,
	IRRELEVENT,
	CLOSED,
	CANCELED,
}
export enum PractitionerType {
	ARCHIVE = 1,
	UNARCHIVE = 0,
}
