import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { DASHBOARD_TOP_BAR_HEIGHT } from 'utils/constants';

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		background: '#F9F9F9',
	},
	btnBox: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '20px',
	},
	btn: {
		borderRadius: '40px',
		padding: '8px 0px',
		width: '205px',
		height: '56px',
		fontWeight: '700',
		boxShadow: 'none',
		backgroundColor: '#40018C',
	},
	inputNumberBox: {
		width: '100%',
		background: theme.palette.grey[50],
		borderRadius: '40px',
		fontWeight: '500',
		paddingLeft: '20px',
		color: theme.palette.primary.main,
		fontSize: '12px',
		marginTop: 7,
	},
	btnDeactive: {
		borderRadius: '40px',
		padding: '8px 0px',
		width: '205px',
		height: '56px',
		fontWeight: '700',
		boxShadow: 'none',
		backgroundColor: '#C3C0C8',
	},
	wrapper: { marginTop: '20px' },
	buttonBox: { textAlign: 'center' },
	explainText: {
		fontSize: '12px',
		color: theme.palette.primary.main,
		fontWeight: '700',
		width: '205px',
	},
	pageBtn: {
		marginLeft: 10,
		borderRadius: 30,
		backgroundColor: theme.palette.common.white,
		minHeight: 35,
		padding: 0,
		minWidth: 50,
	},
	pagination: {
		// position: 'fixed',
		// bottom: 10,
		width: 'fit-content',
		display: 'flex',
		alignItems: 'center',
		// display at the end of the page
		justifyContent: 'flex-end',
		marginBottom: 10,
	},
	patient: {},
	label: {
		paddingLeft: '24px',
		fontSize: '12px',
		color: theme.palette.primary.main,
		fontWeight: '700',
		marginBottom: '8px',
	},
	labelText: {
		paddingLeft: '10px',
		fontSize: '14px',
		color: theme.palette.primary.main,
		fontWeight: '700',
		marginBottom: '4px',
		marginTop: '0px',
	},
	input: {
		'& .MuiOutlinedInput-root': {
			background: theme.palette.grey[50],
			borderRadius: '40px',
			fontWeight: '500',
			paddingLeft: '20px',
		},
		'& .MuiFormHelperText-root': {
			textAlign: 'right',
			color: theme.palette.primary.main,
			fontWeight: '700',
			fontSize: '12px',
			marginTop: 7,
		},
	},
	formGroup: {
		margin: '20px 0',
		'& .MuiTypography-root': {
			marginLeft: 24,
			marginBottom: 24,
		},
		'& .MuiTypography-h5': {
			paddingLeft: '0 !important',
		},
	},
	BtnCircle: {
		background: theme.palette.common.white,
		borderRadius: '100%',
		width: '48px',
		height: '48px',
		minWidth: '48px',
		'&:hover': {
			background: theme.palette.primary.main,
			'& img': { filter: 'brightness(0) invert(1)' },
		},
	},
	tabBox: {
		'& .MuiTab-root': { padding: 0, marginRight: '24px' },
		'& .Mui-selected': {
			background: theme.palette.primary.main,
			'& img': { filter: 'brightness(0) invert(1)' },
		},
		'& .MuiTabs-indicator': { display: 'none' },
	},
	SearchInput: {
		borderRadius: '40px',
		width: '309px',
		background: theme.palette.common.white,
		paddingLeft: '15px',
	},
	searchBox: { margin: '20px 0' },
	TableBox: { '& .MuiBox-root': { padding: 0 } },
	TrashMClass: {
		'& .MuiTypography-h6': {
			color: '#DD2A19',
		},
		'& .MuiTypography-body1': {
			color: '#66130B',
		},
		'& .MuiInputLabel-root': {
			color: '#938E9C',
		},

		'& .MuiOutlinedInput-root': {
			background: 'transparent',
			border: '1px solid #F1F0F2',
		},

		'& .MuiButton-containedPrimary': {
			background: '#DD2A19 !important',
		},
		'& .MuiButton-textPrimary': {
			color: '#66130B !important',
		},
	},
	ReassignMClass: {
		'& .MuiOutlinedInput-root': {
			border: '1px solid #F1F0F2',
			background: 'transparent',
		},
		'& .MuiTypography-h5': {
			color: theme.palette.secondary.dark,
			fontWeight: '700',
			marginBottom: '10px',
			fontSize: '16px',
			paddingLeft: '24px',
		},
	},
	searchInput: {
		borderRadius: '40px',
		width: '100%',
		background: '#F1F0F2',
		paddingLeft: '15px',
	},
	EditPopup: {},
	archive: {},
	subheading: {
		margin: '0',
		fontSize: '25px',
		color: '#0F172A',
		fontWeight: '500',
	},
	subText: {
		fontSize: '12px',
		marginTop: '5px',
	},
	hideOnSmallScreen: {
		'@media (max-width: 600px)': {
			display: 'none',
		},
	},
}));
