import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type styleProps = {
	width?: number;
	height?: number;
};

export const useStyles = makeStyles<Theme, styleProps>(theme => ({
	TextInput: props => {
		let height = typeof props.height === 'number' && props.height > 0;
		let width = typeof props.width === 'number' && props.width > 0;
		return width || height
			? {
					backgroundColor: '#F5F5F5',
					width: '60%',
					maxWidth: props.width,
					maxHeight: props.height,
					'& .MuiOutlinedInput-root': {
						minWidth: props.width,
						minHeight: props.height,
					},
					margin: 0,
					'& .MuiInputBase-adornedEnd': {
						paddingRight: 16,
					},
			  }
			: {
					margin: 0,
					backgroundColor: '#F5F5F5',
					width: '60%',
			  };
	},
	OutlinedInput: {
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
	},
}));
