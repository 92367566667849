import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state: RootState) => state.templatesState || initialState;
export const selectAddTemplateError = createSelector(
	[selectDomain],
	state => state.addTemplateError,
);
export const selectAddTemplateNameError = createSelector(
	[selectDomain],
	state => state.addTemplateNameError,
);
export const selectAddTemplateStatus = createSelector(
	[selectDomain],
	state => state.addTemplateStatus,
);
export const selectIsConflictsTemplatesPopupOpen = createSelector(
	[selectDomain],
	state => state.isConflictsTemplatesPopupOpen,
);
export const selectGetTemplateData = createSelector(
	[selectDomain],
	state => state.getTemplateData,
);
export const selectUpdateTemplateError = createSelector(
	[selectDomain],
	state => state.updateTemplateError,
);
export const selectPatientDetails = createSelector(
	[selectDomain],
	state => state.patientDetails,
);
export const selectDeleteTemplatePopupOpen = createSelector(
	[selectDomain],
	state => state.deleteTemplatePopupOpen,
);
export const selectAddTemplatePopupOpen = createSelector(
	[selectDomain],
	state => state.addTemplatePopupOpen,
);
export const selectSelectedTemplate = createSelector(
	[selectDomain],
	state => state.selectedTemplate,
);
export const selectEditTemplatePopupOpen = createSelector(
	[selectDomain],
	state => state.editTemplatePopupOpen,
);
export const selectEditTemplateError = createSelector(
	[selectDomain],
	state => state.editTemplateError,
);
export const selectAnydishTemplatesData = createSelector(
	[selectDomain],
	state => state.anydishTemplatesData,
);
export const selectPrivateTemplatesData = createSelector(
	[selectDomain],
	state => state.privateTemplatesData,
);
export const selectClinicTemplatesData = createSelector(
	[selectDomain],
	state => state.clinicTemplatesData,
);
export const selectGetTemplateError = createSelector(
	[selectDomain],
	state => state.getTemplateError,
);
export const selectTemplatesList = createSelector(
	[selectDomain],
	state => state.templatesList,
);
