/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React from 'react';
import { AuthPage } from 'app/containers/Auth';
import { userRepoSaga } from 'app/containers/Auth/saga';
import { reducer, sliceKey } from 'app/containers/Auth/slice';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { selectDirection, themeActions } from 'styles/theme/slice';
import { actions } from './containers/Auth/slice';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import PrivateRoute from 'utils/PrivateRoute';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import CreatePassword from './containers/Auth/CreatePassword';
// import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
// import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
// import { IconButton } from '@mui/material';
import ForgotPassword from './containers/Auth/ForgotPassword/ForgotPassword';
import LoginForm from './containers/Auth/LoginPage/LoginForm';
import ResetPassword from './containers/Auth/ResetPassword';
// import PrivateRoute from '../utils/PrivateRoute';
import PatientDetails from './containers/Patients/PatientDetails';
import PatientsList from './containers/Patients/PatientsList';
import { UnsupportedScreen } from './containers/UnsupportedScreen/Loadable';
import ClinicInfo from './containers/Clinics';
import Practitioner from './containers/Practitioner';
import { selectLoading } from './containers/Auth/selectors';
import { NotFound } from './containers/NotFound';
import Loader from './components/Loader';
import { selectPractitionerLoading } from './containers/Practitioner/redux/selector';
import { selectPatientLoading } from './containers/Patients/redux/selector';
import SetPassword from './containers/Auth/SetPassword';
import PromoCodes from './containers/PromoCodes';
import SignUp from './containers/Auth/SignUp';
import TemplatesList from './containers/Templates/TemplatesList';
import TemplatesDetails from './containers/Templates/TemplatesDetails';
import packageJson from '../../package.json';
import { HelpRepoSaga } from './containers/Help/redux/saga';
import {
	sliceKey as sliceKeyHelp,
	reducer as reducerHelp,
} from './containers/Help/redux/slice';
import { useIsPractitionerUser } from 'utils/hooks/isPractitionerUser';
import FaqList from './containers/Help/containers/ManagmentFaq/FaqList';
import Translations from './containers/Translations';

export function App() {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: userRepoSaga });
	useInjectReducer({ key: sliceKeyHelp, reducer: reducerHelp });
	useInjectSaga({ key: sliceKeyHelp, saga: HelpRepoSaga });
	const direction = useSelector(selectDirection);
	const dispatch = useDispatch();
	const isPractitionerUser = useIsPractitionerUser();
	const loading = useSelector(selectLoading);
	const practitionerLoader = useSelector(selectPractitionerLoading);
	const patientLoader = useSelector(selectPatientLoading);
	const loader = loading || practitionerLoader || patientLoader;
	const selectedLanguage = useMemo(() => {
		return {
			language: localStorage.getItem('i18nextLng'),
			direction,
		};
	}, [direction]);

	const toggleDirection = () => {
		dispatch(themeActions.toggleDirection());
	};
	useEffect(() => {
		dispatch(actions.loadUser());
	}, []);

	useEffect(() => {
		dispatch(actions.checkVersion(packageJson.version));
	}, []);

	useEffect(() => {
		if (selectedLanguage) {
			if (
				selectedLanguage.language === 'hw' &&
				selectedLanguage.direction !== 'rtl'
			) {
				toggleDirection();
			}
			if (
				selectedLanguage.language === 'en' &&
				selectedLanguage.direction !== 'ltr'
			) {
				toggleDirection();
			}
		}
		// eslint-disable-next-line
	}, [selectedLanguage]);

	return (
		<BrowserRouter>
			<div dir={direction}>
				<Routes>
					<Route path="/login/" element={<AuthPage />}>
						<Route index element={<LoginForm path="login" className="" />} />
					</Route>
					<Route path="/first-login/" element={<AuthPage />}>
						<Route
							index
							element={<LoginForm path="first-login" className="" />}
						/>
					</Route>
					<Route path="forgot-password" element={<ForgotPassword />} />
					<Route path="reset-password" element={<ResetPassword />} />
					<Route path="create-password" element={<CreatePassword />} />
					<Route path="set-password" element={<SetPassword />} />
					<Route path="sign-up" element={<SignUp />} />
					<Route
						path="patients"
						element={
							<PrivateRoute>
								<PatientsList />
							</PrivateRoute>
						}
					/>
					{isPractitionerUser && (
						<Route
							path="/patients/:id"
							element={
								<PrivateRoute>
									<PatientDetails />
								</PrivateRoute>
							}
						/>
					)}
					<Route
						path="/templates"
						element={
							<PrivateRoute>
								<TemplatesList />
							</PrivateRoute>
						}
					/>
					<Route
						path="/templates/:id"
						element={
							<PrivateRoute>
								<TemplatesDetails />
							</PrivateRoute>
						}
					/>
					<Route
						path="/clinicAdmin"
						element={
							<PrivateRoute>
								<Practitioner />
							</PrivateRoute>
						}
					/>
					{!isPractitionerUser && (
						<Route
							path="/clinics"
							element={
								<PrivateRoute>
									<ClinicInfo />
								</PrivateRoute>
							}
						/>
					)}
					{!isPractitionerUser && (
						<Route
							path="/practitioner"
							element={
								<PrivateRoute>
									<Practitioner />
								</PrivateRoute>
							}
						/>
					)}
					{!isPractitionerUser && (
						<Route
							path="/promo-codes"
							element={
								<PrivateRoute>
									<PromoCodes />
								</PrivateRoute>
							}
						/>
					)}
					{!isPractitionerUser && (
						<Route
							path="/faq"
							element={
								<PrivateRoute>
									<FaqList />
								</PrivateRoute>
							}
						/>
					)}
					{!isPractitionerUser && (
						<Route
							path="/translations"
							element={
								<PrivateRoute>
									<Translations />
								</PrivateRoute>
							}
						/>
					)}
					<Route
						path="/"
						element={
							!isPractitionerUser ? (
								<Navigate to="/clinics" />
							) : (
								<Navigate to="/patients" />
							)
						}
					/>
					<Route path="*" element={<NotFound />} />
				</Routes>
				{loader && <Loader />}
			</div>
		</BrowserRouter>
	);
}
