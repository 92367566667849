import { PayloadAction } from '@reduxjs/toolkit';
import { SnackbarProp } from 'app/components/Snackbar';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { InitialPatientsState, Patient, PatientDetail } from '../types';
import { SearchPractitioner } from 'app/containers/Practitioner/types';

const patientsListJson = [];
const ingredientsListJson = [];

export const initialState: InitialPatientsState = {
	patientsList: [],
	patientNutrientsVitaminsIncludeExlude: [],
	patientNutrientAndVitaminDetails: [],
	patientNutrientsAndVitaminsSubDetails: [],
	restrictionsDetails: [],
	patientClinicalSummeryUpdate: {},
	patientUpadateDetails: {},
	ingredientSearchTerm: '',
	ingredientsList: [],
	searchTerm: '',
	pageCount: 0,
	totalCount: 0,
	optionId: 0,
	patientId: '',
	snackBarStatus: false,
	prescriptionSent: false,
	prescriptionMessage: {
		receiver: '',
		message: '',
		title: '',
	},
	sendMessage: '',
	patientsListArchived: [],
	searchTermArchieved: '',
	pageCountArchived: 0,
	totalCountArchived: 0,

	isSidebarOpen: false,
	isUserSidebarOpen: false,
	isSubMenuOpen: false,

	isAddPatientOpen: false,
	isPrePaymentOpen: { visible: false, link: null },
	isArchivePatientOpen: false,
	isUnArchivePatientOpen: false,
	isDeletePatientOpen: false,
	isReassignPatientOpen: false,
	openAllVitamins: false,

	is_archive: 0,

	selectedPatient: {
		type: 'Add',
		_id: '',
		gender: 0,
		fullName: '',
		email: '',
		countryCode: '',
		mobileNumber: '',
		height: '',
		weight: '',
		age: null,
		clinic_id: '',
		codeSentAt: '',
		clinicalSummary: '',
		createdAt: '',
		identity_number: '',
		isPhoneVerified: false,
		is_archive: 0,
		notificationsEnabled: false,
		practitionerPreferences: {
			practitioner_excluded: [],
			practitioner_included: [],
			practitioner_nutritional_array: [],
			practitioner_vitamins_array: [],
			practitioner_ingredients_warning: [],
		},
		dieteryPreferences: {
			cuisines_array: [],
			life_style_array: [],
			restriction_array: [],
			micro_array: [],
			macro_array: [],
			user_excluded: [],
			user_included: [],
		},
		practitioner_id: '',
		savedRecipes: [],
		updatedAt: '',
		userType: '',
	},
	selectedPractitioner: {
		id: '',
		name: '',
	},

	reAssignedPractitionerId: '',

	snackbar: {
		open: false,
		message: '',
		variant: 'success',
	},
	error: '',
	loading: false,
	showAlert: false,
	loadingIngredients: false,

	addPatient: {
		error: '',
	},

	countryCode: {
		countryCode: null,
		flag: null,
		code: null,
		name: null,
	},
};

export const usePatientSlice = createSlice({
	name: 'patientState',
	initialState,
	reducers: {
		setSearchTerm: (state, action: PayloadAction<string>) => {
			state.searchTerm = action.payload;
			if (!action.payload) {
				state.patientsList = patientsListJson;
			}
		},
		setIngredientsSearchTerm: (state, action: PayloadAction<string>) => {
			state.ingredientSearchTerm = action.payload;
		},
		searchIngredients: (state, action: PayloadAction<string>) => {
			state.ingredientSearchTerm = action.payload;
		},
		updateIngredientsList: (state, action: PayloadAction<Patient[]>) => {
			state.ingredientsList = action.payload;
		},
		searchPatients: (state, action: PayloadAction<SearchPractitioner>) => {
			state.searchTerm = action.payload.searchTerm;
			state.pageCount = action.payload.page || 1;
		},
		sendMessage: (state, action: PayloadAction<string>) => {
			state.sendMessage = action.payload;
		},
		getCountryCodeByIp: () => {},
		setCountryCode: (
			state,
			action: PayloadAction<{
				countryCode: string | null;
				flag: string | null;
				code: string | null;
				name: string | null;
			}>,
		) => {
			state.countryCode = action.payload;
		},
		searchPatientsArchived: (
			state,
			action: PayloadAction<SearchPractitioner>,
		) => {
			state.searchTerm = action.payload.searchTerm;
			state.pageCount = action.payload.page || 1;
		},
		updatePatientsList: (state, action: PayloadAction<Patient[]>) => {
			state.patientsList = action.payload;
		},
		updateArchivedpatientsList: (state, action: PayloadAction<Patient[]>) => {
			state.patientsListArchived = action.payload;
		},
		toggleMenu: state => {
			state.isSidebarOpen = !state.isSidebarOpen;
		},
		toggleSubMenu: state => {
			state.isSubMenuOpen = !state.isSubMenuOpen;
		},
		UserToggleMenu: state => {
			state.isUserSidebarOpen = !state.isUserSidebarOpen;
		},
		toggleAddPatientPopup: (state, action: PayloadAction<boolean>) => {
			state.isAddPatientOpen = action.payload;
			state.error = '';
		},
		setLoadingIngredients: (state, action: PayloadAction<boolean>) => {
			state.loadingIngredients = action.payload;
		},
		togglePrePaymentPopup: (state, action: PayloadAction<any>) => {
			state.isPrePaymentOpen = action.payload;
			state.error = '';
		},
		checkPaymentStatus: (
			state,
			action: PayloadAction<{
				onSuccessStatusCallback: () => void;
				openPayment: (link) => void;
			}>,
		) => {},
		openSnackbar: (state, action: PayloadAction<string>) => {
			state.snackbar.open = true;
			state.snackbar.message = action.payload;
			state.snackbar.variant = 'error';
		},
		toggleSnackbar: (state, action: PayloadAction<SnackbarProp>) => {
			state.snackbar.open = action.payload.open || false;
			state.snackbar.message = action.payload.message || '';
			state.snackbar.variant = action.payload.variant || 'error';
		},
		closeSnackbar: state => {
			state.snackbar.open = false;
			state.snackbar.message = '';
		},
		updateFormValue: (
			state,
			action: PayloadAction<{ key: string; value: any }>,
		) => {
			state.selectedPatient[action.payload.key] = action.payload.value;
			state.error = '';
		},
		updatePatient: (state, action: PayloadAction<PatientDetail>) => {
			state.selectedPatient = action.payload;
			state.error = '';
		},
		setError(state, action) {
			state.error = action.payload;
			state.loading = false;
		},
		setAddPatientError(state, action) {
			state.addPatient.error = action.payload;
			state.loading = false;
		},
		updatePageNo: (state, action: PayloadAction<number>) => {
			state.pageCount = action.payload;
		},
		updatePageNoArchived: (state, action: PayloadAction<number>) => {
			state.pageCountArchived = action.payload;
		},
		updateTotalCount: (state, action: PayloadAction<number>) => {
			state.totalCount = action.payload;
		},
		updateTotalCountArchived: (state, action: PayloadAction<number>) => {
			state.totalCountArchived = action.payload;
		},
		cleanPatient(state) {
			state.selectedPatient.fullName = '';
			state.selectedPatient.email = '';
			state.selectedPatient._id = '';
			state.selectedPatient.mobileNumber = '';
			state.selectedPatient.countryCode = '';
			state.selectedPatient.dieteryPreferences = {
				cuisines_array: [],
				life_style_array: [],
				restriction_array: [],
				micro_array: [],
				macro_array: [],
				user_excluded: [],
				user_included: [],
			};
			state.selectedPatient.practitionerPreferences = {
				practitioner_excluded: [],
				practitioner_included: [],
				practitioner_ingredients_warning: [],
				practitioner_nutritional_array: [],
				practitioner_vitamins_array: [],
			};
		},
		addSuccess(state) {
			state.selectedPatient.fullName = '';
			state.selectedPatient.email = '';
			state.selectedPatient._id = '';
			state.selectedPatient.mobileNumber = '';
			state.selectedPatient.countryCode = '';
			state.loading = false;
			state.error = '';
		},
		changesearchPatient: (state, action: PayloadAction<string>) => {
			state.searchTerm = action.payload;
		},
		changesearchArchivedPatient: (state, action: PayloadAction<string>) => {
			state.searchTermArchieved = action.payload;
		},
		toggleUnArchivePatient: state => {
			state.error = '';
			state.isUnArchivePatientOpen = !state.isUnArchivePatientOpen;
		},
		addPatient: (state, action: PayloadAction<{ callback: () => void }>) => {
			state.loading = true;
			state.error = '';
		},
		getPatient: (state, action: PayloadAction<any>) => {
			state.patientId = action.payload;
			state.error = '';
		},
		putPatientClinicalSummery: (state, action: PayloadAction<any>) => {
			state.patientClinicalSummeryUpdate = action.payload;
		},
		updateClinicalSummery: (state, action: PayloadAction<string>) => {
			state.selectedPatient.clinicalSummary = action.payload;
		},
		putPatientDetails: (state, action: PayloadAction<any>) => {
			state.patientUpadateDetails = action.payload;
		},
		sendMessageToPatient: (state, action: PayloadAction<any>) => {
			state.prescriptionMessage = action.payload.prescriptionMessage;
		},
		setPrescriptionSentStatus: (state, action: PayloadAction<any>) => {
			state.prescriptionSent = action.payload;
		},
		setSnackBarStatus: (state, action: PayloadAction<any>) => {
			state.snackBarStatus = action.payload;
			state.patientNutrientsAndVitaminsSubDetails = [];
		},
		editPatient: (state, action: PayloadAction<{ callback: () => void }>) => {
			state.loading = true;
			state.error = '';
		},
		toggleArchivePatient: state => {
			state.error = '';
			state.isArchivePatientOpen = !state.isArchivePatientOpen;
		},
		toggleReassignPatient: state => {
			state.error = '';
			state.isReassignPatientOpen = !state.isReassignPatientOpen;
		},
		setSelectedPatient: (state, action: PayloadAction<any>) => {
			state.error = '';
			state.selectedPatient._id = action.payload.selectedId;
			state.selectedPatient.fullName = action.payload.selectedName;
			state.selectedPatient.email = action.payload.email;
			state.selectedPatient.countryCode = action.payload?.countryCode;
			state.selectedPatient.mobileNumber = action.payload?.mobileNumber;
		},
		updatePatientStatus: (
			state,
			action: PayloadAction<{ status: number; callback: () => void }>,
		) => {
			state.loading = true;
		},

		// =============deletePatient==========================
		toggleDeletePopupModal: state => {
			state.isDeletePatientOpen = !state.isDeletePatientOpen;
		},
		deleteSelectedPatient: (
			state,
			action: PayloadAction<{ callback: () => void }>,
		) => {
			state.loading = true;
			state.error = '';
		},
		deleteSelectedPatientSuccess: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = '';
		},
		deleteSelectedPatientFailure: (state, action: PayloadAction<any>) => {
			state.loading = false;
			// state.error = "";
		},

		// =======formType======================================
		updateFormType: (state, action: PayloadAction<{ type: string }>) => {
			state.selectedPatient.type = action.payload.type;
		},

		// ==========reassign====================================
		setReAssignPractitionerId: (
			state,
			action: PayloadAction<{ id: string }>,
		) => {
			state.reAssignedPractitionerId = action.payload.id;
		},
		reassignPractitioner: (
			state,
			action: PayloadAction<{ callback: () => void }>,
		) => {
			state.error = '';
			state.loading = true;
		},
		reassignPractitionerSuccess: (state, action: PayloadAction<any>) => {
			state.loading = false;
			state.error = '';
		},
		reassignPractitionerFailure: (state, action: PayloadAction<any>) => {
			state.loading = false;
		},
		updateLoader: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setShowAlert: (state, action: PayloadAction<boolean>) => {
			state.showAlert = action.payload;
		},
		updateNutrientsVitaminsIncludeExlude: (
			state,
			action: PayloadAction<any>,
		) => {
			state.patientNutrientsVitaminsIncludeExlude = action.payload;
		},
		searchNutrientsAndVitaminsSubDetails: (
			state,
			action: PayloadAction<any>,
		) => {
			state.optionId = action.payload;
			state.patientNutrientsAndVitaminsSubDetails = [];
		},
		fetchNutrientAndVitaminDetails: (state, action: PayloadAction<any>) => {
			state.optionId = action.payload;
		},
		assignNutrientAndVitaminDetails: (state, action: PayloadAction<any>) => {
			state.patientNutrientAndVitaminDetails = action.payload;
		},
		assignRestrictionsDetails: (state, action: PayloadAction<any>) => {
			state.restrictionsDetails = action.payload;
		},
		getNutrientsAndVitaminsSubDetails: (state, action: PayloadAction<any>) => {
			state.patientNutrientsAndVitaminsSubDetails = action.payload;
		},
		uploadCsvFile: (state, action: PayloadAction<any>) => {
			state.loading = true;
		},
		endUploadCsvFile: state => {
			state.loading = false;
		},
		openAllVitamins: (state, action: PayloadAction<any>) => {
			state.openAllVitamins = action.payload;
		},
	},
});

export const { reducer, actions, name: sliceKey } = usePatientSlice;
