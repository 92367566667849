import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	ClickAwayListener,
	Slider,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';

import { actions } from 'app/containers/Patients/redux/slice';
import { Nutrition } from 'app/containers/Patients/types';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import theme from 'styles/theme/mui';
import {
	JsIconMinus,
	checkIcon,
	returnIcon,
	settingsIcon,
	xIcon,
} from '../../../../../../assets/images';
import PlusIcon from '../../../../../components/PlusIcon';
import SliderThumbComponent from '../SliderThumb';
import InnerSlider from './InnerSlider';
import { useStyles } from './styles';
import { SwitchToFilter } from 'app/components/Switch';
import { updateFarPerType, useResetValueToDefault } from '../../utils';

const MID_VALUE = 1;

type Props = {
	element: any;
	setNutrients: any;
	nutrients: any;
	setVitaminMinerals: any;
	vitaminMinerals: any;
	index: number;
	nutrientAndVitaminDetails: any;
	allVitaminMinerals: any;
};

const NutrientSlider = ({
	element,
	index,
	nutrients,
	setNutrients,
	vitaminMinerals,
	setVitaminMinerals,
	nutrientAndVitaminDetails,
	allVitaminMinerals,
}: Props) => {
	const [expanded, setExpanded] = useState(false);
	const [disabledInnerSlider, setDisabledInnerSlider] = useState(false);
	const [disabledSlider, setDisabledSlider] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [nutrientsWithSubNutrient] = useState([1005, 1004, 1003]);
	const classes = useStyles();
	const dispatch = useDispatch();

	const handleTooltipClose = () => {
		setOpenTooltip(false);
	};

	const handleTooltipOpen = () => {
		setOpenTooltip(true);
	};
	useEffect(() => {
		const fetchNutrientSubDetail = optionId => {
			if (!element.fatPerTypes || element.fatPerTypes.length === 0) {
				dispatch(actions.searchNutrientsAndVitaminsSubDetails(optionId));
			}
		};
		if (nutrientsWithSubNutrient.includes(element.optionId)) {
			fetchNutrientSubDetail(element.optionId);
		}
	}, [
		dispatch,
		element.fatPerTypes,
		element.optionId,
		nutrientsWithSubNutrient,
		disabledSlider,
	]);

	useEffect(() => {
		if (element?.selectedValue?.value !== MID_VALUE) {
			setDisabledInnerSlider(true);
		} else {
			setDisabledInnerSlider(false);
		}
	}, [element.selectedValue.value]);

	useEffect(() => {
		const nonMidNutrients = element?.fatPerTypes.filter(
			(subNutrient: Nutrition) =>
				subNutrient?.selectedValue?.value !== MID_VALUE,
		);
		if (nonMidNutrients.length > 0) {
			setDisabledSlider(true);
		} else {
			setDisabledSlider(false);
		}
	}, [element, element.fatPerTypes]);
	const handleSliderChange = (index, value) => {
		const nutrientData = [...nutrients];
		nutrientData[index].selectedValue = {
			...nutrientData[index].selectedValue,
			value,
		};
		setNutrients([...nutrientData]);
	};
	return (
		<Box className={clsx(classes.root)} key={element._id}>
			<Box component="div" className={clsx(classes.tooltipBox)}>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<p className={clsx(classes.button)}>{element.optionText}</p>
					<Box
						sx={{
							display: 'flex',
						}}
					>
						<ClickAwayListener onClickAway={handleTooltipClose}>
							<Box className={clsx(classes.tooltipContainer)}>
								<Tooltip
									title={
										<ItemToll
											element={element}
											index={index}
											_type={'father'}
											nutrients={nutrients}
											setNutrients={setNutrients}
											setVitaminMinerals={setVitaminMinerals}
											vitaminMinerals={vitaminMinerals}
										/>
									}
									arrow
									placement="top"
									leaveDelay={200}
									PopperProps={{
										disablePortal: true,
									}}
									onClose={handleTooltipClose}
									open={openTooltip}
									disableFocusListener
									disableHoverListener
									disableTouchListener
								>
									<Button
										onClick={() => {
											handleTooltipOpen();
										}}
										sx={{ borderRadius: '50px', height: 25, minWidth: 16 }}
									>
										<img src={settingsIcon} alt="edit" width="20" height="20" />
									</Button>
								</Tooltip>
							</Box>
						</ClickAwayListener>
					</Box>
				</Box>
			</Box>

			<Accordion className={clsx(classes.acc)}>
				<Box component={`div`} className={clsx(classes.head)}>
					<Box className={clsx(classes.headTxt)}>
						<Box className={clsx(classes.box)}>
							<Box className={clsx(classes.sliderBox)}>
								<Slider
									size="small"
									components={{ Thumb: SliderThumbComponent }}
									getAriaLabel={() => 'Minimum distance'}
									marks={element.sliderValues}
									step={1}
									min={0}
									max={2}
									sx={{
										'& .MuiSlider-thumb': {
											background: disabledSlider
												? theme.palette.grey[200]
												: element.selectedValue.value !== 1
												? theme.palette.secondary.main
												: theme.palette.primary.main,
										},
									}}
									disabled={disabledSlider}
									value={element.selectedValue.value}
									valueLabelDisplay="off"
									className={clsx(classes.sliderMark)}
									onChange={event => {
										handleSliderChange(
											index,
											Number((event.target as HTMLInputElement).value),
										);
									}}
								/>
							</Box>
							{element.selectedValue.value !== 1 && (
								<Box className={clsx(classes.switchBox)}>
									<SwitchToFilter
										element={element}
										setNutrients={setNutrients}
										nutrients={nutrients}
										setVitaminMinerals={setVitaminMinerals}
										nutrientAndVitaminDetails={nutrientAndVitaminDetails}
										allVitaminMinerals={allVitaminMinerals}
									/>
								</Box>
							)}
						</Box>
					</Box>
					{
						<AccordionSummary
							expandIcon={
								!nutrientsWithSubNutrient.includes(
									element.optionId,
								) ? null : expanded ? (
									<JsIconMinus />
								) : (
									<PlusIcon />
								)
							}
							onClick={() => {
								setExpanded(!expanded);
							}}
							sx={{ padding: 0, alignSelf: 'flex-start' }}
							disabled={!nutrientsWithSubNutrient.includes(element.optionId)}
							style={{ width: '50px', minHeight: '0px' }}
							aria-controls="panel1a-content"
							id="panel1a-header"
						/>
					}
				</Box>

				<AccordionDetails className={clsx(classes.AccordionDetails)}>
					{nutrientsWithSubNutrient.includes(element.optionId) && (
						<Box className={clsx(classes.innerBox)}>
							{element.fatPerTypes.map((innerElement, innerIndex) => {
								return (
									<InnerSlider
										disabled={disabledInnerSlider}
										innerElement={innerElement}
										index={index}
										innerIndex={innerIndex}
										key={innerElement.optionId}
										nutrients={nutrients}
										setNutrients={setNutrients}
										setVitaminMinerals={setVitaminMinerals}
										vitaminMinerals={vitaminMinerals}
										nutrientAndVitaminDetails={nutrientAndVitaminDetails}
										allVitaminMinerals={allVitaminMinerals}
									/>
								);
							})}
						</Box>
					)}
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default NutrientSlider;

export const ItemToll = ({
	element,
	index,
	_type,
	nutrients,
	setNutrients,
	vitaminMinerals,
	setVitaminMinerals,
}) => {
	const chooseLowValue = element.selectedValue.value;
	const chooseHighValue = element.selectedValue.value;
	const [lowValue, setLowValue] = useState(element.lowValue);
	const [highValue, setHighValue] = useState(element.highValue);
	const [lowValueDisplay, setLowValueDisplay] = useState(element.lowValue);
	const [highValueDisplay, setHighValueDisplay] = useState(element.highValue);
	const [showInputLow, setShowInputLow] = useState(
		chooseLowValue === 0 ? true : false,
	);
	const [showInputHigh, setShowInputHigh] = useState(
		chooseHighValue === 2 ? true : false,
	);
	const [showBtnLowValue, setShowBtnLowValue] = useState(false);
	const [showBtnHighValue, setShowBtnHighValue] = useState(false);
	const [resetValue] = useResetValueToDefault(
		element.optionId,
		nutrients,
		setNutrients,
		vitaminMinerals,
		setVitaminMinerals,
	);
	const saveChangeValue = (optionId, value, type) => {
		let _nutrientData = [...nutrients];
		const index = _nutrientData.findIndex(
			nutrient => nutrient.optionId === optionId,
		);
		if (type === 'lowValue') {
			_nutrientData[index].lowValue = value;
			_nutrientData[index].notDefault = type;
		} else if (type === 'highValue') {
			_nutrientData[index].highValue = value;
			_nutrientData[index].notDefault = type;
		}
		if (_type === 'child') {
			_nutrientData = updateFarPerType(_nutrientData, value, type);
		}
		setNutrients([..._nutrientData]);
	};
	useEffect(() => {
		setLowValueDisplay(element.lowValue);
		setHighValueDisplay(element.highValue);
		setLowValue(element.lowValue);
		setHighValue(element.highValue);
	}, [element.highValue, element.lowValue]);
	const handleLowValueChange = e => {
		const { value } = e.target;
		setLowValue(value);
	};
	const handleHighValueChange = e => {
		const { value } = e.target;
		setHighValue(value);
	};
	const onFocus = type => {
		if (type === 'low') {
			setShowBtnLowValue(true);
		} else {
			setShowBtnHighValue(true);
		}
	};
	const cleanInput = type => {
		if (type === 'low') {
			setLowValue('');
		} else {
			setHighValue('');
		}
	};
	const clickSaveValue = type => {
		if (type === 'low') {
			if (lowValue && /^-?\d*\.?\d+$/.test(lowValue) && lowValue < highValue) {
				setLowValueDisplay(lowValue);
				saveChangeValue(element.optionId, lowValue, 'lowValue');
				setShowBtnLowValue(false);
			}
		} else {
			if (
				highValue &&
				/^-?\d*\.?\d+$/.test(highValue) &&
				highValue > lowValue
			) {
				setHighValueDisplay(highValue);
				saveChangeValue(element.optionId, highValue, 'highValue');
				setShowBtnHighValue(false);
			}
		}
	};

	const styles = useStyles();
	return (
		<Box className={clsx(styles.toolTipRoot)}>
			<Box className={clsx(styles.titleBox)}>
				<Typography
					color="inherit"
					className={clsx([styles.title, styles.BoxTitle])}
				>
					{element.optionText} per 100 gr
				</Typography>
				<Box className={clsx(styles.line)} />
			</Box>
			<Box className={clsx(styles.editView)}>
				<Box className={clsx(styles.editInnerView)}>
					{showInputLow ? (
						<>
							<p className={clsx(styles.desc)}>Low ({element.unitName})</p>
							<TextField
								variant="outlined"
								id="lowValueEdit"
								type="number"
								value={lowValue}
								InputProps={{
									style: {
										fontSize: 14,
									},
								}}
								onKeyDown={(e: any) => {
									if (e.key === 'Enter') {
										e.preventDefault();
									}
								}}
								onFocus={() => {
									onFocus('low');
								}}
								sx={{
									backgroundColor: theme.palette.common.white,
									display: 'flex',
									borderRadius: 10,
									width: 93,
									height: 31,
									justifyContent: 'center',
									'& .MuiInputBase-root': {
										'& input': {
											textAlign: 'center',
										},
									},
									'& .MuiInputBase-input': {
										color: `${
											element.notDefault === 'lowValue'
												? theme.palette.secondary.main
												: theme.palette.secondary.dark
										} !important`,
									},
								}}
								onChange={e => handleLowValueChange(e)}
							/>
						</>
					) : (
						<Box className={clsx(styles.editInnerView)}>
							<p
								style={{
									textAlign: 'center',
									fontSize: '15px',
									fontWeight: '700',
									color:
										element.notDefault === 'lowValue'
											? theme.palette.secondary.main
											: theme.palette.common.white,
								}}
							>
								Low
							</p>
							<p
								style={{
									fontSize: '15px',
									fontWeight: '700',
									color:
										element.notDefault === 'lowValue'
											? theme.palette.secondary.main
											: theme.palette.common.white,
								}}
							>
								{lowValueDisplay}
								{element.unitName}
							</p>
						</Box>
					)}
				</Box>
				{showBtnLowValue ? (
					<>
						<Button
							sx={{
								backgroundColor: '#FFFFFF',
								borderRadius: '50px',
								height: '24px',
								minWidth: '24px',
								padding: 0,
							}}
							onClick={() => {
								clickSaveValue('low');
							}}
						>
							<img src={checkIcon} alt="edit" />
						</Button>
						<Button
							sx={{
								backgroundColor: '#FFFFFF',
								padding: 0,
								borderRadius: '50px',
								height: '24px',
								minWidth: '24px',
							}}
							onClick={() => {
								cleanInput('low');
							}}
						>
							<img src={xIcon} alt="edit" />
						</Button>
					</>
				) : null}
				{!showBtnLowValue && element.notDefault === 'lowValue' ? (
					<Button
						sx={{
							backgroundColor: '#FFFFFF',
							padding: 0,
							borderRadius: '50px',
							height: '24px',
							minWidth: '24px',
						}}
						onClick={() => {
							resetValue();
						}}
					>
						<img src={returnIcon} alt="edit" />
					</Button>
				) : null}
			</Box>
			<Box className={clsx(styles.editView)}>
				<Box className={clsx(styles.editInnerView)}>
					{showInputHigh ? (
						<>
							<p className={clsx(styles.desc)}>High ({element.unitName})</p>
							<TextField
								variant="outlined"
								id="highValueEdit"
								type="number"
								value={highValue}
								onFocus={() => {
									onFocus('high');
								}}
								onKeyDown={(e: any) => {
									if (e.key === 'Enter') {
										e.preventDefault();
									}
								}}
								InputProps={{
									style: {
										fontSize: 14,
										textAlign: 'center',
									},
								}}
								sx={{
									backgroundColor: theme.palette.common.white,
									display: 'flex',
									borderRadius: 10,
									width: 93,
									height: 31,
									justifyContent: 'center',
									'& .MuiInputBase-root': {
										'& input': {
											textAlign: 'center',
										},
									},
									'& .MuiInputBase-input': {
										color: `${
											element.notDefault === 'highValue'
												? theme.palette.secondary.main
												: theme.palette.secondary.dark
										} !important`,
									},
								}}
								onChange={e => handleHighValueChange(e)}
							/>
						</>
					) : (
						<Box className={clsx(styles.editInnerView)}>
							<p
								style={{
									fontSize: '15px',
									fontWeight: '700',
									color:
										element.notDefault === 'highValue'
											? theme.palette.secondary.main
											: theme.palette.common.white,
								}}
							>
								High
							</p>
							<p
								style={{
									textAlign: 'center',
									fontSize: '15px',
									fontWeight: '700',
									color:
										element.notDefault === 'highValue'
											? theme.palette.secondary.main
											: theme.palette.common.white,
								}}
							>
								{highValueDisplay}
								{element.unitName}
							</p>
						</Box>
					)}
				</Box>
				{showBtnHighValue ? (
					<>
						<Button
							sx={{
								backgroundColor: '#FFFFFF',
								borderRadius: '50px',
								height: '24px',
								minWidth: '24px',
								padding: 0,
							}}
							onClick={() => {
								clickSaveValue('high');
							}}
						>
							<img src={checkIcon} alt="edit" />
						</Button>
						<Button
							sx={{
								backgroundColor: '#FFFFFF',
								padding: 0,
								borderRadius: '50px',
								height: '24px',
								minWidth: '24px',
							}}
							onClick={() => {
								cleanInput('high');
							}}
						>
							<img src={xIcon} alt="edit" />
						</Button>
					</>
				) : null}
				{!showBtnHighValue && element.notDefault === 'highValue' ? (
					<Button
						sx={{
							backgroundColor: '#FFFFFF',
							padding: 0,
							borderRadius: '50px',
							height: '24px',
							minWidth: '24px',
						}}
						onClick={() => {
							resetValue();
						}}
					>
						<img src={returnIcon} alt="edit" />
					</Button>
				) : null}
			</Box>
		</Box>
	);
};
