import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
	sidebar: {
		background: theme.palette.common.white,
		width: '30%',
		height: '100vh',
		zIndex: '99',
		boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.09)',
		position: 'fixed',
		top: '0',
		right: '0',
		transition: 'transform 600ms ease-in-out',
		transform: 'translateX(100%)',
		overflowY: 'auto',
		'@media (max-width:600px)': {
			width: '100%',
		},
		paddingBottom: '20%',
	},
	activeSidebar: {
		transform: 'translateX(0)',
	},
	headBtn: {
		margin: '40px 0 0 20px',
		fontWeight: '700',
		fontSize: '20px',
		color: '#000',
	},
	innerSlide: {
		padding: '0 40px 40px 40px',
		boxShadow: 'none',
	},
	head: {
		paddingTop: '80px',
		paddingBottom: '40px',
		borderBottom: '1px solid #F1F0F2',
		maxWidth: '327px',
		width: '100%',
	},
	heading: {
		color: '#000000',
		fontWeight: '700',
		marginBottom: '25px',
		fontSize: '25px',
	},
	userBox: {
		background: theme.palette.grey[50],
		borderRadius: '20px',
		display: 'flex',
		alignItems: 'center',
		padding: '20px 24px',
	},
	userTxt: {
		marginLeft: '20px',
	},
	boxHeading: {
		fontSize: '16px',
		fontWeight: '700',
		color: '#3A017F',
	},
	desc: {
		fontSize: '11px',
		color: '#51475E',
	},
	inner: {
		height: '100%',
	},
	link: {
		color: '#231735',
		fontSize: '16px',
		padding: '10px 10px',
		borderRadius: '8px 0px 0px 8px',
		transition: 'all 0.3s ease',
		'& .MuiListItemText-root .MuiTypography-root': {
			fontWeight: '600',
		},
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.error.main,
		},
	},
	linkRed: {
		color: theme.palette.error.main,
	},
	title: {
		color: theme.palette.secondary.dark,
		fontWeight: '700',
		marginBottom: '10px',
	},
}));
