import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import React from 'react';
import { ButtonWrapper } from './PaypalButton';
import { PAYPAL_CLIENT_ID } from 'utils/constants';
const SCRIPT_PROVIDER_OPTIONS = {
	clientId: PAYPAL_CLIENT_ID,
	components: 'buttons',
	vault: true,
	intent: 'subscription',
};

export default function PaypalProvider({
	onSuccessStatusCallback,
	link,
	sourceComponent,
}) {
	return (
		<PayPalScriptProvider options={SCRIPT_PROVIDER_OPTIONS}>
			<ButtonWrapper
				showSpinner={false}
				onSuccessStatusCallback={onSuccessStatusCallback}
				link={link}
				sourceComponent={sourceComponent}
			/>
		</PayPalScriptProvider>
	);
}
