import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: Theme) => ({
	loginButtonWrapper: {
		position: 'relative',
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
		marginTop: 26,
	},
	formGroup: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 20,
	},
	title: {
		margin: 0,
		color: `${theme.palette.primary.main} !important`,
		fontSize: '12px',
		fontWeight: '700',
		marginBottom: '5px',
		marginLeft: '30px !important',
	},
	buttonProgress: {
		color: theme.palette.common.white,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	form: { width: '100%' },
	error: {
		width: '100%',
		textAlign: 'center',
		justifyContent: 'center',
		marginBottom: 10,
	},
	email: {
		marginTop: 0,
		marginBottom: 23,
		borderRadius: 5,
		// change text color
		'& .MuiInputBase-input': {
			color: `${theme.palette.primary.main} !important`,
		},

		// create border on focus
		'&:focus-within': {
			border: `1.5px solid ${theme.palette.primary.main}`,
			borderRadius: 100,
		},
	},
	password: {
		borderRadius: 5,
		'&:focus-within': {
			border: `1.5px solid ${theme.palette.primary.main}`,
			borderRadius: 100,
		},
		'& .MuiInputBase-input': {
			color: `${theme.palette.primary.main} !important`,
		},
	},
	labelKeepSignedIn: {
		fontSize: 16,
		fontWeight: 400,
	},
	forgotPassword: {
		color: theme.palette.primary.main,
		fontSize: 14,
		lineHeight: '16.71px',
		fontWeight: 400,
		marginRight: 5,
	},
	textWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginTop: '20px',
	},
	btnBox: {
		marginTop: '40px',
		position: 'relative',
		'& .MuiButton-root': {
			background: theme.palette.primary.main,
			padding: '7px 11px',
			borderRadius: ' 40px',
			width: '100%',
			height: '56px',
			color: theme.palette.common.white,
			fontSize: '14px',
			fontWeight: '700',
		},
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	head: {
		marginBottom: '40px',
		textAlign: 'center',
	},
	topTxt: {
		color: '#938E9C ',
		fontSize: '20px',
		fontWeight: '500',
		marginBottom: '10px',
	},
	logo: {
		width: '80%',
		height: 70,
	},
	cancelBoxShadow: {
		boxShadow: 'none',
	},
	modalWidth: {
		width: '600px !important',
	},
}));
