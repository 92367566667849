import { t } from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { request } from 'utils/request';
import { actions, initialState } from './slice';

export function* getTranslation(action) {
	console.log('getTranslation', action?.payload);
	const options = {
		method: 'POST',
		body: JSON.stringify(action?.payload),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/translation/gat-language`,
			options,
		);
		if (response && response.messageCode !== 'MESSAGE.GET_LANGUAGE.SUCCESS') {
			if (!response?.message) {
				yield put(actions.setGatLanguageError(t('something went wrong')));
			} else {
				yield put(actions.setGatLanguageError(t(`${response.message}`)));
			}
		} else {
			yield put(actions.setGatLanguageError(''));
			yield put(actions.setLanguageData(response?.data));
		}
	} catch (e: any) {
		let errorMessage = t('something went wrong');
		try {
			const errors = JSON.parse(e.message);
			errorMessage = errors.message;
		} catch (parseError) {
			console.error('Error parsing JSON:', parseError);
		}
		yield put(actions.setGatLanguageError(errorMessage));
		yield put(actions.setLanguageData(initialState.languageData));
	}
}

export function* TranslationsRepoSaga() {
	yield takeLatest(actions.getTranslation.type, getTranslation);
}
