import { createSlice } from 'utils/@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { InitialTranslationsState } from '../types';

export const initialState: InitialTranslationsState = {
	error: '',
	languageData: { language: '', translationData: [] },
};

export const useTranslationsSlice = createSlice({
	name: 'translationsState',
	initialState,
	reducers: {
		getTranslation: (state, action: PayloadAction<any>) => {},
		setGatLanguageError: (state, action: PayloadAction<any>) => {
			state.error = action.payload;
		},
		setLanguageData: (state, action: PayloadAction<any>) => {
			const input = action?.payload?.translationData;

			const output = Object?.entries(input)?.map(([key, value]) => ({
				[key]: value,
			}));
			state.languageData = {
				language: action?.payload?.language,
				translationData: output,
			};
		},
	},
});

export const { reducer, actions, name: sliceKey } = useTranslationsSlice;
