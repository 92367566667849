import React from 'react';
import {
	Box,
	Button,
	ButtonGroup,
	Grid,
	Pagination,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { IconUnArchive, IconTrash } from '../../../../assets/images';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/slice';
import { Patient } from '../types';
import {
	selectArchivePatientsList,
	selectArchivedPatientsListtotalCount,
	selectPatientsListtotalCount,
} from '../redux/selector';
import { useStyles } from './styles';
import clsx from 'clsx';
import { selectUserType } from 'app/containers/Auth/selectors';
import { Tooltip } from '@mui/material';
import { useIsPractitionerUser } from 'utils/hooks/isPractitionerUser';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common,
		color: '#645B70',
		fontWeight: '400',
		background: 'transparent',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		fontWeight: '500',
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:hover': {
		backgroundColor: '#ece6f4',
		cursor: 'pointer',
		'& .MuiTableCell-root': {
			color: theme.palette.primary.main,
		},
	},
}));

const ArchieveListTable = () => {
	const isPractitionerUser = useIsPractitionerUser();
	const navigate = useNavigate();
	const archivePatientsList: Patient[] = useSelector(selectArchivePatientsList);
	const dispatch = useDispatch();
	const styles = useStyles();
	const ArchivedPatientsListtotal = useSelector(
		selectArchivedPatientsListtotalCount,
	);
	const PatientsListtotal = useSelector(selectPatientsListtotalCount);

	// ============handlechange================================
	const handleDeletePatientModel = (id: string, name: string) => {
		dispatch(
			actions.setSelectedPatient({ selectedId: id, selectedName: name }),
		);
		dispatch(actions.toggleDeletePopupModal());
	};
	const UnArchivePractitionerHandler = (id: string, name: string) => {
		try {
			dispatch(actions.toggleUnArchivePatient());
			dispatch(
				actions.setSelectedPatient({ selectedId: id, selectedName: name }),
			);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<>
			<Table
				sx={{ width: 700 }}
				aria-label="simple table"
				style={{ width: '100%', overflowX: 'auto' }}
			>
				<TableHead>
					<TableRow>
						<StyledTableCell style={{ paddingLeft: '15px' }}>
							Clients ({PatientsListtotal})
						</StyledTableCell>
						<StyledTableCell
							className={clsx(styles.hideOnSmallScreen)}
							align="center"
						>
							Contact Number
						</StyledTableCell>
						<StyledTableCell align="center">Actions</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{archivePatientsList.map((patient, index) => (
						<StyledTableRow
							key={index}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<StyledTableCell
								style={{ paddingLeft: '15px' }}
								onClick={() => navigate(`/patients/${index + 1}`)}
								component="th"
								scope="row"
							>
								{patient.fullName}
							</StyledTableCell>
							<StyledTableCell
								className={clsx(styles.hideOnSmallScreen)}
								align="center"
							>
								{patient?.mobileNumber || ''}
							</StyledTableCell>

							<StyledTableCell
								align="left"
								sx={{ width: '15%', textAlign: 'center' }}
							>
								<ButtonGroup
									className="iconBtnGroup"
									variant="text"
									aria-label="text button group"
									sx={{ justifyContent: 'flex-end' }}
								>
									<Button
										onClick={() =>
											UnArchivePractitionerHandler(
												patient._id,
												patient.fullName,
											)
										}
									>
										<Tooltip title="Unarchive patient" placement="top">
											<img src={IconUnArchive} alt="UnArchive" />
										</Tooltip>
									</Button>
									{!isPractitionerUser ? (
										<Button
											onClick={() =>
												handleDeletePatientModel(patient._id, patient.fullName)
											}
										>
											<img src={IconTrash} alt="trash" />
										</Button>
									) : null}
								</ButtonGroup>
							</StyledTableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
			{/* <Grid container>
				<Grid item xs={12} md={12}>
					<Box component={`div`} className={clsx(styles.pagination)}>
						<Stack mr={7}>
							<Pagination count={10} />
						</Stack>
						<Typography color="inherit">
							Go to page <Button className={clsx(styles.pageBtn)}>2</Button>
						</Typography>
					</Box>
				</Grid>
			</Grid> */}
		</>
	);
};

export default ArchieveListTable;
