import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import PlusIcon from 'app/components/PlusIcon';
import MinusIcon from 'assets/images/JsIcons/MinusIcon';
import { Box } from '@mui/material';

interface UserInfoAccordionProps {
	children: React.ReactNode;
	title: string;
	marginSize?: number;
}

const UserInfoAccordion = ({
	children,
	title,
	marginSize,
}: UserInfoAccordionProps) => {
	const [expanded, setExpanded] = React.useState<boolean>(false);
	return (
		<Box style={{ marginTop: marginSize }}>
			<Accordion style={{ boxShadow: 'none', borderRadius: 5, padding: 5 }}>
				<AccordionSummary
					expandIcon={!expanded ? <PlusIcon /> : <MinusIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
					onClick={() => setExpanded(!expanded)}
				>
					<Typography style={{ fontSize: 14 }}>{title}</Typography>
				</AccordionSummary>
				<AccordionDetails style={{ padding: 0, borderBottom: 'none' }}>
					{children}
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default UserInfoAccordion;
